import Skeleton from 'antd/lib/skeleton';
import cx from 'classnames';
import React from 'react';

import { TOTAL_LIMIT } from '@/constants';

export interface PageResultsProps {
  className?: string;
  style?: React.CSSProperties;
  totalCount: number;
  showItemElement?: boolean;
}

const PageResults: React.FCWithChild<PageResultsProps> = ({
  className,
  style,
  totalCount,
  showItemElement = totalCount > 0,
}) => {
  const showPlus = totalCount === TOTAL_LIMIT;

  return (
    <div className="flex items-center">
      {showItemElement &&
        (!totalCount ? (
          <Skeleton.Input active size={'small'} block={true} className="pl-2" />
        ) : (
          <div
            className={cx(
              'flex flex-wrap items-center justify-between sm:justify-start pagination',
              className,
            )}
            style={style}
          >
            {totalCount > 0 && (
              <div className="text-xs text-dark ml-2">
                Showing{' '}
                <span className="font-bold">
                  {totalCount}
                  {showPlus && '+'}
                </span>{' '}
                {totalCount > 1 ? 'items' : 'item'}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default PageResults;
