import { Skeleton } from 'antd';

import style from '../style.module.less';

const skeletonTitleStyle = {
  height: '16px',
  width: '50%',
  marginBottom: '8px',
  borderRadius: '5px',
};
const skeletonParagraphStyle = {
  height: '72px',
  width: '100%',
  marginBottom: '8px',
  borderRadius: '5px',
};

const BucketSkeleton = ({ loading }: { loading?: boolean }) =>
  !!loading ? (
    <div className={style.bucket}>
      <div className={style.skeleton}>
        <Skeleton.Input
          style={skeletonTitleStyle}
          active={loading}
          size="small"
        />
        <Skeleton.Input
          style={skeletonTitleStyle}
          active={loading}
          size="small"
        />
        {Array.from(new Array(6), (_, i) => (
          <Skeleton.Input
            style={skeletonParagraphStyle}
            active={loading}
            size="large"
            // biome-ignore lint/suspicious/noArrayIndexKey: Index is stable as array length is fixed
            key={`bucket-skeleton-${i}`}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );

export default BucketSkeleton;
