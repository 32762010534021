import React from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import PremiumDiscountChart from '@/partials/Charts/premium-discount/PremiumDiscountChart';
import SharePriceVsNavChart from '@/partials/Charts/share-price-vs-nav/SharePriceVsNavChart';

export const InvestmentTrustCharts: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return (
    <div className="investment-details-content">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <div
            className="p-4 bg-white lg:rounded-lg"
            data-test-id="fundDetailsPerfRiskTabSharePriceNavChart"
          >
            <SharePriceVsNavChart
              emptyState={{ title: 'No data available for this fund' }}
              isins={[state?.fund._id || '']}
              benchmarkId={state?.fund.performanceBenchmarkId}
            />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div
            className="p-4 bg-white lg:rounded-lg"
            data-test-id="fundDetailsPerfRiskTabPremiumDiscountChart"
          >
            <PremiumDiscountChart
              emptyState={{ title: 'No data available for this fund' }}
              isins={state?.fund._id ? [state?.fund._id] : []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
