import { ChartDataTag } from '@aminsights/contract';
import {
  DATE_PERIOD_FILTER,
  DISPLAY_DATE_FORMAT,
  EDateFilterValues,
} from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import { Button, Dropdown } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import useGetPortfolioRiskReturnPlotData from '@/hooks/query-hooks/chart-hooks/useGetPortfolioRiskReturnPlotData';
import { getErrorMessageFromResponse } from '@/hooks/query-hooks/errorHelpers';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { PortfolioChartsProps } from '@/partials/Charts/components/BaseChart';
import { useChartColorsById } from '@/partials/Charts/utils/useChartColors';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';
import NestedDrawer from '@/partials/NestedDrawer';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { STROKE_GREY } from '../../Charts/utils/colors';
import BaseScatterPlot from '../components/BaseScatterPlot';
import { ScatterPlotCardDetails } from '../utils/scatter-plot-data';
import {
  PortfolioRiskReturnInvalidWeightingsError,
  PortfolioRiskReturnWeightingAppliedToDateWithNoDataError,
} from './PortfolioRiskReturnErrors';

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const PortfolioRiskReturnScatterPlot: React.FCWithChild<
  PortfolioChartsProps
> = ({ portfolioId, emptyState: _emptyState }) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<ScatterPlotCardDetails[]>([]);

  const currentPeriod = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  const { data, isLoading, isError, error, isFetching } =
    useGetPortfolioRiskReturnPlotData(
      {
        portfolioId,
        period: currentPeriod,
      },
      {
        enabled: !!portfolioId,
      },
    );
  const isInvalid = data?.isValid === false && !(isFetching || isLoading);

  if (isInvalid) {
    // biome-ignore lint/suspicious/noConsole: backward compatibility
    console.log(
      '[Temp log] Portfolio risk return is invalid, here are the issues: ',
      JSON.stringify(data?.issues, null, 2),
    );
  }

  const shouldShowError = isInvalid && portfolioId;
  const isWeightingsNotAvailableForPeriod = data?.issues?.[
    'errorCodes'
  ].includes('WEIGHTINGS_ARE_NOT_AVAILABLE_FOR_PERIOD');

  const weightingAppliedToDateWithNoData = data?.issues?.[
    'errorCodes'
  ].includes('WEIGHTING_APPLIED_TO_DATE_WITH_NO_DATA');

  const scatterPlotData = data?.chartData.data || [];
  const scatterPlotLegendData = data?.chartData.legendData || [];

  const legendIds = useMemo(() => {
    const ldClone = scatterPlotLegendData.filter(
      ld => ld.dataTag !== ChartDataTag.Benchmark,
    );
    return (ldClone || [])
      .sort((a, b) => a?.label?.localeCompare(b?.label || '') || -1)
      .map(ld => ld.id || '');
  }, [scatterPlotLegendData]);

  const scatterPlotColors = useChartColorsById({
    legend: scatterPlotLegendData,
    ids: legendIds,
  });
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (scatterPlotLegendData.length && scatterPlotColors) {
      setLegendData(
        scatterPlotLegendData.map(l => ({
          ...l,
          tooltip: l.label || '',
          standardDeviation: l.xValue || 0,
          totalReturnOverPeriod: l.yValue || 0,
          id: l.id || '',
          label: l.label || '',
          color: scatterPlotColors[l.id || ''] || '',
          date: l.date || '',
          dataTag: l.dataTag,
        })),
      );
    } else if (scatterPlotLegendData.length === 0 && legendData.length > 0) {
      setLegendData([]);
    }
  }, [data, scatterPlotColors]);

  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData.map(s => ({
        ...s,
        color: scatterPlotColors[s.id],
      })),
      colorField: 'color',
      color: data => {
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  const humanizedPeriod = useMemo(
    () => DATE_PERIOD_FILTER.find(fd => fd.value === currentPeriod)?.label,
    [currentPeriod],
  );

  const to = data?.queriedDateRange?.to;

  let errorMessage: string | undefined = undefined;
  if (error) {
    errorMessage = getErrorMessageFromResponse(error);
  }

  const hasNoWeightings = data?.issues?.['errorCodes'].includes(
    'PORTFOLIO_HAS_NO_WEIGHTINGS',
  );
  if (hasNoWeightings) {
    return <></>;
  }

  return (
    <div className="px-6">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between my-2">
        <h5
          data-test-id="riskReturnChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader
            width="150px"
            loading={isLoading}
            component={<>Risk/Return</>}
          />
        </h5>
        <div className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto">
          {isLoading ? (
            <SkeletonButton className="w-40" active />
          ) : (
            <div
              data-test-id="riskReturnChartDropdown"
              className="pt-2 md:pt-0"
            >
              <Dropdown
                trigger={['click']}
                placement="bottom"
                menu={{
                  items: isMobile ? [] : allowedPeriodsForDropdown,
                }}
              >
                <div className="hover:fill-[#40a9ff] text-neutral fill-[#545576]">
                  <Button
                    className="w-full text-neutral-700 text-left flex items-center justify-between border border-light rounded hover:border-primary hover:[&>p]:text-primary hover:[&>svg]:text-primary"
                    onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                  >
                    <p className="truncate text-sm mb-0 flex-grow">
                      {
                        allowedPeriodsForDropdown.find(
                          p => p.key === currentPeriod,
                        )?.label
                      }
                    </p>
                    <NextArrow className="w-3 rotate-90 fill-current" />
                  </Button>
                </div>
              </Dropdown>
            </div>
          )}
          {isMobile && (
            <NestedDrawer
              menuItems={allowedPeriodsForDropdown}
              visible={isNestedDrawerVisible}
              onClose={() => setIsNestedDrawerVisible(false)}
              title="Select"
            />
          )}
          {humanizedPeriod && to && (
            <p className="text-xs md:text-right mt-2">
              <strong>{humanizedPeriod}</strong> as at{' '}
              <i>{dayjs(to).format(DISPLAY_DATE_FORMAT)}</i>
            </p>
          )}
        </div>
      </div>
      <div className="relative">
        {isWeightingsNotAvailableForPeriod && shouldShowError && (
          <PortfolioRiskReturnInvalidWeightingsError
            portfolioId={portfolioId}
            period={humanizedPeriod}
          />
        )}
        {weightingAppliedToDateWithNoData &&
          !isWeightingsNotAvailableForPeriod &&
          shouldShowError && (
            <PortfolioRiskReturnWeightingAppliedToDateWithNoDataError
              portfolioId={portfolioId}
            />
          )}
        <div>
          {!isLoading && (
            <div data-test-id="riskReturnChart">
              <BaseScatterPlot
                config={config}
                strokeColor={STROKE_GREY}
                onUpdateLegend={setLegendData}
              />
            </div>
          )}
          {!isLoading && isError && (
            <div className="text-center">
              <p className="text-xs text-danger">{errorMessage}</p>
            </div>
          )}
          {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
          <div
            data-test-id="riskReturnChartLegend"
            className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
          >
            <Loader
              row={2}
              width="150px"
              loading={isLoading}
              component={legendData
                .filter(f => f.label)
                .map(legendItem => (
                  <ScatterPlotLegendCard
                    key={legendItem.id}
                    label={legendItem.label}
                    tooltip={legendItem.tooltip || legendItem.label}
                    color={legendItem.color}
                    totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                    standardDeviation={legendItem.standardDeviation}
                    superTag={getLegendSuperTag(legendItem.dataTag)}
                    id={getLegendIdToRemove(legendItem)}
                    targetLink={getLegendTargetLink(legendItem)}
                    cardClasses={getLegendCardClasses(
                      legendItem.dataTag,
                      legendItem.isActive,
                    )}
                    date={legendItem.date}
                  />
                ))}
            />
          </div>
        </div>
      </div>
      {/* Divider is here because it is only on charts page */}
      <div className="my-[30px] border-b border-dashed border-grey-light" />
    </div>
  );
};

export default PortfolioRiskReturnScatterPlot;
