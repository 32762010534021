import { Bucket, Fund } from '@aminsights/contract';
import { LIMIT_FUNDS_FOR_CHARTING } from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

type GetWatchlistCurrencyColumns = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
  currencyColumns: string[];
};

const generateCurrencyColumn = (label: string): IDataTableColumns => {
  return {
    title: label,
    sortKey: `currencyExposures.value.${label}`,
    render: (item: Fund): ReactNode => {
      const currencyExposure = item.currencyExposures?.find(
        c => c.currencyId === label,
      );
      const value = currencyExposure ? currencyExposure.value : undefined;
      return <span>{roundOrEmpty(value, 0, '%')}</span>;
    },
    renderType: 'number',
    width: 100,
  };
};

export const WatchlistCurrencyColumns = ({
  rowSelectionState,
  onSelectAll,
  currencyColumns,
}: GetWatchlistCurrencyColumns): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();
  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (item: Fund) => {
        return (
          <div className="flex items-center w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item._id}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item._id)
                }
                checked={checkedRows.includes(item._id)}
              />
            </div>
            <div className="w-full cursor-pointer">
              <FundInvestmentTrustColumn
                data={{
                  id: item._id,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    ...currencyColumns.map(currencyId => generateCurrencyColumn(currencyId)),
  ];

  return columnDefinitions;
};
