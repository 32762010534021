import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GraphicFile } from '@/assets/svg/graphic-file.svg';
import { Subtitle } from '@/components';

interface EmptyTabProps {
  title: string;
  subTitle: string;
  redirectLink: string;
}

export const EmptyTab: React.FCWithChild<EmptyTabProps> = ({
  title,
  subTitle,
  redirectLink,
}) => {
  return (
    <div className="min-h-[70vh] flex flex-col relative w-full rounded-lg bg-white overflow-hidden items-center justify-center text-center px-5">
      <GraphicFile />
      <h1 className="pt-4 text-[#313341] text-[20px] font-semibold block">
        {title}
      </h1>
      <div>
        <Subtitle>
          {subTitle}{' '}
          <Link style={{ color: '#1890ff' }} to={redirectLink}>
            here
          </Link>
        </Subtitle>
      </div>
    </div>
  );
};
