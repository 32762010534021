import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React, { useEffect, useMemo, useState } from 'react';

import { DataTable } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import getColumns from './columns';
import { IMarketCapData } from './type';

const MarketCapTable: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [marketCapData, setMarketCapData] = useState<Array<any>>([]);

  useEffect(() => {
    const fund = state?.fund;
    const marketCapData: IMarketCapData[] = [
      {
        label: 'Giant',
        value: fund?.marketCapLongPctGiant || EMPTY_DATA_POINT,
      },
      {
        label: 'Large',
        value: fund?.marketCapLongPctLarge || EMPTY_DATA_POINT,
      },
      {
        label: 'Medium',
        value: fund?.marketCapLongPctMedium || EMPTY_DATA_POINT,
      },
      {
        label: 'Small',
        value: fund?.marketCapLongPctSmall || EMPTY_DATA_POINT,
      },
      {
        label: 'Micro',
        value: fund?.marketCapLongPctMicro || EMPTY_DATA_POINT,
      },
    ];
    setMarketCapData(marketCapData);
  }, [state?.fund]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const columns = useMemo(() => {
    const filteredColumns = getColumns(!!isInvestmentTrust);
    return filteredColumns.filter(column => {
      if (column.isVisible && state?.fund) {
        return column.isVisible(state.fund);
      }
      return true;
    });
  }, []);

  if (!state?.fund) return null;

  return (
    <div
      className="bg-white rounded-lg"
      data-test-id="fundDetailsPortfolioTabMarketCapTable"
    >
      <DataTable
        uniqueKey="label"
        columns={columns}
        data={marketCapData}
        noDataLabel="No market cap data provided"
      />
    </div>
  );
};

export default MarketCapTable;
