import {
  RangePair,
  RangePairSerializer,
  YesNoOption,
} from '@aminsights/contract';

import { ICheckboxData } from '@/components/Checkbox/types';

import { countSelectedFilter } from '../utils/countSelectedFilter';

type CheckboxValueType = any;

export interface EsgFilterState {
  datesUNPRI?: Array<string>;
  datesTCFD?: Array<string>;
  checkedSFDR?: string[];
  netZeroAssetManager?: YesNoOption;
  stewardshipCode?: YesNoOption;
  financeForBiodiversityDates?: Array<string>;
  actDates?: Array<string>;
  shareActionRatings?: ICheckboxData[];
}

export const esgInitialState: EsgFilterState = {
  datesUNPRI: [],
  datesTCFD: [],
  checkedSFDR: [],
  netZeroAssetManager: undefined,
  stewardshipCode: undefined,
  financeForBiodiversityDates: [],
  actDates: [],
  shareActionRatings: [],
};

export const countEsgFilters = (f?: EsgFilterState) => {
  const zeroIfEmpty = (items: unknown[] | undefined) => {
    return items?.length ?? 0;
  };

  const a = [
    zeroIfEmpty(f?.checkedSFDR) > 0,
    zeroIfEmpty(f?.datesUNPRI) > 0,
    zeroIfEmpty(f?.datesTCFD) > 0,
    zeroIfEmpty(f?.shareActionRatings) > 0,
    zeroIfEmpty(f?.financeForBiodiversityDates) > 0,
    zeroIfEmpty(f?.actDates) > 0,
    !!f?.stewardshipCode,
    !!f?.netZeroAssetManager,
  ];

  return countSelectedFilter(a);
};

export const SELECT_ALL = 'select-all';

export const checkboxSelectAllData = [
  {
    label: 'Select all',
    value: SELECT_ALL,
  },
];

export const filterDatesCall = () => {
  const numberOfYearsInThePast = 3;
  const currentYear = new Date().getFullYear();
  const result = [];
  let map: Record<string, CheckboxValueType> = {};
  for (let i = 0; i <= numberOfYearsInThePast; i += 1) {
    let rawValue: RangePair = {
      to: `${currentYear - i + 1}`,
      from: `${currentYear - i}`,
    };
    let label = `${currentYear - i}`;
    if (i === numberOfYearsInThePast) {
      label = `Prior ${currentYear - i + 1}`;
      rawValue = {
        to: `${currentYear - i + 1}`,
        from: `${currentYear - 100}`,
      };
    }
    const value = JSON.stringify(rawValue);
    result.push({
      value,
      label,
    });
    map = {
      ...map,
      [RangePairSerializer.serialize(rawValue)]: value,
    };
  }
  return {
    options: result,
    map,
  };
};

export const filterDatesACTCall = () => {
  const startOfActDates = 2022;
  const currentYear = new Date().getFullYear();
  const result = [];
  let map: Record<string, CheckboxValueType> = {};
  for (let i = currentYear; i >= startOfActDates; i -= 1) {
    const rawValue: RangePair = {
      to: `${i + 1}`,
      from: `${i}`,
    };
    const label = `${i}`;
    const value = JSON.stringify(rawValue);
    result.push({
      value,
      label,
    });
    map = {
      ...map,
      [RangePairSerializer.serialize(rawValue)]: value,
    };
  }
  return {
    options: result,
    map,
  };
};
