import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import React, { useEffect, useState } from 'react';

import { DataTable } from '@/components';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { sortArray } from '@/utils/array';

import { CalendarYearPerformanceColumns } from './columns';
import { calendarYearPerformanceMapper } from './rowMapper';
import { ICalendarYearPerformanceData } from './types';

export const CalendarPerformanceTable: React.FCWithChild = () => {
  const [performanceData, setPerformanceData] = useState<
    Array<ICalendarYearPerformanceData>
  >([]);
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();

  useEffect(() => {
    const result: ICalendarYearPerformanceData[] = [];
    const fund = state?.fund;

    fund && result.push(calendarYearPerformanceMapper(fund._id, fund));

    state?.benchmarkPerformance &&
      result.push({
        name: state.benchmarkPerformance.name,
        key: '2',
        totalReturnYtdPct: state.benchmarkPerformance.totalReturnYtdPct,
        totalReturn1calendarYearsAgoPct:
          state.benchmarkPerformance.totalReturn1calendarYearsAgoPct,
        totalReturn2calendarYearsAgoPct:
          state.benchmarkPerformance.totalReturn2calendarYearsAgoPct,
        totalReturn3calendarYearsAgoPct:
          state.benchmarkPerformance.totalReturn3calendarYearsAgoPct,
        totalReturn4calendarYearsAgoPct:
          state.benchmarkPerformance.totalReturn4calendarYearsAgoPct,
        totalReturn5calendarYearsAgoPct:
          state.benchmarkPerformance.totalReturn5calendarYearsAgoPct,
      });
    setPerformanceData(result);
  }, [state]);

  const handleOnSort = (
    key: keyof ICalendarYearPerformanceData,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(performanceData, key, direction);
    setPerformanceData(sorted);
  };

  return (
    <div data-test-id="fundDetailsPerfRiskTabPerformanceCalendar">
      <DataTable
        uniqueKey="key"
        className="
            [&_tbody_tr:nth-child(2n+1)]:bg-grey-600 
            [&_tbody_tr:nth-child(2n+2)]:bg-midLight
          "
        columns={CalendarYearPerformanceColumns(!!isInvestmentTrust)}
        data={performanceData}
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => handleOnSort(key as keyof ICalendarYearPerformanceData, direction)}
      />
    </div>
  );
};
