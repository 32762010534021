import { Checkbox } from '@/components';

import {
  SELECT_ALL,
  checkboxSelectAllData,
  filterDatesCall,
} from '../../../hooks/esgSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import styles from '../../style.module.less';

type CheckboxValueType = any;

const EsgTcfdFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const esgFilters = tentativeFilters.esgFilters;
  const filterDates = filterDatesCall();
  return (
    <div data-test-id={'ESGLabelTCFD'}>
      <p className="text-neutral text-sm mb-4">Signed up in:</p>
      <div className={styles['column-checkbox']}>
        <div className="ant-checkbox-group flex">
          <Checkbox
            data={checkboxSelectAllData}
            className="mr-2"
            direction="row"
            onChange={(list: Array<CheckboxValueType>) => {
              const filters = getMutableFilters();
              filters.esgFilters = filters.esgFilters ?? {};
              if (list.includes(SELECT_ALL)) {
                filters.esgFilters.datesTCFD = filterDates.options.map(
                  option => option.value,
                );
                updateTentativeFilters(filters);
              } else {
                filters.esgFilters.datesTCFD = undefined;
                updateTentativeFilters(filters);
              }
            }}
            value={
              (esgFilters?.datesTCFD ?? []).length >= filterDates.options.length
                ? [SELECT_ALL]
                : []
            }
          />
          <Checkbox
            data={filterDates.options}
            direction="row"
            onChange={(checkedItems: string[]) => {
              const filters = getMutableFilters();
              filters.esgFilters = filters.esgFilters ?? {};
              filters.esgFilters.datesTCFD =
                checkedItems.length > 0 ? checkedItems : undefined;
              updateTentativeFilters(filters);
            }}
            value={esgFilters?.datesTCFD}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgTcfdFilter;
