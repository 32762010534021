import { Slider } from 'antd';
import cx from 'classnames';
import React from 'react';

import Accordion, { Panel } from '@/components/Accordion';
import { SLIDER_MAX, getSliderValueOrDefault } from '@/constants';

import {
  betaMarks,
  maximumDrawdownMarks,
  riskInitialState,
  sortinoRatioMarks,
  stdDeviationMarks,
  trackingErrorMarks,
  upsideAndDownSideMarks,
} from '../hooks/riskSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import style from './style.module.less';

const RiskFilterPanels: React.FC = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const riskFilters = tentativeFilters.riskFilters ?? riskInitialState;

  const [betaRangeMin, betaRangeMax] = getSliderValueOrDefault(
    riskFilters.betaRange,
  );
  const [upsideRangeMin, upsideRangeMax] = getSliderValueOrDefault(
    riskFilters.upsideRange,
  );
  const [downsideRangeMin, downsideRangeMax] = getSliderValueOrDefault(
    riskFilters.downsideRange,
  );
  const [trackingErrorRangeMin, trackingErrorRangeMax] =
    getSliderValueOrDefault(riskFilters.trackingErrorRange);
  const [stdDeviationRangeMin, stdDeviationRangeMax] = getSliderValueOrDefault(
    riskFilters.stdDeviationRange,
  );
  const [maximumDrawdownRangeMin, maximumDrawdownRangeMax] =
    getSliderValueOrDefault(riskFilters.maximumDrawdownRange);
  const [sortinoRatioRangeMin, sortinoRatioRangeMax] = getSliderValueOrDefault(
    riskFilters.sortinoRatioRange,
  );

  return (
    <div>
      <Accordion defaultActiveKey="1">
        <Panel
          header="Beta"
          dataTestId={'riskLabelBeta'}
          key="1"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.riskFilters?.betaRange,
                betaMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderBeta'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.betaRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={betaMarks}
              value={[betaRangeMin, betaRangeMax]}
              onChange={value => {
                const f = getMutableFilters();
                f.riskFilters = {
                  ...f.riskFilters,
                  betaRange: adjustMarksToAvoidDuplicates(value, betaMarks),
                };
                updateTentativeFilters(f);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Upside"
          dataTestId={'riskLabelUpside'}
          key="2"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.riskFilters?.upsideRange,
                upsideAndDownSideMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderUpside'}
          >
            <Slider
              className={cx(
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.upsideRange,
                  )
                ],
                style['range-slider-cutoff-fix'],
              )}
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={upsideAndDownSideMarks}
              value={[upsideRangeMin, upsideRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  upsideRange: adjustMarksToAvoidDuplicates(
                    value,
                    upsideAndDownSideMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Downside"
          dataTestId={'riskLabelDownside'}
          key="3"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.riskFilters?.downsideRange,
                upsideAndDownSideMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderDownside'}
          >
            <Slider
              className={cx(
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.downsideRange,
                  )
                ],
                style['range-slider-cutoff-fix'],
              )}
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={upsideAndDownSideMarks}
              value={[downsideRangeMin, downsideRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  downsideRange: adjustMarksToAvoidDuplicates(
                    value,
                    upsideAndDownSideMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Tracking Error"
          dataTestId={'riskLabelTrackingError'}
          key="4"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters?.riskFilters?.trackingErrorRange,
                trackingErrorMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderTrackingError'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.trackingErrorRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={trackingErrorMarks}
              value={[trackingErrorRangeMin, trackingErrorRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  trackingErrorRange: adjustMarksToAvoidDuplicates(
                    value,
                    trackingErrorMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Std. Deviation"
          dataTestId={'riskLabelStdDeviation'}
          key="5"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters?.riskFilters?.stdDeviationRange,
                stdDeviationMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderStdDeviation'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.stdDeviationRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={20}
              max={SLIDER_MAX}
              marks={stdDeviationMarks}
              value={[stdDeviationRangeMin, stdDeviationRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  stdDeviationRange: adjustMarksToAvoidDuplicates(
                    value,
                    stdDeviationMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Max. Drawdown"
          dataTestId={'riskLabelMaxDrawdown'}
          key="6"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.riskFilters?.maximumDrawdownRange,
                maximumDrawdownMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderMaxDrawdown'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.maximumDrawdownRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={maximumDrawdownMarks}
              value={[maximumDrawdownRangeMin, maximumDrawdownRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  maximumDrawdownRange: adjustMarksToAvoidDuplicates(
                    value,
                    maximumDrawdownMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
        <Panel
          header="Sortino Ratio"
          dataTestId={'riskLabelSortinoRatio'}
          key="7"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.riskFilters?.sortinoRatioRange,
                sortinoRatioMarks,
              )}
            </span>
          }
        >
          <div
            className={style['range-slider']}
            data-test-id={'riskSliderSortinoRatio'}
          >
            <Slider
              className={
                style[
                  getRangeSliderClassName(
                    !!tentativeFilters.riskFilters?.sortinoRatioRange,
                  )
                ]
              }
              tooltip={{ open: false }}
              range
              step={25}
              max={SLIDER_MAX}
              marks={sortinoRatioMarks}
              value={[sortinoRatioRangeMin, sortinoRatioRangeMax]}
              onChange={value => {
                const filters = getMutableFilters();
                filters.riskFilters = {
                  ...filters.riskFilters,
                  sortinoRatioRange: adjustMarksToAvoidDuplicates(
                    value,
                    sortinoRatioMarks,
                  ),
                };
                updateTentativeFilters(filters);
              }}
            />
          </div>
        </Panel>
      </Accordion>
    </div>
  );
};

export default RiskFilterPanels;
