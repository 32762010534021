import { Fund } from '@aminsights/contract';
import React from 'react';

import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';

import CheckboxColumn from './CheckboxColumn';
import FundInvestmentTrustColumn from './FundInvestmentTrustColumn';

interface FundNameCheckboxWrapperProps {
  item: Fund;
  checkboxParams: CheckboxParams;
  dataTableRenderedAt?: DataTableRenderedAt;
  showPrimaryShareClassIndicator?: boolean;
  infoTooltip?: string;
}

export interface CheckboxParams {
  onCheckedRow: (isins: string[]) => void;
  checkedISINs: string[];
  topISINs: string[];
  isAllCheckboxChecked?: boolean;
}

const FundNameCheckboxWrapper: React.FC<FundNameCheckboxWrapperProps> = ({
  item,
  checkboxParams,
  dataTableRenderedAt,
  showPrimaryShareClassIndicator,
  infoTooltip,
}) => {
  return (
    <>
      <CheckboxColumn
        checkedISINs={checkboxParams.checkedISINs}
        item={item}
        onCheckedRow={checkboxParams.onCheckedRow}
        dataTableRenderedAt={dataTableRenderedAt}
        showPrimaryShareClassIndicator={showPrimaryShareClassIndicator}
      />
      <div className="w-full">
        <FundInvestmentTrustColumn
          data={{
            id: item._id,
            name: item.shareClassDetails.code,
            type: item.legalStructure,
            assetClassCode: item.assetClassCode,
            fundId: item.fundId,
            legalStructure: item.legalStructure,
          }}
          infoTooltip={infoTooltip}
        />
      </div>
    </>
  );
};

export default FundNameCheckboxWrapper;
