import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import { DatePicker, Popover } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import React from 'react';

import { getLatestDateForNewPortfolioColumn } from '@/utils/date';

import style from '../style.module.less';

const AntDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

export type DateValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker>['onChange']>
>[0];

interface Props {
  date: Dayjs;
  disabledDates: string[];
  onDateChange: (date: Dayjs) => void;
  isCalendarVisible: boolean;
}

const PopoverDatePicker: React.FCWithChild<Props> = ({
  date,
  disabledDates,
  onDateChange,
  isCalendarVisible,
}) => {
  const dateValue = getLatestDateForNewPortfolioColumn(disabledDates, date);

  const DateRangePicker = (
    <AntDatePicker
      open
      placement="bottomLeft"
      value={dateValue}
      onChange={newDate => {
        if (newDate) {
          onDateChange(newDate);
        }
      }}
      disabledDate={d =>
        disabledDates.some(dD => d.format(DATE_FORMAT_DASHED) === dD)
      }
      popupClassName={cx(style['popup-date-container'])}
      getPopupContainer={trigger => {
        if (trigger.parentElement) return trigger.parentElement;
        return trigger;
      }}
    />
  );

  return (
    <Popover
      open={isCalendarVisible}
      content={DateRangePicker}
      trigger="click"
      align={
        disabledDates.some(
          d => date.diff(dayjs(d, DATE_FORMAT_DASHED), 'days') > 0,
        )
          ? { offset: [5, 0] }
          : { offset: [50, 0] }
      }
      placement="bottomLeft"
      getPopupContainer={trigger => {
        if (trigger.parentElement) return trigger.parentElement;
        return trigger;
      }}
      overlayClassName={cx(style['popover'])}
    />
  );
};

export default PopoverDatePicker;
