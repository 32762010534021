import { NoteCategory } from '@aminsights/contract';
import { Button, Dropdown, Tooltip } from 'antd';
import cx from 'classnames';
import React, { FC, useState } from 'react';

import { ReactComponent as AutoGraphRoundedIcon } from '@/assets/svg/research/menu/AutoGraphRounded.svg';
import { ReactComponent as CheveronDownIcon } from '@/assets/svg/research/menu/Cheveron-down.svg';
import { ReactComponent as CogDefaultIcon } from '@/assets/svg/research/menu/Cog-default.svg';
import { ReactComponent as LightBulbIcon } from '@/assets/svg/research/menu/Light-bulb.svg';
import { ReactComponent as PeopleOutlineOutlinedIcon } from '@/assets/svg/research/menu/PeopleOutlineOutlined.svg';
import { ReactComponent as PieChartOutlinedIcon } from '@/assets/svg/research/menu/PieChartOutlined.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg/research/menu/Plus.svg';
import { ReactComponent as StickyNoteIcon } from '@/assets/svg/research/menu/StickyNote.svg';

import { ModalMode } from '../../constants';
import NoteModal from './NoteModal';

interface MenuItemProps {
  icon: React.ReactNode;
  label: string | React.ReactNode;
  isDisabled: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ icon, label, isDisabled }) => (
  <Tooltip
    title={
      isDisabled ? 'This note already exists, edit the existing one' : null
    }
    placement="bottomLeft"
    color="#313341"
    align={{ offset: [0, 15] }}
    overlayStyle={{ width: '200px' }}
  >
    <span className="flex items-center justify-start">
      <div className={`${isDisabled ? 'opacity-70' : ''}`}>{icon}</div>
      <label
        className={`text-sm cursor-pointer ${
          isDisabled
            ? 'text-neutral-300 hover:!text-neutral-300'
            : 'text-neutral-700 hover:!text-neutral-700'
        }`}
      >
        {label}
      </label>
    </span>
  </Tooltip>
);

const iconMapping: Record<NoteCategory, React.ReactNode> = {
  [NoteCategory.Summary]: (
    <StickyNoteIcon fill="red" className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.People]: (
    <PeopleOutlineOutlinedIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Philosophy]: (
    <LightBulbIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Process]: <CogDefaultIcon className="mr-2 text-neutral-700" />,
  [NoteCategory.Portfolio]: (
    <PieChartOutlinedIcon className="mr-2 text-neutral-700" />
  ),
  [NoteCategory.Performance]: (
    <AutoGraphRoundedIcon className="mr-2 text-neutral-700" />
  ),
};

interface DropdownMenuWithModalProps {
  noteCategory: NoteCategory[];
  hideTextOnSmallScreen?: boolean;
}

const DropdownMenuWithModal: FC<DropdownMenuWithModalProps> = ({
  noteCategory,
  hideTextOnSmallScreen,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<NoteCategory>(
    NoteCategory.Summary,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const menuItemsData = Object.entries(NoteCategory).map(([key, value]) => ({
    key: value,
    icon: iconMapping[value as NoteCategory],
    label: key,
  }));

  const items = menuItemsData.map(({ key, icon, label }) => {
    const isDisabled = noteCategory.includes(key);
    return {
      key,
      label: <MenuItem icon={icon} label={label} isDisabled={isDisabled} />,
      onClick: () => {
        if (!isDisabled) {
          setSelectedCategory(key as NoteCategory);
          setShowModal(true);
        }
      },
    };
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      <NoteModal
        modalNoteInfo={{
          category: selectedCategory,
          mode: ModalMode.Add,
        }}
        isVisible={showModal}
        toggleModal={() => setShowModal(false)}
      />
      <Dropdown
        menu={{ items }}
        placement="bottom"
        autoAdjustOverflow={false}
        overlayClassName={cx(
          'bg-white rounded border border-[#F2F2F5] border-solid p-2',
          'shadow-dropdown',
          '[&>.ant-dropdown-menu]:border-none',
          '[&>.ant-dropdown-menu]:shadow-none',
        )}
        trigger={['click']}
        onOpenChange={setIsDropdownOpen}
      >
        <Button
          type="primary"
          className={cx(
            'flex items-center gap-2 px-4 py-2.5 h-10',
            { 'max-sm:w-10 max-sm:p-0': hideTextOnSmallScreen },
            { 'bg-[#005FBF]': isDropdownOpen },
          )}
        >
          <PlusIcon className={cx({ 'sm:text-xs': hideTextOnSmallScreen })} />
          <span className={cx({ 'max-sm:hidden': hideTextOnSmallScreen })}>
            Add a note
          </span>
          <CheveronDownIcon
            className={cx('text-xs', {
              'max-sm:hidden': hideTextOnSmallScreen,
            })}
          />
        </Button>
      </Dropdown>
    </>
  );
};

export default DropdownMenuWithModal;
