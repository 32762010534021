import { Button } from 'antd';
import cx from 'classnames';
import React, { ComponentProps } from 'react';

import style from './style.module.less';

interface ButtonProps extends ComponentProps<typeof Button> {}

/**
 * @deprecated prefer to use `Button` from `antd`
 */
const CustomButton: React.FCWithChild<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Button
      className={cx(
        style['custom-btn'],
        `text-sm background-primary rounded border-0
        py-1.5 px-4 -outline-offset-1 h-auto
        w-full md:w-auto landscape:w-auto`,
        className,
      )}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
