import { RatingScale } from '@aminsights/contract';
import { Button } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as RateIcon } from '@/assets/svg/research/rating.svg';
import {
  useCreateRatings,
  useGetRatings,
} from '@/hooks/query-hooks/research-hooks/ratings-hooks';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { validatePermissions } from '@/utils/auth';
import { USER_PERMISSIONS } from '@aminsights/shared';
import EmptyState from '../EmptyState';
import SkeletonLoader from '../SkeletonLoader';
import { RatingListing } from './RatingListing';
import { RatingModal } from './RatingModal';
import { RatingsByYear } from './RatingsByYear';

const Ratings = () => {
  const { id: fundId } = useParams<{ id: string }>();
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;

  const [addRatingIsOpen, setAddRatingIsOpen] = useState(false);
  const [isViewAllRatings, setIsViewAllRatings] = useState(false);

  const { mutate: createRatings } = useCreateRatings();
  const { data: ratings, isLoading } = useGetRatings(fundId);

  const sortedRatings = useMemo(() => {
    return ratings?.sort((a, b) => {
      return (
        new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime()
      );
    });
  }, [ratings]);

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasExistingRatings = Boolean(ratings?.length);
  const hasMoreThanThreeRatings = (ratings?.length || 0) > 3;

  return (
    <>
      {!isLoading ? (
        <div className="flex bg-white flex-col gap-2 p-4 lg:rounded-lg">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-darkest">Ratings</span>
            {hasExistingRatings && hasWritePermissions && (
              <Button
                type="primary"
                size="large"
                onClick={() => setAddRatingIsOpen(true)}
              >
                Add rating
              </Button>
            )}
          </div>
          {!hasExistingRatings && (
            <EmptyState
              icon={<RateIcon />}
              onClick={() => setAddRatingIsOpen(true)}
              title="You haven't rated this fund"
              buttonLabel="Add rating"
              hideButton={!hasWritePermissions}
            />
          )}
          <div className="mt-1">
            {hasExistingRatings &&
              (sortedRatings ?? [])
                .slice(0, 3)
                .map(rating => (
                  <RatingListing key={rating._id} rating={rating} />
                ))}
          </div>
          {hasMoreThanThreeRatings && (
            <Button
              type="link"
              className="self-center text-primary hover:text-primary-light"
              onClick={() => setIsViewAllRatings(true)}
            >
              View all ratings
            </Button>
          )}

          <RatingModal
            open={addRatingIsOpen}
            onCancel={() => setAddRatingIsOpen(false)}
            onSave={async rating => {
              await createRatings({
                fundId,
                body: { fundId, scale: RatingScale.Detailed, rating },
              });
            }}
          />

          <RatingsByYear
            ratings={sortedRatings ?? []}
            isModalOpen={isViewAllRatings}
            toggleModal={() => setIsViewAllRatings(false)}
          />
        </div>
      ) : (
        <SkeletonLoader />
      )}
    </>
  );
};

export default Ratings;
