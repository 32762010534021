export const DEFAULT_CURRENCY_CODE = 'GBP';
export const DEFAULT_NOTIFICATIONS_PAGE_SIZE = 12;
export const LIMIT_FUNDS_PER_BUCKET = 11;
export const LIMIT_FUNDS_FOR_CHARTING = 20;
export const LIMIT_PORTFOLIOS = 15;
export const LIMIT_FUNDS_PER_PORTFOLIO = 40;
export const DATE_FORMAT_DASHED = 'DD-MM-YYYY';
export const DATE_FORMAT_SLASHED = 'DD/MM/YYYY';
export const PORTFOLIO_TOTAL_PRECISION = 3;

export const MAX_DAYS_BACK_RIPS = 5;

export const DEFAULT_MONTHS_FOR_NOTIFICATIONS = 1;
