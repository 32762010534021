import { isInvestmentTrust } from '@aminsights/shared';
import React, { useMemo } from 'react';

import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AbsoluteChart from '@/partials/Charts/absolute/AbsoluteChart';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import PremiumDiscountChart from '@/partials/Charts/premium-discount/PremiumDiscountChart';
import RelativeChart from '@/partials/Charts/relative/RelativeChart';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RiskReturnScatterPlot from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnScatterPlot';

export const ChartsTab: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const currentBucketId = useCurrentBucketId();

  const currentBucket = buckets?.find(b => b.id === currentBucketId);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const isins = useMemo(() => {
    return (
      currentBucket?.funds
        ?.filter(fund => fund.isin !== undefined)
        .map(fund => fund.isin) || []
    );
  }, [currentBucket?.name]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const investmentTrustIsins = useMemo(() => {
    if (currentWatchlist?.data) {
      const result = currentBucket?.funds
        ?.filter(fund => isInvestmentTrust(fund?.legalStructure))
        .map(f => f.isin);
      return result ?? [];
    }
    return [];
  }, [currentWatchlist]);

  const hasInvestmentTrust = investmentTrustIsins.length !== 0;

  const orderedFunds = currentBucket?.funds ?? [];
  const featuredIsins = orderedFunds.filter(f => f.isFeatured).map(f => f.isin);

  return (
    <div className="p-[15px]">
      {(isins.length !== 0 || currentBucket?.indexSecId) && (
        <RiskReturnScatterPlot
          isins={isins}
          featuredIsins={featuredIsins}
          benchmarkId={currentBucket?.indexSecId}
          sectorId={currentBucket?.sector}
          hasInvestmentTrust={hasInvestmentTrust}
          showDropdown
        />
      )}
      <AbsoluteChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        showDropdown={true}
      />
      <AbsoluteChart
        emptyState={{
          title: 'No data available for this fund',
        }}
        isins={isins}
        benchmarkId={currentBucket?.indexSecId}
        sectorId={currentBucket?.sector}
        hasInvestmentTrust={hasInvestmentTrust}
        showDropdown={true}
        featuredIsins={featuredIsins}
      />
      {isins.length !== 0 && currentBucket?.indexSecId && (
        <>
          <div className="my-[30px] border-b border-dashed border-[#ccc]" />
          <RelativeChartTitle
            hasInvestmentTrust={hasInvestmentTrust}
            benchmarkName={currentBucket.index}
            showDropdown={true}
          />
          <RelativeChart
            emptyState={{
              title: 'No data available for this fund',
            }}
            isins={isins}
            benchmarkId={currentBucket.indexSecId}
            hasInvestmentTrust={hasInvestmentTrust}
            showDropdown={true}
            featuredIsins={featuredIsins}
          />
        </>
      )}
      {hasInvestmentTrust && (
        <PremiumDiscountChart
          emptyState={{ title: 'No data available for this fund' }}
          isins={investmentTrustIsins}
          allIsins={isins}
          showFundLinks={true}
        />
      )}
    </div>
  );
};
