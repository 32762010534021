import { ResourceUserRoleEnum } from '@aminsights/contract';
import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { Button, Divider } from 'antd';
import { useContext, useMemo, useState } from 'react';

import { APP_ACTIONS } from '@/constants/app';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import { UpsertPortfolioForm } from '@/partials/Forms/Portfolio';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

import { PORTFOLIO_TAB } from '../../../types';
import { useProvidePortfolio } from '../../../useProvidePortfolio';
import RevokePortfolioAccessModal from '../../components/RevokePortfolioAccessModal';
import SharePortfolio from '../../components/SharePortfolio';
import style from './style.module.less';

const PortfolioSettingsTab: React.FCWithChild = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const {
    state,
    deletePortfolio,
    updatePortfolioSettings,
    sharePortfolio,
    revokePortfolioAccess,
  } = useProvidePortfolio();
  const [isDeletePortfolioModalOpen, setIsDeletePortfolioModalOpen] =
    useState(false);
  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const { data: usersData } = useOrganizationUsers();

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );
  const portfolioUsers = [
    ...(currentPortfolio?.users || []),
    ...(currentPortfolio?.invitedUsers || []),
  ];

  const currentPortfolioUser = portfolioUsers.find(
    bu => bu.id === currentUser.sub,
  );

  const hasManagePermissions =
    ResourceUserRoleEnum.Editor === currentPortfolioUser?.role;

  const handleDeletePortfolio = () => {
    return deletePortfolio(currentPortfolio?._id || '');
  };

  const onSubmit = (values: UpsertPortfolioFields) => {
    return updatePortfolioSettings(currentPortfolio?._id || '', {
      name: values.name,
      benchmarkSetting: values.benchmarkSetting,
      sectorId: values.sectorId,
    })
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully updated portfolio' },
        });
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Portfolio not updated',
        });
      });
  };

  const hasActiveUsersWithEditAccess = currentPortfolio?.users
    ?.filter(u => currentPortfolioUser?.id !== u.id)
    .some(u => u.role === ResourceUserRoleEnum.Editor);

  const hasPendingUsersWithEditAccess = currentPortfolio?.invitedUsers?.some(
    u => u.role === ResourceUserRoleEnum.Editor,
  );

  const handleRevokePortfolioAccess = async (usersWithEditorRole: string[]) => {
    if (!currentPortfolio?._id) return;
    if (!(hasActiveUsersWithEditAccess || hasPendingUsersWithEditAccess)) {
      await sharePortfolio({
        portfolioId: currentPortfolio._id,
        users: portfolioUsers.map(u => {
          if (usersWithEditorRole.includes(u.id)) {
            return {
              id: u.id,
              role: ResourceUserRoleEnum.Editor,
            };
          }
          return u;
        }),
      });
    }
    await revokePortfolioAccess(currentPortfolio._id);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const userOptions = useMemo(() => {
    return (usersData || [])
      .filter(
        u =>
          u.user_id !== currentUser?.sub &&
          portfolioUsers.find(bu => bu.id === u.user_id),
      )
      .map(({ name, user_id }) => ({
        label: name,
        value: user_id,
      }));
    // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  }, [usersData?.length, portfolioUsers, currentUser]);

  const hasDeletePermissions =
    portfolioUsers.length === 1 || hasManagePermissions;

  const showRevokeAccessButton =
    !hasManagePermissions || portfolioUsers.length > 1;

  const activeTab = location.pathname.slice(
    APP_ROUTE_PORTFOLIOS.length + (state.currentPortfolioId?.length ?? 0) + 3,
  );

  return (
    <div className={style['portfolio-settings']}>
      <div className={style['wrapper']}>
        <h2 className={style['title']}>Portfolio Details</h2>
        <p className={style['description']}>
          Update your portfolio information here
        </p>
        <div
          key={`portfolio-settings-form-${currentPortfolio?._id}-${activeTab}`}
        >
          {currentPortfolio && activeTab === PORTFOLIO_TAB.SETTINGS && (
            <UpsertPortfolioForm
              portfolio={currentPortfolio}
              submitting={false}
              onSubmit={(values: UpsertPortfolioFields): void => {
                onSubmit(values);
              }}
              error={null}
              disableActions={!hasManagePermissions}
            />
          )}
        </div>
        {currentPortfolio && hasManagePermissions && (
          <div>
            <Divider />
            <SharePortfolio />
          </div>
        )}
        <div className={style['delete-portfolio']}>
          <h2 className={style['title']}>Delete Portfolio</h2>
          <p className={style['description']}>
            This action is permanent and cannot be undone.
          </p>
          <div
            className={`pt-4 flex items-center 
            gap-x-4 landscape:gap-x-6 md:gap-x-6
            justify-between md:justify-start landscape:justify-start`}
          >
            {showRevokeAccessButton && (
              <Button
                size="large"
                className={'border w-full md:w-auto landscape:w-auto'}
                onClick={() => setIsRevokeAccessModalOpen(true)}
                danger
                data-test-id="deletePortfolioButton"
              >
                Delete for me
              </Button>
            )}
            {hasDeletePermissions && (
              <Button
                type="link"
                className={'border w-full md:w-auto landscape:w-auto'}
                onClick={() => setIsDeletePortfolioModalOpen(true)}
                danger
                data-test-id="deletePortfolioButton"
              >
                {portfolioUsers.length === 1 ? 'Delete' : 'Delete for everyone'}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModalDanger
        modalInfo={{
          title:
            portfolioUsers.length === 1
              ? 'Delete Portfolio?'
              : 'Delete for everyone',
          description: 'This portfolio will be permanently deleted.',
          primaryActionLabel: 'Delete',
          succesMessage: 'Successfully deleted a portfolio',
          errorMessage: 'Portfolio not deleted',
        }}
        isVisible={isDeletePortfolioModalOpen}
        toggleModal={() => {
          setIsDeletePortfolioModalOpen(prev => !prev);
        }}
        onConfirm={handleDeletePortfolio}
      />
      <RevokePortfolioAccessModal
        isVisible={isRevokeAccessModalOpen}
        onSubmitClick={handleRevokePortfolioAccess}
        toggleModal={() => {
          setIsRevokeAccessModalOpen(prev => !prev);
        }}
        hasManagePermissions={hasManagePermissions}
        hasActiveUsersWithEditAccess={hasActiveUsersWithEditAccess}
        hasPendingUsersWithEditAccess={hasPendingUsersWithEditAccess}
        userOptions={userOptions}
      />
    </div>
  );
};

export default PortfolioSettingsTab;
