import { Fund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  TotalReturnPeriod,
  calculatePerformanceTableBackgroundColor,
  getYtdMonthCode,
  isFund,
} from '@aminsights/shared';
import { CALENDAR_YEAR_PERFORMANCE_TO_SHOW } from '@aminsights/shared';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { RenderAnnualisedReturns } from '../RenderFundValues/RenderAnnualisedReturns';
import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const CalendarYearPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();

  const monthCodeOfYtd = getYtdMonthCode(dayjs.tz(undefined, 'UTC'));

  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund): React.ReactNode => {
        return (
          <FundNameCheckboxWrapper
            item={item}
            checkboxParams={checkboxParams}
            dataTableRenderedAt={dataTableRenderedAt}
            showPrimaryShareClassIndicator={
              showPrimaryShareClassIndicator ?? false
            }
          />
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${monthCodeOfYtd}`,
          render: (item: Fund) => {
            return <RenderAnnualisedReturns fund={item} showYtd />;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          render: (item: Fund): string => {
            const value = item.totalReturnYtdMorningstarCategoryPcl;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'calendarYearPerformancesPcl',
                  TotalReturnPeriod.YTD,
                )
              : '';
          },
        },
      ],
    },
    ...[1, 2, 3, 4, 5].map(yearsAgo => {
      const year = (new Date().getFullYear() - yearsAgo).toString();
      const keyReturn = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      // totalReturn1calendarYearsAgoCategoryPcl, totalReturn2calendarYearsAgoCategoryPcl and others are used here for sorting to avoid complexity.
      const keyPcl = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;

      return {
        title: year,
        idSubText: year,
        renderType: 'custom' as const,
        align: 'center',
        children: [
          {
            title: 'Return',
            sortKey: keyReturn,
            render: (item: Fund): string => {
              const fundValue = item[keyReturn as keyof Fund];
              return !!fundValue
                ? `${Number(fundValue).toFixed(1)}%`
                : EMPTY_DATA_POINT;
            },
            renderType: 'number' as const,
            width: 85,
          },
          {
            title: 'Pcl',
            sortKey: keyPcl,
            render: (item: Fund): string => {
              const fundValue = item[keyPcl as keyof Fund];
              return !!fundValue
                ? Number(fundValue).toFixed(0)
                : EMPTY_DATA_POINT;
            },
            renderType: 'number' as const,
            width: 85,
            backgroundColor: (item: Fund) => {
              return isFund(item)
                ? calculatePerformanceTableBackgroundColor(
                    item,
                    'calendarYearPerformancesPcl',
                    CALENDAR_YEAR_PERFORMANCE_TO_SHOW[yearsAgo].type,
                  )
                : '';
            },
          },
        ],
      };
    }),
  ];
};

export default CalendarYearPerformanceColumns;
