import { MaturityList, WatchlistApi } from '@aminsights/contract';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import { MATURITY, WATCHLIST } from './query-keys';
import { WatchlistParams } from './watchlistParams';

/**
 * We still need to use infiniteQuery to not break the parent hook. This should be refactored someday
 * if the component rendering the table is refactored.
 */
const useWatchlistMaturityInifiniteQuery = (
  params?: WatchlistParams,
  options?: Omit<
    UseInfiniteQueryOptions<MaturityList, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection, bucketId } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useInfiniteQuery<MaturityList, unknown>(
    [WATCHLIST, MATURITY, sortKey, sortDirection, bucketId],
    async ({ pageParam = 1 }) => {
      const response = await watchlistApi.getWatchlistMaturity(
        {
          page: pageParam,
          size: SIZE_PER_PAGE,
          sortBy: sortKey,
          sortDirection: sortDirection,
        },
        bucketId,
      );
      return response.data;
    },
    {
      getNextPageParam: () => undefined, // This tells react-query that there are no more pages
      ...options,
    },
  );
};

export default useWatchlistMaturityInifiniteQuery;
