import { Avatar, List, Select } from 'antd';
import React from 'react';

import { ListItemUser } from '@/utils/user';

import RoleSelect, { roleOptions } from '../RoleSelect';
import { ResourceUserExtendedActions, ResourceUserRoleOptions } from '../types';
import styles from './style.module.less';

interface Props {
  users: ListItemUser[];
  onUserUpdate: (id: string, role: ResourceUserRoleOptions) => void;
}

const UsersList: React.FCWithChild<Props> = ({ users, onUserUpdate }) => {
  return (
    <List
      dataSource={users}
      className={styles['users-list']}
      itemLayout="horizontal"
      renderItem={item => (
        <List.Item
          key={item.id}
          actions={[
            <RoleSelect<ResourceUserRoleOptions>
              key={item.id}
              value={item.role}
              onChange={r => onUserUpdate(item.id, r)}
              popupClassName={styles['select-dropdown']}
              customOptions={true}
              bordered={false}
            >
              <Select.OptGroup>
                {roleOptions.map(opt => (
                  <Select.Option
                    key={opt.value}
                    value={opt.value}
                    label={opt.label}
                  >
                    {opt.label}
                  </Select.Option>
                ))}
              </Select.OptGroup>
              <Select.OptGroup>
                <Select.Option
                  value={ResourceUserExtendedActions.Remove}
                  label="Remove access"
                >
                  <span style={{ color: 'red' }}>Remove access</span>
                </Select.Option>
              </Select.OptGroup>
            </RoleSelect>,
          ]}
          className="flex items-center gap-4 border-b-0 pb-0"
        >
          <List.Item.Meta
            avatar={
              <Avatar className="bg-[#0b5bb9] text-[white] h-8 w-8 font-medium flex items-center text-xs justify-center">
                {item.name
                  .split(' ')
                  .map(n => n[0])
                  .slice(0, 2)
                  .join('')}
              </Avatar>
            }
            className="flex items-center gap-2 [&_.ant-list-item-meta-avatar]:mr-0"
            title={
              <span className="text-neutral-700 text-sm not-italic font-normal">
                {item.name}
              </span>
            }
            description={
              <div className="flex items-start gap-x-2 flex-col">
                <p className="text-xs text-neutral not-italic font-normal">
                  {item.email}
                </p>
                {item.pendingInvite && (
                  <p className="text-xs text-neutral-700 bg-grey-lighter rounded px-1.5 py-0.5 mt-0.5">
                    Invite sent
                  </p>
                )}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default UsersList;
