import cx from 'classnames';

import ChartSettings from '@/components/ChartSettings/ChartSettings';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { useDatePickerContext } from '@/partials/DatePicker';
import RangeDatePicker from '@/partials/DatePicker/Kinds/RangeDatePicker';
import { toCamelCase } from '@/utils/toCamelCase';

interface RelativeChartTitleProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  benchmarkName?: string;
}

const RelativeChartTitle: React.FCWithChild<RelativeChartTitleProps> = ({
  showDropdown,
  hasInvestmentTrust,
  benchmarkName,
}) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();

  return (
    <div
      className={cx(
        'grid grid-cols-[1fr_auto_auto] gap-y-2 items-center sm:justify-between my-2',
        'sm:grid-cols-[1fr_auto_auto]',
        '[&_.dropdown]:pt-4 [&_.dropdown]:w-full md:[&_.dropdown]:w-auto md:[&_.dropdown]:pt-0',
      )}
    >
      <h5
        data-test-id={toCamelCase(`relativeChartLabel${benchmarkName}`)}
        className="text-sm w-full font-bold text-darkest col-span-3 sm:col-span-1"
      >
        <Loader
          width="150px"
          component={
            <>
              Relative{' '}
              <span className="font-light">
                {benchmarkName ? `(${benchmarkName})` : ''}
              </span>
            </>
          }
        />
      </h5>
      <div
        className="relative-date-picker-wrapper"
        onClick={e => e.stopPropagation()}
      >
        <RangeDatePicker
          onChange={handleDatePickerChange}
          value={datePickerValue}
          btnClassName="justify-self-end w-full"
          dataTestId="relativeChartDropdown"
        />
      </div>
      <div className="ml-2">
        {showDropdown && (
          <ChartSettings
            hidePanelKeys={!hasInvestmentTrust && 'investment-trusts-returns'}
          />
        )}
      </div>
    </div>
  );
};

export default RelativeChartTitle;
