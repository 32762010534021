import { DashboardApi, DashboardSummaryCardItem } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

type UseDashboardSummaryCardsResponse = DashboardSummaryCardItem[];
type UseDashboardSummaryCardsError = unknown; // If API had error types place here
type UseDashboardSummaryCardsOptions = Omit<
  UseQueryOptions<
    UseDashboardSummaryCardsResponse,
    UseDashboardSummaryCardsError
  >,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useDashboardSummaryCards = (
  options?: UseDashboardSummaryCardsOptions,
) => {
  return useQuery<
    UseDashboardSummaryCardsResponse,
    UseDashboardSummaryCardsError
  >(
    ['dashboard_summary_cards'],
    async () => {
      const api = new DashboardApi(openApiConfig());
      const result = await api.getDashboardSummaryCards();
      return result.data;
    },
    options,
  );
};

export default useDashboardSummaryCards;
