import {
  AssignFocusFundBody,
  BucketRequestBody,
  BucketUpdateRequestBody,
  BucketsApi,
  ChangeBucketOrderPayload,
  ManageWatchlistResource,
  WatchlistApi,
} from '@aminsights/contract';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import {
  CHARTS,
  WATCHLIST,
  WATCHLIST_TABS_TO_SHOW,
} from '../watchlist-hooks/query-keys';
import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '../watchlist-hooks/useWatchlists';

// Helpers
const bucketsApi = new BucketsApi(openApiConfig());
const watchlistApi = new WatchlistApi(openApiConfig());
type Res = AxiosResponse<void> | void; // All mutations have axios void response
const invalidateWatchlist = () => {
  queryClient.invalidateQueries({ queryKey: [WATCHLIST] });
  queryClient.invalidateQueries({ queryKey: [CHARTS] });
  queryClient.invalidateQueries({ queryKey: [WATCHLIST_TABS_TO_SHOW] });
};

// ---------------------------------------------
export const useCreateNewBucket = (
  options?: UseMutationOptions<Res, unknown, BucketRequestBody>,
) => {
  const mutation = useMutation(
    async (input: BucketRequestBody) => {
      return await bucketsApi.createNewBucket(input);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );

  return mutation;
};

// ---------------------------------------------
export const useUpdateBucket = (
  options?: UseMutationOptions<
    Res,
    unknown,
    { bucketId: string; bucket: BucketUpdateRequestBody }
  >,
) => {
  const mutation = useMutation(
    async (input: { bucketId: string; bucket: BucketUpdateRequestBody }) => {
      return await bucketsApi.updateBucket(input.bucketId, input.bucket);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );

  return mutation;
};

// ---------------------------------------------
export const useDeleteBucket = (
  options?: UseMutationOptions<Res, unknown, string>,
) => {
  const mutation = useMutation(
    async (bucketId: string) => {
      return await bucketsApi.deleteBucket(bucketId);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );

  return mutation;
};

// ---------------------------------------------
export const useAddFundsToBucket = (
  options?: UseMutationOptions<Res, unknown, ManageWatchlistResource>,
) => {
  const currentWatchlist = useCurrentWatchlist();
  const currentBucketId = useCurrentBucketId();

  return useMutation(
    async (funds: ManageWatchlistResource) => {
      if (funds.isins.length !== 0) {
        const currentBucket = currentWatchlist.data?.buckets.find(
          bucket => bucket.id === currentBucketId,
        );

        //Check current bucket will not have duplicated isins
        const currentIsinsList = currentBucket?.funds.map(f => f.isin) ?? [];
        if (
          currentIsinsList.some(existingIsin =>
            funds.isins.some(incommingIsin => incommingIsin === existingIsin),
          )
        ) {
          return;
        }
      }
      return await bucketsApi.addFundsToBucket(funds);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};

// ---------------------------------------------
export const useChangeFundsBucket = (
  options?: UseMutationOptions<Res, unknown, ManageWatchlistResource>,
) => {
  return useMutation(
    async (funds: ManageWatchlistResource) => {
      return await bucketsApi.changeFundsBucket(funds);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};

// ---------------------------------------------
export const useRemoveFundsFromBucket = (
  options?: UseMutationOptions<Res, unknown, ManageWatchlistResource>,
) => {
  return useMutation(
    async (funds: ManageWatchlistResource) => {
      return await bucketsApi.removeFundsFromBucket(funds);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};

// ---------------------------------------------
export const useAssignAsFocusFund = (
  options?: UseMutationOptions<Res, unknown, AssignFocusFundBody[]>,
) => {
  return useMutation(
    async (funds: AssignFocusFundBody[]) => {
      return await bucketsApi.assignFocusFund(funds);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};

// ---------------------------------------------
export const useRemoveSharedBucket = (
  options?: UseMutationOptions<Res, unknown, string>,
) => {
  return useMutation(
    async (id: string) => {
      return await bucketsApi.removeSharedBucket(id);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};

// ---------------------------------------------
export const useChangeBucketsOrder = (
  options?: UseMutationOptions<Res, unknown, ChangeBucketOrderPayload>,
) => {
  return useMutation(
    async (id: ChangeBucketOrderPayload) => {
      return await watchlistApi.changeBucketsOrder(id);
    },
    { onSettled: () => invalidateWatchlist(), ...options },
  );
};
