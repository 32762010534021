import { PortfolioFund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  MonthCode,
  buildFundDetailsPath,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { RenderAnnualisedReturns } from '@/partials/RenderFundValues/RenderAnnualisedReturns';

const PerformanceColumns = (): IDataTableColumns[] => [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'fundName',
    render: (item: PortfolioFund) =>
      !!item.isin && !!item.fundName ? (
        <Link
          onClick={e => e.preventDefault()}
          to={buildFundDetailsPath(item.isin)}
        >
          {item.fundName}
        </Link>
      ) : (
        EMPTY_DATA_POINT
      ),
    renderType: 'text',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'YTD',
    sortKey: 'totalReturnYtdPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} showYtd />;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '3 mos',
    sortKey: 'totalReturn3MonthPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M3} />;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '6 mos',
    sortKey: 'totalReturn6MonthPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M6} />;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '1 yr',
    sortKey: 'totalReturn1YearPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M12} />;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '3 yrs',
    sortKey: 'totalReturn3YearPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M36} />;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '5 yrs',
    sortKey: 'totalReturn5YearPct',
    render: (item: PortfolioFund) => {
      return <RenderAnnualisedReturns fund={item} timePeriod={MonthCode.M60} />;
    },
    renderType: 'number',
    width: 85,
  },
];

export default PerformanceColumns;
