import { NoteBody, NoteCategory, NoteStatus } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Button, Input, Modal } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useCreateNote,
  useUpdateNote,
} from '@/hooks/query-hooks/research-hooks/notes-hooks';

import { ModalMode } from '../../constants';

type ModalProps = {
  modalNoteInfo: {
    successMessage?: string;
    errorMessage?: string;
    category: NoteCategory;
    description?: string;
    updatedAt?: string | number;
    mode?: ModalMode;
    _id?: string;
    updatedBy?: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
};
const { TextArea } = Input;
const NoteModal: React.FCWithChild<ModalProps> = ({
  modalNoteInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  width = 400,
}) => {
  const { id: fundId } = useParams<{ id: string }>();
  const createNote = useCreateNote();
  const updateNote = useUpdateNote();
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isLimitReached, setIsLimitReached] = useState(false);
  const maxCharacters = 2000;
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (modalNoteInfo?.description) {
      setTextAreaValue(modalNoteInfo.description);
    }
  }, [modalNoteInfo.description, isVisible]);

  const { data: usersData } = useOrganizationUsers();

  const handleCloseModal = () => {
    setTextAreaValue('');
    toggleModal();
  };

  const getUserNameById = (id: string) => {
    const user = usersData?.find(user => {
      return user.user_id === id;
    });
    return user?.name;
  };

  const handleTextChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value.length <= maxCharacters) {
      setTextAreaValue(value);
      setIsLimitReached(false);
    } else {
      setIsLimitReached(true);
    }
  };
  const onClick = async () => {
    const note: NoteBody = {
      fundId: fundId,
      description: textAreaValue,
      category: modalNoteInfo.category,
      status: NoteStatus.Draft,
    };
    if (modalNoteInfo.mode === ModalMode.Edit && modalNoteInfo._id) {
      await updateNote.mutateAsync({
        fundId,
        noteId: modalNoteInfo._id,
        body: { ...note },
      });
    }
    if (modalNoteInfo.mode === ModalMode.Add) {
      await createNote.mutateAsync({
        fundId,
        body: { ...note },
      });
    }
    handleCloseModal();
  };

  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={handleCloseModal}
      className={cx(
        'action-modal',
        'action-modal-confirmation',
        'md:min-w-[600px] lg:min-w-[800px]',
        '[&_.ant-modal-body]:py-6 [&_.ant-modal-header]:pt-10 [&_.ant-modal-header]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0',
        className,
      )}
      title={
        <div className="flex gap-2 flex-col">
          <div className="text-xl font-bold text-darkest">Note</div>
          {modalNoteInfo.mode === ModalMode.Edit && modalNoteInfo.updatedBy && (
            <p className="text-[10px] not-italic font-normal leading-4">
              Last Updated:{' '}
              <span className="font-semibold text-neutral">
                {getUserNameById(modalNoteInfo.updatedBy)}
              </span>{' '}
              {dayjs(modalNoteInfo.updatedAt).format(DISPLAY_DATE_FORMAT)}
            </p>
          )}
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary-save"
          onClick={handleCloseModal}
          data-test-id="modalNoteContentCancelButton"
        >
          Cancel
        </Button>,
        <Button
          size="large"
          onClick={onClick}
          key="primary-confirm"
          loading={loading}
          disabled={
            !textAreaValue ||
            isLimitReached ||
            modalNoteInfo.description === textAreaValue
          }
          data-test-id="modalNoteContentAddButton"
          type="primary"
        >
          {modalNoteInfo.mode === ModalMode.Edit ? 'Save' : 'Add'}
        </Button>,
      ]}
    >
      <div className="text-base font-normal leading-6">
        <span className="text-xs not-italic font-medium leading-4 text-darkest">
          {modalNoteInfo.category}
        </span>
        <div className="text-area-wrapper">
          <TextArea
            className="!placeholder:text-sm !placeholder:text-neutral-100 !placeholder:font-normal !rounded border !border-[#BAC0D0] !border-solid h-[280px] !pb-0 text-sm !text-neutral-700 px-4 py-2 resize-none hover:!border-solid focus:!border-[#007FFF] focus:!border-2"
            id=""
            name=""
            placeholder="Write something..."
            value={textAreaValue}
            onChange={handleTextChange}
          />
          {isLimitReached && (
            <p className="mt-1 text-red-500 text-xs">
              You have hit the 2,000 character limit. Reduce the characters and
              also let us know if this is a regular problem for you.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
