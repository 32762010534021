import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { APP_ACTIONS } from '@/constants';
import {
  STATIC_DATA_ADD_BUCKET_MODAL,
  STATIC_DATA_BUCKET_LIMIT_MODAL,
} from '@/constants/modals-static-data';
import { useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import {
  useAddFundsToBucket,
  useCreateNewBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AddEntityWithBenchmarkModal from '@/partials/Modal/AddEntityWithBenchmarkModal';
import LimitReachModal from '@/partials/Modal/LimitReachModal';

interface ContextValue {
  openLimitReachedModal: () => void;
  openNewBucketModal: () => void;
}

export const WatchlistModalContext = createContext<ContextValue>({
  openLimitReachedModal: () => null,
  openNewBucketModal: () => null,
});

export const WatchlistProvider: React.FCWithChild = ({ children }) => {
  const currentWatchlist = useCurrentWatchlist();
  const createNewBucket = useCreateNewBucket();
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [isAddNewBucketModalOpen, setIsAddNewBucketModalOpen] = useState(false);

  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };

  const handleAddNewBucketModalOpen = () => {
    setIsAddNewBucketModalOpen(prev => !prev);
  };
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const handleCreateNewBucket = async (
    name: string,
    benchmarkId?: string,
    sectorId?: string,
  ) => {
    const benchmarkName =
      benchmarkOptionsResponse?.find(b => b.secId === benchmarkId)?.name ?? '';
    await createNewBucket.mutateAsync({
      name,
      index: benchmarkName,
      indexSecId: benchmarkId || '',
      sector: sectorId,
      funds: [],
    });
  };

  return (
    <WatchlistModalContext.Provider
      value={{
        openLimitReachedModal: () => setIsLimitReachModalOpen(true),
        openNewBucketModal: () => setIsAddNewBucketModalOpen(true),
      }}
    >
      {children}
      <LimitReachModal
        isFundTrust
        staticData={STATIC_DATA_BUCKET_LIMIT_MODAL}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
      />
      <AddEntityWithBenchmarkModal
        entities={currentWatchlist.data?.buckets || []}
        isVisible={isAddNewBucketModalOpen}
        toggleModal={handleAddNewBucketModalOpen}
        onSaveClick={handleCreateNewBucket}
        staticModalData={{
          ...STATIC_DATA_ADD_BUCKET_MODAL,
          entityName: 'Bucket',
        }}
      />
    </WatchlistModalContext.Provider>
  );
};

export function withWatchlistModal<T>(
  Component: React.ComponentType<T>,
): React.FCWithChild<T> {
  return (props: T) => (
    <WatchlistProvider>
      <Component {...(props as any)} />
    </WatchlistProvider>
  );
}

export const useWatchlistModal = ({
  onSuccess,
  isins,
  onClose,
}: {
  onSuccess?: (watched: boolean) => void;
  isins: string[];
  onClose: () => void;
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const { openLimitReachedModal } = useContext(WatchlistModalContext);

  const { mutateAsync, isLoading } = useAddFundsToBucket({
    onSuccess: () => {
      onSuccess?.(true);
      dispatchApp({
        type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
        payload: { text: 'Successfully added to watchlist' },
      });
    },
    onError: () => {
      onSuccess?.(true); // Copied from old .catch block. Not sure if this is correct.
      dispatchApp({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: 'Fund not added to watchlist',
      });
    },
  });

  const [selectedBucket, setSelectedBucket] = useState('');
  const [selectedWatchlistCount, setSelectedWatchlistCount] = useState(0);

  const assign = () => {
    if (selectedWatchlistCount > LIMIT_FUNDS_PER_BUCKET) {
      openLimitReachedModal();
    } else {
      if (!isLoading) {
        mutateAsync({
          isins,
          bucketId: selectedBucket,
        });
      }
    }
    onClose();
  };

  const selectBucket = (bucketId: string, fundCount: number) => {
    setSelectedBucket(bucketId);
    setSelectedWatchlistCount(fundCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setSelectedBucket('');
  }, [onClose]);

  return {
    selectBucket,
    assign,
    selectedBucket,
  };
};
