import { DataSourceType, FundBroadAssetClass } from '@aminsights/contract';
import {
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  MARKET_CAP_TO_SHOW,
  MsTimePeriod,
  SalesPosition,
  buildFundDetailsPath,
  isEquityBroadAssetClass,
  isFixedIncomeBroadAssetClass,
  isInvestmentTrust,
} from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Collapse, Tooltip } from 'antd';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as CollapseIcon } from '@/assets/svg/icons/icon-collapse-up.svg';
import { ReactComponent as ExpandIcon } from '@/assets/svg/icons/icon-expand-down.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icons/icon-pencil-v2.svg';
import SectorEquityLegend from '@/components/SectorEquityLegend';
import { FundTitleLabel } from '@/constants';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useGetFundsToCompare } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useBenchmarkHoldingsByFundShareClassId from '@/hooks/query-hooks/useBenchmarkHoldingsByFundShareClassId';
import useBenchmarkBySecId from '@/hooks/query-hooks/watchlist-hooks/useBenchmarkBySecId';
import useScreenWidth from '@/hooks/screenWidth';
import BasePageWithMetadata from '@/pages/app/BasePageWithMetadata';
import AbsoluteChart from '@/partials/Charts/absolute/AbsoluteChart';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import RelativeChart from '@/partials/Charts/relative/RelativeChart';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import NestedDrawer from '@/partials/NestedDrawer';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { sectorLegendList } from '@/utils/sectorLegendList';
import React from 'react';

import Loader from '../Loader';
import CompareAnnualisedPerformance from './CompareAnnualisedPerformance';
import CompareCalendarYearPerformance from './CompareCalendarYearPerformance';
import { CompareCollapse } from './CompareCollapse';
import CompareFundSelectModal from './CompareFundSelectModal';
import CompareRisk from './CompareRisk';
import CompareCreditQuality from './CompareTables/CompareCreditQuality';
import { CompareFundBasics } from './CompareTables/CompareFundBasics';
import CompareGeography from './CompareTables/CompareGeography';
import CompareHoldings from './CompareTables/CompareHoldings';
import CompareMarketCap from './CompareTables/CompareMarketCap';
import CompareMaturity from './CompareTables/CompareMaturity';
import CompareSectors from './CompareTables/CompareSectors';
import { extractFundTitle } from './CompareTables/extractFundTitle';
import styles from './style.module.less';
import {
  calculateDataForGeographyCompareTable,
  calculateDataForHoldingsCompareTable,
  calculateDataForSectorsCompareTable,
} from './utils/CompareDataCalculations';

const { Panel } = Collapse;

const panelKeys = [
  'geography',
  'geography-eq',
  'geography-fi',
  'sectors',
  'sectors-fi',
  'sectors-eq',
  'sectors',
  'fundBasics',
  'holdings',
  'market-cap',
  'maturity',
  'credit-quality',
  'absolute-chart',
  'relative-chart',
  'annualised-performance',
  'calendar-performance',
  'risk',
];

const CompareFundPage = () => {
  const { currentWidth } = useScreenWidth();
  const { search } = useLocation();
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();
  const query = new URLSearchParams(search);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const FUND_BASICS_KEY = 'fundBasics';

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [activeKeysSetOnLoad, setActiveKeysSetOnLoad] = useState(false);
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (!activeKeysSetOnLoad && currentWidth > 0) {
      setActiveKeys(
        screenWidthMode[ScreenWidthEnum.MinSm] ? panelKeys : [FUND_BASICS_KEY],
      );
      setActiveKeysSetOnLoad(true);
    }
  }, [currentWidth]);

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKeys(Array.isArray(key) ? key : [key]);
  };

  const funds = useMemo(() => {
    return Array.from(
      new Array(4),
      (_, idx) => query.get(`fund${idx + 1}`) || '',
    )?.filter(Boolean);
  }, [query]);

  const benchmarkSecId = query.get('benchmarkSecId') ?? undefined; // I have no idea what to do with index for now
  const matchingBenchmark = benchmarkOptionsResponse?.find(
    b => b.secId === benchmarkSecId,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (funds && funds.length < 2) {
    // TODO Show a proper error screen - you should not be able to land here without two actual funds
    return <div>Invalid query params</div>;
  }

  // Fetch data with react-query
  const validFundQueries = useGetFundsToCompare(funds);
  const validFundQueriesData = validFundQueries?.data?.filter(q => !!q) || [];
  const benchmarkQuery = useBenchmarkBySecId(matchingBenchmark?.secId, {
    refetchOnWindowFocus: false,
  });

  const fundDates = useMemo(() => {
    return validFundQueriesData.map(fund =>
      dayjs(fund?.portfolioSectorUpdateDate).format(DISPLAY_DATE_FORMAT),
    );
  }, [validFundQueriesData]);

  const hasInvestmentTrust = validFundQueriesData.some(
    fund => fund?.legalStructure && isInvestmentTrust(fund.legalStructure),
  );

  const holdingsCompareData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return calculateDataForHoldingsCompareTable(validFundQueriesData);
    }
  }, [validFundQueriesData]);

  const hasHoldingsCompareData = useMemo(() => {
    return (holdingsCompareData?.data.length ?? 0) > 0;
  }, [holdingsCompareData]);

  const hasSectorsNoFIOrPadi = useMemo(() => {
    return validFundQueriesData
      ?.filter(
        v =>
          !isFixedIncomeBroadAssetClass(v.broadAssetClass) &&
          v.portfolioDataSource !== DataSourceType.Padi,
      )
      .some(v => {
        return (
          (v?.sectorsEquity?.filter(s => {
            const hasEqSectorsFromLegend = sectorLegendList.some(i =>
              i.type.includes(s.type),
            );
            return hasEqSectorsFromLegend;
          }).length ?? 0) > 0
        );
      });
  }, [validFundQueriesData]);

  const hasSectorsPadi = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => v.portfolioDataSource === DataSourceType.Padi)
      .some(v => {
        return (
          (v?.sectorsEquity?.filter(s => {
            const hasEqSectorsFromLegend = sectorLegendList.some(i =>
              i.type.includes(s.type),
            );
            return !hasEqSectorsFromLegend;
          }).length ?? 0) > 0
        );
      });
  }, [validFundQueriesData]);

  const hasGeographyCountriesNoFI = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isFixedIncomeBroadAssetClass(v.broadAssetClass))
      .some(v => (v?.countriesEquity?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const hasGeographyCountriesPadi = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => v.portfolioDataSource === DataSourceType.Padi)
      .some(v => (v?.geographyPortfolio?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const hasSectorsNoEquity = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isEquityBroadAssetClass(v.broadAssetClass))
      .some(v => (v?.sectorsFixedIncome?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const hasGeographyNoEquity = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isEquityBroadAssetClass(v.broadAssetClass))
      .some(v => (v?.countriesFixedIncome?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const hasMarketCapsLongDataNoFI = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isFixedIncomeBroadAssetClass(v.broadAssetClass))
      .some(v => (v?.marketCapsLong?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const hasMaturityNoEquity = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isEquityBroadAssetClass(v.broadAssetClass))
      .some(
        v =>
          (v?.bondMaturityRange?.filter(
            v => v.salePosition === SalesPosition.Long,
          ).length ?? 0) > 0,
      );
  }, [validFundQueriesData]);

  const hasCreditQualityNoEquity = useMemo(() => {
    return validFundQueriesData
      ?.filter(v => !isEquityBroadAssetClass(v.broadAssetClass))
      .some(v => (v?.bondCreditQualityBreakdown?.length ?? 0) > 0);
  }, [validFundQueriesData]);

  const geographyCompareEquityData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return hasGeographyCountriesNoFI
        ? calculateDataForGeographyCompareTable(true, validFundQueriesData)
        : { data: [], geographies: [[]], flatGeographies: [] };
    }
  }, [validFundQueriesData, hasGeographyCountriesNoFI]);

  const geographyComparePadiData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return hasGeographyCountriesPadi
        ? calculateDataForGeographyCompareTable(
            true,
            validFundQueriesData,
            true,
          )
        : { data: [], geographies: [[]], flatGeographies: [] };
    }
  }, [validFundQueriesData, hasGeographyCountriesPadi]);

  const geographyCompareFixedIncomeData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return hasGeographyNoEquity
        ? calculateDataForGeographyCompareTable(false, validFundQueriesData)
        : { data: [], geographies: [[]], flatGeographies: [] };
    }
  }, [validFundQueriesData, hasGeographyNoEquity]);

  const sectorsEquityCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return hasSectorsNoFIOrPadi
        ? calculateDataForSectorsCompareTable(true, validFundQueriesData)
        : { data: [], sectors: [[]], flatSectors: [] };
    }
  }, [validFundQueriesData, hasSectorsNoFIOrPadi]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const sectorsPADICompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return hasSectorsPadi
        ? calculateDataForSectorsCompareTable(true, validFundQueriesData, true)
        : { data: [], sectors: [[]], flatSectors: [] };
    }
  }, [validFundQueriesData, hasSectorsNoFIOrPadi]);

  const sectorsFixedIncomeCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return hasSectorsNoEquity
        ? calculateDataForSectorsCompareTable(false, validFundQueriesData)
        : { data: [], sectors: [[]], flatSectors: [] };
    }
  }, [validFundQueriesData, hasSectorsNoEquity]);

  const benchmarkHoldingsQuery = useBenchmarkHoldingsByFundShareClassId(
    matchingBenchmark?.secId,
    (sectorsEquityCompareData?.data.map(s => s.type) ?? []).concat(
      sectorsFixedIncomeCompareData?.data.map(s => s.type) ?? [],
    ),
    holdingsCompareData?.data.filter(d => d.isin).map(h => h.isin) ?? [],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (
      geographyCompareEquityData?.data
        .filter(g => !!g.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(g => g.id!) ?? []
    ).concat(
      geographyCompareFixedIncomeData?.data
        .filter(g => !!g.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(g => g.id!) ?? [],
    ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const marketCapCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return {
        types: Object.values(MARKET_CAP_TO_SHOW).map(m => m.title),
        marketCap: validFundQueriesData.map(f => f.marketCapsLong),
      };
    } else {
      return {
        types: [],
        marketCap: [[]],
      };
    }
  }, [validFundQueriesData]);

  const isSomethingLoading =
    validFundQueries.isLoading || benchmarkQuery.isLoading;

  // We'll use the equity template if there is an equity fund that is not PADI. Other templates to come in the future.
  const useEquityTemplate = validFundQueriesData.some(
    v =>
      isEquityBroadAssetClass(v?.broadAssetClass ?? '') &&
      v?.portfolioDataSource !== DataSourceType.Padi,
  );

  const showIndexColumn = useEquityTemplate && !!matchingBenchmark?.secId;

  if (validFundQueries.isError) {
    // biome-ignore lint/suspicious/noConsole: backward compatibility
    console.group('One or both of the queries threw an error');
    console.error(validFundQueries.error);
    // biome-ignore lint/suspicious/noConsole: backward compatibility
    console.groupEnd();
    return null;
  }

  const isinsForChartRequest = useMemo(() => funds, [funds]);

  const orderedFunds = !!validFundQueriesData.length
    ? validFundQueriesData.map(f => {
        return {
          ...f,
          isin: f._id,
          shareClassDetailsCode: f.shareClassDetails.code,
        };
      })
    : [];

  const renderComparePanelGeography = (
    key: string,
    broadAssetClass: FundBroadAssetClass,
    title: FundTitleLabel,
    showPadi = false,
  ) => {
    const data = showPadi
      ? geographyComparePadiData
      : broadAssetClass === FundBroadAssetClass.Equity
        ? geographyCompareEquityData
        : geographyCompareFixedIncomeData;

    return (
      <Panel
        showArrow={true}
        className="bg-white overflow-hidden"
        key={key}
        header={<h3 className="!font-bold py-4 pl-4">{title}</h3>}
      >
        <CompareGeography
          funds={validFundQueriesData}
          showIndexColumn={showIndexColumn}
          loading={isSomethingLoading}
          benchmarkCountries={benchmarkHoldingsQuery.data?.countries}
          data={data}
        />
      </Panel>
    );
  };

  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  let timePeriod: MsTimePeriod = MsTimePeriod.ONE_YR;
  switch (datePickerValue.mode) {
    case EDateFilterValues['1YR']:
      timePeriod = MsTimePeriod.ONE_YR;
      break;
    case EDateFilterValues['3YR']:
      timePeriod = MsTimePeriod.THREE_YRS;
      break;
    case EDateFilterValues['5YR']:
      timePeriod = MsTimePeriod.FIVE_YRS;
      break;
  }

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const currentPeriod = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  const renderComparePanelSector = (
    key: string,
    broadAssetClass: FundBroadAssetClass,
    title: FundTitleLabel,
    showPadi = false,
  ) => {
    let data =
      broadAssetClass === FundBroadAssetClass.Equity
        ? sectorsEquityCompareData
        : sectorsFixedIncomeCompareData;
    if (showPadi) {
      data = sectorsPADICompareData;
    }
    return (
      <Panel
        showArrow={true}
        className="bg-white overflow-hidden"
        key={key}
        header={
          <div className="flex flex-col md:flex-row justify-between md:items-center pl-4 py-4 pr-10">
            <h3 className="!font-bold pb-1 md:pb-0">{title}</h3>
            {!showPadi && broadAssetClass === FundBroadAssetClass.Equity && (
              <SectorEquityLegend className="gap-2" />
            )}
          </div>
        }
      >
        <CompareSectors
          funds={validFundQueriesData}
          showIndexColumn={showIndexColumn}
          data={data}
          benchmarkSectors={benchmarkHoldingsQuery.data?.sectors}
          loading={isSomethingLoading}
          broadAssetClass={broadAssetClass}
          showPadiData={showPadi}
        />
      </Panel>
    );
  };

  return (
    <BasePageWithMetadata
      title={`Compare ${validFundQueriesData
        .map(f => extractFundTitle(f))
        .join(' vs ')}`}
    >
      <div>
        <div className="flex flex-col">
          <div className="grid grid-cols-[1fr,_min-content] items-center">
            <SectionBackButton
              previousLabel={
                screenWidthMode[ScreenWidthEnum.MinMd] ? 'Back' : ''
              }
            />
          </div>
          <div className="flex justify-between px-5 pb-6">
            <h1 className="text-darkest text-xl font-bold content-center">
              Compare
            </h1>
            <div className={cx('self-center flex gap-3 md:gap-4')}>
              <Button
                size="large"
                type="link"
                className="max-md:w-10"
                onClick={() => setActiveKeys(panelKeys)}
                icon={<ExpandIcon className="w-6 h-6 md:w-5 md:h-5" />}
              >
                <span className="hidden md:inline">Expand All</span>
              </Button>
              <Button
                size="large"
                type="link"
                className="max-md:w-10"
                onClick={() => setActiveKeys([])}
                icon={<CollapseIcon className="w-6 h-6 md:w-5 md:h-5" />}
              >
                <span className="hidden md:inline">Collapse All</span>
              </Button>
              {!isAppLimitedAccessEnabled && (
                <Loader
                  width="90px"
                  loading={isSomethingLoading}
                  component=<Button
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    className="max-md:w-10"
                    size="large"
                    icon={<EditIcon className="h-[18-px] w-[18-px]" />}
                    type="primary"
                  >
                    <span className="hidden md:inline">Edit</span>
                  </Button>
                />
              )}
            </div>
          </div>
          <div className="bg-white py-1 grid grid-cols-[1fr,_min-content] items-center">
            <div
              className={cx(
                'px-6 w-full overflow-hidden flex items-center flex-wrap',
              )}
            >
              <div>
                <Loader
                  width="300px"
                  loading={isSomethingLoading}
                  // We don't expect the below to show up anyway so not worried about undefined being returned
                  component={
                    <div className="grid grid-cols-[1fr,_min-content] items-center">
                      <h1 className="w-full text-xs font-bold text-darkest flex flex-wrap gap-x-1">
                        {validFundQueriesData.map((f, idx, arr) => (
                          <>
                            <Tooltip
                              title={extractFundTitle(f)}
                              placement="bottomRight"
                              color="#3E414B"
                              overlayClassName="md:max-w-none lg:whitespace-nowrap"
                              overlayInnerStyle={{
                                width: 'fit-content',
                              }}
                              key={f._id}
                            >
                              <Link
                                to={buildFundDetailsPath(f._id)}
                                className={cx(
                                  'max-w-[calc((100%-80px)/2)] overflow-hidden text-ellipsis',
                                  'whitespace-nowrap inline-block md:w-fit md:self-center md:basis-0',
                                  'md:max-w-max md:shrink-[1] md:grow',
                                  validFundQueriesData.length === 2 &&
                                    'w-2/5 min-w-[35%]',
                                )}
                              >
                                {extractFundTitle(f)}
                              </Link>
                            </Tooltip>
                            {idx !== arr.length - 1 && (
                              <p className="text-neutral-100">vs.</p>
                            )}
                          </>
                        ))}
                      </h1>
                      <div className="h-6 w-6 flex items-center justify-center">
                        <Tooltip
                          title={
                            <>
                              Portfolios as at: <br />
                              {validFundQueriesData.map((f, idx) => (
                                <React.Fragment key={f._id}>
                                  {extractFundTitle(f)} - {fundDates[idx]}{' '}
                                  <br />
                                </React.Fragment>
                              ))}
                            </>
                          }
                          placement="bottomRight"
                          color="#3E414B"
                          overlayClassName="md:max-w-none lg:whitespace-nowrap"
                          overlayInnerStyle={{
                            width: 'fit-content',
                          }}
                          arrow={{ pointAtCenter: true }}
                          onOpenChange={setTooltipVisible}
                        >
                          <InfoIcon
                            className={cx('icon text-neutral !w-4 !h-4', {
                              'text-neutral-100': tooltipVisible,
                            })}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
                <h2 className="text-xs text-regular">
                  {matchingBenchmark?.name}
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* CONTENT */}
        <div className={cx(styles['compare-content-wrapper'])}>
          <div
            className={cx(
              styles['compare-tool-table-container'],
              'grid gap-y-4 md:gap-y-6 lg:px-5 overflow-auto xl:overflow-hidden',
            )}
          >
            {/* First column */}
            <div>
              <CompareCollapse
                activeKey={activeKeys}
                onChange={handleCollapseChange}
              >
                <Panel
                  showArrow={true}
                  className="bg-white overflow-hidden"
                  key="fundBasics"
                  header={<h3 className="!font-bold py-4 pl-4">Fund Basics</h3>}
                >
                  <CompareFundBasics
                    funds={validFundQueriesData}
                    loading={isSomethingLoading}
                  />
                </Panel>
                {hasHoldingsCompareData && (
                  <Panel
                    showArrow={true}
                    className="bg-white overflow-hidden"
                    key="holdings"
                    header={
                      <h3 className="!font-bold py-4 pl-4">Top 10 Holdings</h3>
                    }
                  >
                    <CompareHoldings
                      data={holdingsCompareData}
                      funds={validFundQueriesData}
                      showIndexColumn={showIndexColumn}
                      loading={isSomethingLoading}
                      benchmarkHoldings={benchmarkHoldingsQuery.data?.holdings}
                    />
                  </Panel>
                )}

                {hasSectorsNoFIOrPadi &&
                  renderComparePanelSector(
                    'sectors-eq',
                    FundBroadAssetClass.Equity,
                    FundTitleLabel.SECTORS_EQ,
                  )}

                {hasGeographyCountriesNoFI &&
                  renderComparePanelGeography(
                    'geography-eq',
                    FundBroadAssetClass.Equity,
                    FundTitleLabel.GEOGRAPHY_EQ,
                  )}

                {hasMarketCapsLongDataNoFI && (
                  <Panel
                    showArrow={true}
                    className="bg-white overflow-hidden"
                    key="market-cap"
                    header={
                      <h3 className="!font-bold py-4 pl-4">Market Cap</h3>
                    }
                  >
                    <CompareMarketCap
                      data={marketCapCompareData}
                      funds={validFundQueriesData}
                      loading={isSomethingLoading}
                    />
                  </Panel>
                )}

                {hasSectorsNoEquity &&
                  renderComparePanelSector(
                    'sectors-fi',
                    FundBroadAssetClass.FixedIncome,
                    FundTitleLabel.SECTORS_FI,
                  )}

                {hasGeographyNoEquity &&
                  renderComparePanelGeography(
                    'geography-fi',
                    FundBroadAssetClass.FixedIncome,
                    FundTitleLabel.GEOGRAPHY_FI,
                  )}

                {hasSectorsPadi &&
                  renderComparePanelSector(
                    'sectors',
                    FundBroadAssetClass.Equity,
                    FundTitleLabel.SECTORS,
                    true,
                  )}

                {hasGeographyCountriesPadi &&
                  renderComparePanelGeography(
                    'geography',
                    FundBroadAssetClass.Equity,
                    FundTitleLabel.GEOGRAPHY,
                    true,
                  )}

                {hasMaturityNoEquity && (
                  <Panel
                    showArrow={true}
                    className="bg-white overflow-hidden"
                    key="maturity"
                    header={<h3 className="!font-bold py-4 pl-4">Maturity</h3>}
                  >
                    <CompareMaturity
                      funds={validFundQueriesData}
                      loading={isSomethingLoading}
                    />
                  </Panel>
                )}

                {hasCreditQualityNoEquity && (
                  <Panel
                    showArrow={true}
                    className="bg-white overflow-hidden"
                    key="credit-quality"
                    header={
                      <h3 className="!font-bold py-4 pl-4">Credit Quality</h3>
                    }
                  >
                    <CompareCreditQuality
                      funds={validFundQueriesData}
                      loading={isSomethingLoading}
                    />
                  </Panel>
                )}
                <Panel
                  showArrow={true}
                  className={`bg-white overflow-hidden with-extra-header ${
                    screenWidthMode[ScreenWidthEnum.MaxSm]
                      ? 'collapse-move-arrow'
                      : ''
                  }`}
                  key="absolute-chart"
                  header={
                    <div className="px-4 sm:pr-10 sm:pl-4 py-0.5">
                      <AbsoluteChartTitle
                        hasInvestmentTrust={hasInvestmentTrust}
                        showDropdown={true}
                      />
                    </div>
                  }
                >
                  {orderedFunds.length >= 2 && (
                    <div className="p-4 pt-0">
                      <div className={styles['chart-wrapper']}>
                        <AbsoluteChart
                          emptyState={{
                            title: 'No data available for this fund',
                          }}
                          isins={isinsForChartRequest}
                          benchmarkId={benchmarkSecId}
                          hasInvestmentTrust={hasInvestmentTrust}
                          showDropdown={true}
                          retainSortOrder={true}
                          scrollable={true}
                        />
                      </div>
                    </div>
                  )}
                </Panel>
                {useEquityTemplate &&
                  benchmarkSecId &&
                  orderedFunds.length >= 2 &&
                  isinsForChartRequest.length !== 0 && (
                    <Panel
                      showArrow={true}
                      className={`bg-white overflow-hidden ${
                        screenWidthMode[ScreenWidthEnum.MaxSm]
                          ? 'collapse-move-arrow'
                          : ''
                      }`}
                      key="relative-chart"
                      header={
                        <div className="px-4 sm:pr-10 sm:pl-4 py-0.5">
                          <RelativeChartTitle
                            hasInvestmentTrust={hasInvestmentTrust}
                            benchmarkName={matchingBenchmark?.name}
                            showDropdown={true}
                          />
                        </div>
                      }
                    >
                      {orderedFunds.length >= 2 && (
                        <div className="p-4 pr-0 pt-0 overflow-scroll collapse-move-arrow">
                          <div className={styles['chart-wrapper']}>
                            <RelativeChart
                              emptyState={{
                                title: 'No data available for this fund',
                              }}
                              isins={isinsForChartRequest}
                              benchmarkId={benchmarkSecId}
                              hasInvestmentTrust={hasInvestmentTrust}
                              showDropdown={true}
                              retainSortOrder={true}
                              scrollable={true}
                            />
                          </div>
                        </div>
                      )}
                    </Panel>
                  )}

                {!!validFundQueriesData.length && (
                  <>
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="annualised-performance"
                      header={
                        <h3 className="!font-bold py-4 pl-4">
                          Annualised Performance
                        </h3>
                      }
                    >
                      <CompareAnnualisedPerformance
                        funds={validFundQueriesData}
                        benchmarkData={benchmarkQuery.data ?? undefined}
                        loading={isSomethingLoading}
                      />
                    </Panel>
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="calendar-performance"
                      header={
                        <h3 className="!font-bold py-4 pl-4">
                          Calendar Performance
                        </h3>
                      }
                    >
                      <CompareCalendarYearPerformance
                        funds={validFundQueriesData}
                        benchmarkData={benchmarkQuery.data ?? undefined}
                        loading={isSomethingLoading}
                      />
                    </Panel>
                    <Panel
                      showArrow={true}
                      className={`bg-white rounded-none lg:rounded-lg overflow-hidden with-extra-header ${
                        screenWidthMode[ScreenWidthEnum.MaxSm]
                          ? 'collapse-move-arrow'
                          : ''
                      }`}
                      key="risk"
                      header={
                        <div className="flex flex-col sm:flex-row justify-between sm:items-center py-3 px-4 sm:pl-4 sm:pr-10">
                          <h3 className="!font-bold pb-1 sm:pb-0">
                            Risk <br />
                            <span className="font-normal text-neutral">
                              vs. category benchmark
                            </span>
                          </h3>
                          <div onClick={e => e.stopPropagation()}>
                            <Dropdown
                              trigger={['click']}
                              placement="bottom"
                              menu={{
                                items: isMobile
                                  ? []
                                  : allowedPeriodsForDropdown,
                              }}
                            >
                              <div className="hover:fill-[#40a9ff] text-neutral fill-[#545576]">
                                <Button
                                  className="w-full text-neutral-700 text-left flex items-center justify-between border border-light rounded hover:border-primary"
                                  onClick={() =>
                                    isMobile && setIsNestedDrawerVisible(true)
                                  }
                                >
                                  <p className="truncate text-sm mb-0 flex-grow">
                                    {
                                      allowedPeriodsForDropdown.find(
                                        p => p.key === currentPeriod,
                                      )?.label
                                    }
                                  </p>
                                  <NextArrow className="w-3 rotate-90 fill-current" />
                                </Button>
                              </div>
                            </Dropdown>
                            {isMobile && (
                              <NestedDrawer
                                menuItems={allowedPeriodsForDropdown}
                                visible={isNestedDrawerVisible}
                                onClose={() => setIsNestedDrawerVisible(false)}
                                title="Select"
                              />
                            )}
                          </div>
                        </div>
                      }
                    >
                      <CompareRisk
                        funds={validFundQueriesData}
                        loading={isSomethingLoading}
                        dateRange={timePeriod}
                      />
                    </Panel>
                  </>
                )}
              </CompareCollapse>
            </div>
          </div>
        </div>
        <CompareFundSelectModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          initialFunds={validFundQueriesData.map(f => f.shareClassDetails)}
          initialBenchmarkName={matchingBenchmark?.secId}
        />
      </div>
    </BasePageWithMetadata>
  );
};

export default CompareFundPage;
