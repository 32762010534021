import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}

const withScrollToTop =
  <T extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<T>,
  ): React.FC<T> =>
  (props: T) => {
    return (
      <ScrollToTop>
        <Component {...(props as T)} />
      </ScrollToTop>
    );
  };

export default withScrollToTop;
