import {
  NotificationsSettingsForCommentary,
  NotificationsSettingsForNotesAndMeetings,
  NotificationsSettingsForSignificantChanges,
  SettingsApi,
} from '@aminsights/contract';
import {
  ChangeTypeEnum,
  DEFAULT_MONTHS_FOR_NOTIFICATIONS,
  USER_PERMISSIONS,
} from '@aminsights/shared';
import { Checkbox, Modal, Switch } from 'antd';
import { isEqual } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ReactComponent as IconArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Button } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import NotificationModalChecklist, {
  NotificationType,
} from '@/partials/Modal/ModalChecklist';
import ResponsiveDropdown from '@/partials/ResponsiveDropdown';
import { openApiConfig } from '@/utils';
import { validatePermissions } from '@/utils/auth';

const Profile: React.FCWithChild = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaveChangesEnabled, setIsSaveChangesEnabled] = useState(false);
  const { dispatch: dispatchApp } = useAppContext();
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const { isWeeklyUpdateTreatementEnabled, isResearchEnabled } =
    featureSwitchState;

  const settingsAPI = new SettingsApi(openApiConfig());
  const [
    significantChangesNotificationSettings,
    setSignificantChangesNotificationSettings,
  ] = useState<NotificationsSettingsForSignificantChanges>();
  const [
    tempSignificantChangesNotificationSettings,
    setTempSignificantChangesNotificationSettings,
  ] = useState<NotificationsSettingsForSignificantChanges>();
  const [commentaryNotificationSettings, setCommentaryNotificationSettings] =
    useState<NotificationsSettingsForCommentary>({
      email: false,
      inApp: false,
    });
  const [
    notesAndMeetingsNotificationSettings,
    setNotesAndMeetingsNotificationSettings,
  ] = useState<NotificationsSettingsForNotesAndMeetings>({
    noteUpdateStale: {
      enabled: false,
      monthsPeriod: DEFAULT_MONTHS_FOR_NOTIFICATIONS,
    },
    meetingGap: {
      enabled: false,
      monthsPeriod: DEFAULT_MONTHS_FOR_NOTIFICATIONS,
    },
  });

  const [shouldSendWeeklyUpdate, setShouldSendWeeklyUpdate] =
    useState<boolean>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    settingsAPI.getNotificationsSettings().then(notificationSettings => {
      setCommentaryNotificationSettings(notificationSettings.data.commentary);
      setSignificantChangesNotificationSettings(
        notificationSettings.data.significantChanges,
      );
      setTempSignificantChangesNotificationSettings(
        notificationSettings.data.significantChanges,
      );
      setShouldSendWeeklyUpdate(
        notificationSettings.data.shouldSendWeeklyUpdate,
      );
      setNotesAndMeetingsNotificationSettings(
        notificationSettings.data.notesAndMeetings,
      );
    });
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (settingsAPI) {
      settingsAPI.getNotificationsSettings().then(notificationSettings => {
        setSignificantChangesNotificationSettings(
          notificationSettings.data.significantChanges,
        );
        setTempSignificantChangesNotificationSettings(
          notificationSettings.data.significantChanges,
        );
      });
    }
  }, [isModalVisible]);

  useEffect(() => {
    setIsDirty(
      !isEqual(
        tempSignificantChangesNotificationSettings,
        significantChangesNotificationSettings,
      ),
    );
  }, [
    significantChangesNotificationSettings,
    tempSignificantChangesNotificationSettings,
  ]);

  const hasWriteResearchPermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const periodMonths = (
    key: keyof NotificationsSettingsForNotesAndMeetings,
  ) => [
    ...Array.from({ length: 6 }).map((_val, index) => {
      const month = (index + 1) * 3;
      return {
        key: `${month}mths`,
        value: month,
        label: `${month} months`,
        onClick: () => {
          setPeriodForNotesAndMeetings(key, month);
        },
      };
    }),
  ];

  const setPeriodForNotesAndMeetings = (
    key: keyof NotificationsSettingsForNotesAndMeetings,
    periodMonths: number,
  ) => {
    if (
      notesAndMeetingsNotificationSettings[key].monthsPeriod !== periodMonths
    ) {
      if (!isSaveChangesEnabled) {
        setIsSaveChangesEnabled(true);
      }
      handleNotesNotificationChanged(key, periodMonths, true);
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const setCheckedAll = (type: NotificationType, value: boolean) => {
    setTempSignificantChangesNotificationSettings(prev => {
      if (prev) {
        const updatedNotificationSettings = {
          ...prev[type],
        };
        Object.keys(updatedNotificationSettings).forEach((key: string) => {
          updatedNotificationSettings[key as ChangeTypeEnum] = value;
        });
        return {
          ...prev,
          [type]: updatedNotificationSettings,
        };
      }
      return prev;
    });
    setIsDirty(true);
  };

  const setChecked = (
    key: ChangeTypeEnum,
    type: NotificationType,
    value?: boolean,
  ) => {
    setTempSignificantChangesNotificationSettings(prev => {
      if (prev) {
        const subProperty = prev[type];
        return {
          ...prev,
          [type]: {
            ...subProperty,
            [key]: value === undefined ? !subProperty[key] : value,
          },
        };
      }
      return prev;
    });
    setIsDirty(true);
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const handleCommentaryNotificationChanged = useCallback(
    (key: 'inApp' | 'email') => {
      const changedProperty = !commentaryNotificationSettings[key];
      const commentaryNotifications = {
        ...commentaryNotificationSettings,
        ...{ [key]: changedProperty },
      };
      if (
        significantChangesNotificationSettings &&
        shouldSendWeeklyUpdate !== undefined
      ) {
        settingsAPI.saveNotificationsSettings({
          significantChanges: significantChangesNotificationSettings,
          commentary: commentaryNotifications,
          shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
          notesAndMeetings: notesAndMeetingsNotificationSettings,
        });
      }
      setCommentaryNotificationSettings(commentaryNotifications);
    },
    [
      commentaryNotificationSettings,
      significantChangesNotificationSettings,
      shouldSendWeeklyUpdate,
    ],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const handleNotesNotificationChanged = useCallback(
    (
      key: keyof NotificationsSettingsForNotesAndMeetings,
      period: number,
      isEnabled: boolean,
    ) => {
      const notesNotifications = {
        ...notesAndMeetingsNotificationSettings,
        ...{ [key]: { enabled: isEnabled, monthsPeriod: period } },
      };
      setNotesAndMeetingsNotificationSettings(notesNotifications);
    },
    [
      notesAndMeetingsNotificationSettings,
      commentaryNotificationSettings,
      significantChangesNotificationSettings,
      shouldSendWeeklyUpdate,
    ],
  );

  const handleModalClose = () => {
    setIsDirty(false);
    setIsModalVisible(false);
  };

  return (
    <div className="pt-5 flex flex-row justify-center">
      <Modal
        title={
          <span data-test-id="notificationsModalTitle">
            Significant Changes
          </span>
        }
        className="max-sm:full-page-modal information-modal"
        open={isModalVisible}
        onClose={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button
            size="large"
            type="link"
            className="font-semibold m-0 !text-neutral-700 !text-sm"
            onClick={handleModalClose}
            key="secondary"
          >
            Cancel
          </Button>,
          <Button
            data-test-id="notificationsModalSaveButton"
            size="large"
            type="primary"
            className="font-semibold m-0"
            onClick={async () => {
              if (
                tempSignificantChangesNotificationSettings &&
                shouldSendWeeklyUpdate !== undefined
              ) {
                await settingsAPI.saveNotificationsSettings({
                  significantChanges:
                    tempSignificantChangesNotificationSettings,
                  commentary: commentaryNotificationSettings,
                  shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
                  notesAndMeetings: notesAndMeetingsNotificationSettings,
                });
              }
              setSignificantChangesNotificationSettings(
                tempSignificantChangesNotificationSettings,
              );
              setIsDirty(false);
              dispatchApp({
                type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                payload: { text: 'Successfully saved changes' },
              });
              toggleModal();
            }}
            disabled={!isDirty}
            key="primary"
          >
            Save
          </Button>,
        ]}
        data-test-id="notificationsModal"
      >
        {tempSignificantChangesNotificationSettings && (
          <NotificationModalChecklist
            notificationSettings={tempSignificantChangesNotificationSettings}
            setChecked={setChecked}
            setAllChecked={setCheckedAll}
          />
        )}
      </Modal>
      <div className="section-notifications px-4">
        <p className="md:w-[600px] mb-4 pr-4 pl-4 text-neutral">
          Manage your notifications across your watchlist
        </p>
        <div className="section-notifications__tabs">
          <div className="w-full">
            <div className="bg-grey-lighter w-full">
              <h3
                className="pl-4 pr-4 pt-2 pb-2 !text-sm text-darkest leading-md mb-0 important"
                data-test-id="noticationsSectionScTitle"
              >
                Significant Changes
              </h3>
            </div>
            <div
              role="button"
              className="flex items-center justify-between px-4 py-2 cursor-pointer group"
              onClick={() => toggleModal()}
              onKeyDown={() => toggleModal()}
              tabIndex={0}
              data-test-id="manageNotificationsButton"
            >
              <div>
                <h3 className="mb-0 text-darkest !text-sm font-semibold important">
                  Manage Notifications
                </h3>
                <p className="text-neutral pb-4">
                  Choose which notifications you want and how you receive them.
                </p>
              </div>
              <IconArrow className="icon text-neutral-100 group-hover:text-neutral" />
            </div>
          </div>

          <div className="w-full">
            <div className="bg-grey-lighter w-full">
              <h3
                className="pl-4 pr-4 pt-2 pb-2 !text-sm text-darkest leading-md mb-0 important"
                data-test-id="noticationsSectionScTitle"
              >
                Commentary
              </h3>
            </div>
            <p className="mt-2 px-4 text-neutral">
              Choose how you want to receive notifications for newly added
              commentaries
            </p>
            <div className="flex flex-col gap-y-2 px-6 py-4">
              <div className="section-notifications--commentary-field">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={commentaryNotificationSettings.inApp}
                    onChange={() => {
                      setIsSaveChangesEnabled(true);
                      handleCommentaryNotificationChanged('inApp');
                    }}
                    id="commentaryNotificationSettingsInApp"
                    data-test-id="commentaryNotificationSettingsInApp"
                  />
                  <label
                    className="cursor-pointer text-neutral-200"
                    htmlFor="commentaryNotificationSettingsInApp"
                  >
                    In-app
                  </label>
                </div>
              </div>
              <div className="section-notifications--commentary-field">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={commentaryNotificationSettings.email}
                    onChange={() => {
                      setIsSaveChangesEnabled(true);
                      handleCommentaryNotificationChanged('email');
                    }}
                    id="commentaryNotificationSettingsEmail"
                    data-test-id="commentaryNotificationSettingsEmail"
                  />
                  <label
                    className="cursor-pointer text-neutral-200"
                    htmlFor="commentaryNotificationSettingsEmail"
                  >
                    Email
                  </label>
                </div>
              </div>
            </div>
          </div>

          {isWeeklyUpdateTreatementEnabled && (
            <div className="w-full">
              <div className="bg-grey-lighter w-full">
                <h3
                  className="pl-4 pr-4 pt-2 pb-2 !text-sm text-darkest leading-md mb-0 important"
                  data-test-id="noticationsSectionScTitle"
                >
                  Weekly Update
                </h3>
              </div>
              <div className="w-full flex flex-row justify-between py-4 pr-4 flex items-center">
                <p className="mt-2 px-4 text-neutral">
                  Receive weekly update emails
                </p>
                <Switch
                  className="section-notifications--weekly-update-switch"
                  onChange={checked => {
                    setIsSaveChangesEnabled(true);
                    setShouldSendWeeklyUpdate(checked);
                  }}
                  checked={shouldSendWeeklyUpdate}
                />
              </div>
            </div>
          )}

          {isResearchEnabled && hasWriteResearchPermissions && (
            <div className="w-full">
              <div className="bg-grey-lighter w-full">
                <h3
                  className="pl-4 pr-4 pt-2 pb-2 !text-sm text-darkest leading-md mb-4 important"
                  data-test-id="noticationsSectionScTitle"
                >
                  Notes and Meetings
                </h3>
              </div>
              <div className="w-full flex flex-col gap-x-4 md:flex-row landscape:flex-row justify-between px-6 md:pr-0 landscape:pr-0 md:items-center">
                <div className="flex items-center gap-2 px-1 md:px-0 landscape:px-0">
                  <Checkbox
                    checked={
                      notesAndMeetingsNotificationSettings.noteUpdateStale
                        .enabled
                    }
                    onChange={() => {
                      setIsSaveChangesEnabled(true);
                      handleNotesNotificationChanged(
                        'noteUpdateStale',
                        notesAndMeetingsNotificationSettings.noteUpdateStale
                          .monthsPeriod,
                        !notesAndMeetingsNotificationSettings.noteUpdateStale
                          .enabled,
                      );
                    }}
                    id="notesNotificationSettingsStale"
                    data-test-id="notesNotificationSettingsStale"
                  />
                  <label
                    className="cursor-pointer text-neutral-200 w-full"
                    htmlFor="notesNotificationSettingsStale"
                  >
                    Notify me when a note hasn&rsquo;t been updated for
                  </label>
                </div>
                <ResponsiveDropdown
                  disabled={
                    !notesAndMeetingsNotificationSettings.noteUpdateStale
                      .enabled
                  }
                  options={periodMonths('noteUpdateStale')}
                  value={
                    notesAndMeetingsNotificationSettings.noteUpdateStale
                      .monthsPeriod
                  }
                  nestedDrawerTitle="Time"
                />
              </div>
              <div className="w-full flex flex-col gap-x-4 md:flex-row landscape:flex-row justify-between my-2 px-6 md:pr-0 landscape:pr-0 md:items-center">
                <div className="flex items-center gap-2 px-1 md:px-0 landscape:px-0">
                  <Checkbox
                    checked={
                      notesAndMeetingsNotificationSettings.meetingGap.enabled
                    }
                    onChange={() => {
                      setIsSaveChangesEnabled(true);
                      handleNotesNotificationChanged(
                        'meetingGap',
                        notesAndMeetingsNotificationSettings.meetingGap
                          .monthsPeriod,
                        !notesAndMeetingsNotificationSettings.meetingGap
                          .enabled,
                      );
                    }}
                    id="notesNotificationSettingsGap"
                    data-test-id="notesNotificationSettingsGap"
                  />
                  <label
                    className="cursor-pointer text-neutral-200 w-full"
                    htmlFor="notesNotificationSettingsGap"
                  >
                    Notify me when a manager hasn’t been met for
                  </label>
                </div>
                <ResponsiveDropdown
                  disabled={
                    !notesAndMeetingsNotificationSettings.meetingGap.enabled
                  }
                  options={periodMonths('meetingGap')}
                  value={
                    notesAndMeetingsNotificationSettings.meetingGap.monthsPeriod
                  }
                  nestedDrawerTitle="Time"
                />
              </div>
            </div>
          )}
          {(isWeeklyUpdateTreatementEnabled ||
            (isResearchEnabled && hasWriteResearchPermissions)) && (
            <div className="flex flex-row justify-end mt-6">
              <Button
                disabled={!isSaveChangesEnabled}
                className="h-10 w-20 font-medium bg-primary border-primary"
                type="primary"
                size="large"
                htmlType="submit"
                data-test-id="saveNotificationSettings"
                loading={submitting}
                onClick={async () => {
                  setSubmitting(true);
                  try {
                    if (
                      !!significantChangesNotificationSettings &&
                      !!notesAndMeetingsNotificationSettings &&
                      shouldSendWeeklyUpdate !== undefined
                    )
                      await settingsAPI.saveNotificationsSettings({
                        significantChanges:
                          significantChangesNotificationSettings,
                        commentary: commentaryNotificationSettings,
                        shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
                        notesAndMeetings: notesAndMeetingsNotificationSettings,
                      });
                    setIsSaveChangesEnabled(false);
                    dispatchApp({
                      type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                      payload: { text: 'Successfully saved changes' },
                    });
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
