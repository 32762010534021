import { Tag } from 'antd';
import cx from 'classnames';
import React from 'react';

import { CheckboxProps, ICheckboxData } from '../types';

const { CheckableTag: Checkbox } = Tag;

const ButtonCheckbox: React.FCWithChild<CheckboxProps> = ({
  data,
  value,
  onChange,
  direction,
}) => {
  const isChecked = (itemValue: string | number) => {
    const index =
      value?.findIndex((idx: ICheckboxData) => idx.value === itemValue) ?? -1;
    return index > -1;
  };

  const handleCheck = (item: ICheckboxData, checked: boolean) => {
    onChange((prevValues: Array<ICheckboxData>) => {
      const newValues: Array<ICheckboxData> = prevValues || [];
      if (checked) {
        return [...newValues, item];
      }
      return newValues.filter(i => i.value !== item.value);
    });
  };

  return (
    <div
      className={cx(
        'flex flex-wrap',
        '[&_.ant-tag-checkable]:mb-2 [&_.ant-tag-checkable]:py-2 [&_.ant-tag-checkable]:px-4 [&_.ant-tag-checkable]:text-sm [&_.ant-tag-checkable]:text-neutral',
        '[&_.ant-tag-checkable]:rounded [&_.ant-tag-checkable]:border [&_.ant-tag-checkable]:border-grey-light [&_.ant-tag-checkable]:select-none',
        '[&_.ant-tag-checkable:hover]:text-primary [&_.ant-tag-checkable:hover]:border-primary [&_.ant-tag-checkable:active]:bg-transparent ',
        '[&_.ant-tag-checkable-checked]:!text-primary [&_.ant-tag-checkable-checked]:!border-primary',
        '[&_.ant-tag-checkable-checked]:!bg-transparent',
        `${direction}`,
      )}
    >
      {data?.map(item => (
        <Checkbox
          key={item.value}
          checked={isChecked(item.value)}
          onChange={(checked: boolean) => handleCheck(item, checked)}
        >
          {item.label}
        </Checkbox>
      ))}
    </div>
  );
};

export default ButtonCheckbox;
