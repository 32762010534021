import { FUND_BOX_X_AND_Y_LABEL_VALUE } from '@aminsights/shared';
import { getHighlightStyleboxColor } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

const RenderStyleBox: React.FCWithChild<{
  isFixedIncome?: boolean;
  isBoxClickable?: boolean;
  isClear?: boolean;
  onSelectedBox?: (value: number[]) => void;
  selectedBox?: number[] | number;
  styleBoxValue?: number;
}> = ({
  isFixedIncome,
  isBoxClickable,
  onSelectedBox,
  selectedBox,
  isClear,
  styleBoxValue,
}) => {
  const { data: state } = useFundByIsInParam();
  const [selectedBoxes, setSelectedBoxes] = useState<number[]>([]);

  const STYLE_BOX_COUNT = 9;

  const getXLabel = (labelData: string[]) => {
    return labelData.map(value => (
      <div
        className="font-normal text-xs text-neutral w-[6em] sm:w-[6.9em] text-start pt-2 sm:pt-0 pb-2"
        key={value}
      >
        {value}
      </div>
    ));
  };

  const getYLabel = (labelData: string[]) => {
    return labelData.map(value => (
      <div
        className={cx(
          'font-normal text-xs text-neutral relative h-[6em] pl-2 sm:h-[7em]',
        )}
        key={value}
      >
        <div
          className="absolute"
          style={{ writingMode: 'vertical-rl', textOrientation: 'sideways' }}
        >
          {value}
        </div>
      </div>
    ));
  };

  const handleBoxClick = (index: number) => {
    if (onSelectedBox) {
      if (selectedBoxes.includes(index)) {
        const updatedSelectedBoxes = selectedBoxes.filter(
          boxIndex => boxIndex !== index,
        );
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      } else {
        const updatedSelectedBoxes = [...selectedBoxes, index];
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      }
    }
  };

  useEffect(() => {
    if (isClear) {
      setSelectedBoxes([]);
    }
  }, [isClear]);

  useEffect(() => {
    if (selectedBox) {
      if (Array.isArray(selectedBox)) {
        setSelectedBoxes(selectedBox);
      } else {
        setSelectedBoxes([selectedBox]);
      }
    }
  }, [selectedBox]);

  const boxBackgroundColor = () => {
    return getHighlightStyleboxColor(isFixedIncome ?? false);
  };

  const renderBox = () => {
    const boxes = [];
    for (let i = 0; i < STYLE_BOX_COUNT; i += 3) {
      boxes.push(
        <div
          className={cx(
            'flex border-l h-1/3',
            isFixedIncome ? 'border-l-[#97D17D]' : 'border-l-[#99CCFF]',
          )}
          key={i}
        >
          {[i + 1, i + 2, i + 3].map(index => (
            <div
              data-test-id={`${isFixedIncome ? 'bond' : 'equity'}Index${index}`}
              key={index}
              onClick={() => handleBoxClick(index)}
              className={cx(
                'box-border w-1/3 p-0.5',
                isFixedIncome
                  ? 'border-r border-b border-[#97D17D]'
                  : 'border-r border-b border-[#99CCFF]',
              )}
              style={{
                backgroundColor: boxBackgroundColor(),
              }}
            >
              <div
                className={cx('box-border h-full', {
                  'hover:cursor-pointer hover:border-4 hover:border-[#003e7d50] hover:bg-[#99CCFF]':
                    isBoxClickable && !isFixedIncome,
                  'cursor-pointer border-4 border-[#003e7d50]':
                    selectedBoxes.includes(index) && !isFixedIncome,
                  'bg-[#99CCFF]':
                    (styleBoxValue === index ||
                      selectedBoxes.includes(index)) &&
                    !isFixedIncome,
                  'hover:cursor-pointer hover:border-4 hover:border-[#00520050] hover:bg-[#97d17d]':
                    isBoxClickable && isFixedIncome,
                  'cursor-pointer border-4 border-[#00520050]':
                    selectedBoxes.includes(index) && isFixedIncome,
                  'bg-[#97d17d]':
                    (styleBoxValue === index ||
                      selectedBoxes.includes(index)) &&
                    isFixedIncome,
                })}
              />
            </div>
          ))}
        </div>,
      );
    }
    return boxes;
  };

  return (
    <div data-test-id="fundDetailsPortfolioTabStyleBox">
      <div className="pl-2 bg-white rounded-lg">
        <div className="font-bold text-sm text-darkest">
          {state?.fund?.broadAssetClass}
        </div>
        <div className={cx('flex', !isBoxClickable && 'pt-2')}>
          <div
            className={cx(
              'border-t w-[216px] sm:w-[243px] h-[216px] sm:h-[243px]',
              isFixedIncome ? 'border-t-[#97D17D]' : 'border-t-[#99CCFF]',
            )}
          >
            {renderBox()}
          </div>
          <div className="flex flex-col">
            {getYLabel(
              isFixedIncome
                ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.yName
                : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.yName,
            )}
          </div>
        </div>
        <div className="flex text-center">
          {getXLabel(
            isFixedIncome
              ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.xName
              : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.xName,
          )}
        </div>
      </div>
      {!isBoxClickable && (
        <div className="w-full px-2 py-4">
          <p className="text-xs text-neutral">Only captures long positions</p>
        </div>
      )}
    </div>
  );
};

export default RenderStyleBox;
