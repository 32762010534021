import { Tabs } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageResults } from '@/components';
import usePortfolioEdgeDates from '@/hooks/query-hooks/portfolio-hooks/usePortfolioEdgeDates';
import { evaluatePortfolioSubRoute } from '@/utils';

import { PORTFOLIO_TAB } from '../types';
import { useProvidePortfolio } from '../useProvidePortfolio';
import PortfolioChartsTab from './Tabs/PortfolioChartsTab';
import PortfolioHoldingsTab from './Tabs/PortfolioHoldingsTab';
import PortfolioPerformanceTab from './Tabs/PortfolioPerformanceTab';
import PortfolioPlatformsTab from './Tabs/PortfolioPlatformsTab';
import PortfolioSettingsTab from './Tabs/PortfolioSettingsTab';
import style from './style.module.less';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('GMT');

const PortfolioPagesWrapper: React.FCWithChild = () => {
  const history = useHistory();

  const [totalFunds, setTotalFunds] = React.useState<number | undefined>(0);

  const { state } = useProvidePortfolio();

  const { tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setTotalFunds(currentPortfolio?.funds.length);
  }, [state]);

  // We just need the hook to fire
  usePortfolioEdgeDates({
    portfolioId: state.currentPortfolioId,
  });

  return (
    <div className={cx('bg-white lg:rounded-lg')}>
      <div className={cx(style['portfolio-tabs'], 'relative')}>
        <Tabs
          activeKey={tab}
          onChange={(tab: string) => {
            history.push(`${evaluatePortfolioSubRoute(tab)}`);
          }}
          tabBarExtraContent={
            (tab === PORTFOLIO_TAB.HOLDINGS ||
              tab === PORTFOLIO_TAB.PERFORMANCE) &&
            totalFunds && (
              <PageResults
                totalCount={
                  state.portfolios.find(p => p._id === state.currentPortfolioId)
                    ?.totalFunds || 0
                }
              />
            )
          }
          defaultActiveKey={PORTFOLIO_TAB.HOLDINGS}
          items={[
            {
              label: <span data-test-id="tabPortfolioHoldings">Holdings</span>,
              key: PORTFOLIO_TAB.HOLDINGS,
              children: (
                <PortfolioHoldingsTab
                  key={`holdings-${currentPortfolio?._id}`}
                />
              ),
            },
            {
              label: (
                <span data-test-id="tabPortfolioPerformance">Performance</span>
              ),
              key: PORTFOLIO_TAB.PERFORMANCE,
              children: currentPortfolio && (
                <PortfolioPerformanceTab
                  key={`performance-${currentPortfolio?._id}`}
                />
              ),
            },
            {
              label: <span data-test-id="tabPortfolioCharts">Charts</span>,
              key: PORTFOLIO_TAB.CHARTS,
              children: <PortfolioChartsTab />,
            },
            {
              label: (
                <span data-test-id="tabPortfolioPlatforms">Platforms</span>
              ),
              key: PORTFOLIO_TAB.PLATFORMS,
              children: <PortfolioPlatformsTab />,
            },
            {
              label: (
                <span data-test-id="tabPortfolioSettings">
                  Portfolio Settings
                </span>
              ),
              key: PORTFOLIO_TAB.SETTINGS,
              children: currentPortfolio && <PortfolioSettingsTab />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PortfolioPagesWrapper;
