import { Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { APP_ACTIONS, UpdateProfileFormProps } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { UpdateProfileForm } from '@/partials/Forms/Settings';

import { useProvideProfile } from './useProvideProfile';

const Profile: React.FCWithChild = () => {
  const { user, success, setSuccess, submitting, error, patchUserProfile } =
    useProvideProfile();
  const { dispatch: dispatchApp } = useAppContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const onSubmit = useCallback((values: UpdateProfileFormProps) => {
    const formData = {
      family_name: values.lastName,
      given_name: values.firstName,
      ...(user?.phone_number !== values.phone &&
        values.phone !== '' && {
          phone_number: values.phone,
        }),
    };
    patchUserProfile(formData);
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (success) {
      dispatchApp({
        type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
        payload: { text: 'Your profile has been updated.' },
      });

      setSuccess(false);
    }
  }, [success]);

  return (
    <div className="p-4 flex flex-row justify-center">
      {user ? (
        <UpdateProfileForm
          user={user}
          onSubmit={onSubmit}
          submitting={submitting}
          error={error}
        />
      ) : (
        <div className="mb-5">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Profile;
