import { Fund, PortfolioFundForUpdate } from '@aminsights/contract';
import { LIMIT_FUNDS_PER_PORTFOLIO } from '@aminsights/shared';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@/components';
import { UPLOAD_FAILED } from '@/constants/text-messages';
import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import BasePageWithMetadata from '@/pages/app/BasePageWithMetadata';
import useProvideHelp from '@/pages/app/Help/useProvideHelp';
import {
  FailedStatus,
  UploadModalInfoBase,
  UploadModalInfoItems,
} from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { openJiraForm } from '@/utils/jiraForm';

import AssignFundsToPortfolioComponent, {
  AssignFundsToPortfolioComponentRef,
} from './AssignFundsToPortfolioComponent';
import UploadFunds from './UploadFunds';
import UploadModal from './UploadModal';

const emptyUploadModalInfo = {
  title: '',
  description: '',
  unavailableISINsList: [],
  failedStatus: FailedStatus.NONE,
};

const ImportPortfolio: React.FCWithChild = () => {
  const [importedIsins, setImportedIsins] = useState<string[]>([]);
  const { data: multipleFundsByIsinsResponse, error } =
    useMultipleFundsByIsins(importedIsins);
  const assignFundFormRef = useRef<AssignFundsToPortfolioComponentRef>(null);

  const { sendUnavailableIsinsEmail } = useProvideHelp();
  const { currentWidth } = useScreenWidth();
  const isMobile = currentWidth < screenBreakpoints.sm;

  const [isAssignModalSaveButtonDisabled, setIsAssignModalSaveButtonDisabled] =
    useState(false);
  const [allDates, setAllDates] = useState<string[]>([]);
  const [isFundsImported, setIsFundsImported] = useState<boolean>(false);
  const [fileList, setFileList] = useState<string[]>([]);
  const [importedFundsList, setImportedFundsList] = useState<
    (Partial<Fund> & PortfolioFundForUpdate)[]
  >([]);
  const [portfolioFunds, setPortfolioFunds] = useState<
    PortfolioFundForUpdate[]
  >([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isLeavePageModalVisible, setIsLeavePageModalVisible] =
    useState<boolean>(false);
  const [uploadModalInfo, setUploadModalInfo] =
    useState<UploadModalInfoItems>(emptyUploadModalInfo);

  const handleUploadModalOpen = () => {
    setIsUploadModalOpen(prev => !prev);
  };

  const handleLeaveModalOpen = () => {
    setUploadModalInfo(emptyUploadModalInfo);
    setIsLeavePageModalVisible(false);
  };

  const onUploadSuccess = () => {
    setUploadModalInfo({
      failedStatus: FailedStatus.NONE,
      description:
        'You may now add a name and assign a benchmark to your uploaded portfolio.',
      title: 'Upload Complete',
      unavailableISINsList: [],
    });
    setIsFundsImported(true);
  };
  // This hook identifies if we need to set an error message for upload modal or leave it empty after
  // uploading and parsing funds
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    let count = 0;
    const mappedPortfolioFundNotExist = portfolioFunds
      .map(portfolioFund => {
        const isinExist = importedFundsList.some(
          fund => portfolioFund.isin === fund?._id,
        );

        return {
          rowNumber: !isinExist ? (count += 1) : 0,
          isin: !isinExist ? portfolioFund.isin : '',
        };
      })
      .filter(f => f.isin);

    if (portfolioFunds.length > LIMIT_FUNDS_PER_PORTFOLIO) {
      setUploadModalInfo({
        description: (
          <p>
            You are trying to upload too many funds. The maximum is{' '}
            {LIMIT_FUNDS_PER_PORTFOLIO} funds, so please remove some of the
            necessary number and try uploading again. For further assistance,
            you may contact our{' '}
            <a
              onClick={() => {
                openJiraForm();
              }}
              className="text-primary"
            >
              support team
            </a>
          </p>
        ),
        unavailableISINsList: [],
        title: UPLOAD_FAILED,
        failedStatus: FailedStatus.FUND_LIMIT,
      });
      setIsUploadModalOpen(true);
    } else if (mappedPortfolioFundNotExist.length !== 0) {
      const title = `${
        mappedPortfolioFundNotExist && mappedPortfolioFundNotExist.length > 1
          ? `${mappedPortfolioFundNotExist.length} ISINS`
          : `${mappedPortfolioFundNotExist.length} ISIN`
      } not found`;
      const modalInfo = {
        title,
        description: (
          <p>
            These ISINs are not in our database - we suggest you copy the list
            for your records. We have been notified and will endeavour to have
            them added, but for further assistance you can contact our{' '}
            <a
              onClick={() => {
                openJiraForm();
              }}
              className="text-primary"
            >
              support team
            </a>
            .
          </p>
        ),
        unavailableISINsList: mappedPortfolioFundNotExist,
        failedStatus: FailedStatus.ISINS_NOT_FOUND,
      };
      setIsFundsImported(true);

      setUploadModalInfo({
        ...modalInfo,
      });
    } else {
      setUploadModalInfo(emptyUploadModalInfo);
    }
  }, [importedFundsList]);

  // This hook sets either imported isins to refetch funds for another set of isins and in the following hooks sets imported funds
  // or just sets imported funds directly if there are no new isins being caught by **portfolioFunds**
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (portfolioFunds && portfolioFunds.length > 0) {
      const isins = portfolioFunds.map(f => f.isin);
      if (
        isins.some(i => importedIsins.some(ii => ii !== i)) ||
        isins.length !== importedIsins.length
      ) {
        setImportedIsins(isins);
      } else {
        if (multipleFundsByIsinsResponse) {
          setImportedFundsList(
            multipleFundsByIsinsResponse?.map(d => {
              const portfolioFund = portfolioFunds.find(p => p.isin === d._id);

              if (portfolioFund) {
                return { ...d, ...portfolioFund };
              } else {
                return { ...d, isin: '', weightings: [] };
              }
            }),
          );
          onUploadSuccess();
        }
      }
    }
  }, [portfolioFunds]);

  // This hook sets imported funds based on the above hooks outcome,
  // if refetch funds for another set of isins took place = check multipleFundsByIsinsResponse
  // it also sets an uploadModal error if after refetching items we got unvavailable isins or network error
  // But if all isins are found we sets imported funds
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (
      multipleFundsByIsinsResponse &&
      importedIsins.length !== multipleFundsByIsinsResponse.length
    ) {
      const unavailableISINsList = importedIsins.map((isin, i) => ({
        isin,
        rowNumber: i,
      }));
      const title = `${
        unavailableISINsList && unavailableISINsList.length > 1
          ? `${unavailableISINsList.length} ISINS`
          : `${unavailableISINsList.length} ISIN`
      } not found`;
      const description = (
        <p>
          These ISINs are not in our database - we suggest you copy the list for
          your records. We have been notified and will endeavour to have them
          added, but for further assistance you can contact our{' '}
          <a
            onClick={() => {
              openJiraForm();
            }}
            className="text-primary"
          >
            support team
          </a>
          .
        </p>
      );
      setUploadModalInfo({
        ...uploadModalInfo,
        title: title,
        description: description,
        unavailableISINsList: unavailableISINsList,
        failedStatus: FailedStatus.ISINS_NOT_FOUND,
      });

      setImportedFundsList(
        multipleFundsByIsinsResponse.map(d => {
          const portfolioFund = portfolioFunds.find(p => p.isin === d._id);

          if (portfolioFund) {
            return { ...d, ...portfolioFund };
          } else {
            return { ...d, isin: '', weightings: [] };
          }
        }),
      );
    } else if (
      multipleFundsByIsinsResponse &&
      multipleFundsByIsinsResponse?.length > 0
    ) {
      setImportedFundsList(
        multipleFundsByIsinsResponse.map(d => {
          const portfolioFund = portfolioFunds.find(p => p.isin === d._id);

          if (portfolioFund) {
            return { ...d, ...portfolioFund };
          } else {
            return { ...d, isin: '', weightings: [] };
          }
        }),
      );
      onUploadSuccess();
    }

    if (error) {
      setUploadModalInfo({
        title: UPLOAD_FAILED,
        description:
          'An error occurred while uploading this file. Please check your internet connection and try again.',
        unavailableISINsList: [],
        failedStatus: FailedStatus.INTERNET_CONNECTION,
      });
    }
  }, [multipleFundsByIsinsResponse]);

  // This hook sends an email if the previous hook caught some unavailable isins
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (
      uploadModalInfo.failedStatus === FailedStatus.ISINS_NOT_FOUND &&
      uploadModalInfo.unavailableISINsList?.length &&
      isFundsImported
    ) {
      sendUnavailableIsinsEmail({
        isins: uploadModalInfo.unavailableISINsList.map(i => i.isin),
      });
    }
  }, [uploadModalInfo, isFundsImported]);

  const onFundsUploadFailure = (
    uploadModalMessage: UploadModalInfoBase,
    filenames: string[],
  ) => {
    setUploadModalInfo({
      ...uploadModalMessage,
      unavailableISINsList: [],
      failedStatus: FailedStatus.OTHER,
    });
    setFileList(filenames);

    setIsUploadModalOpen(true);
  };

  const onFundsUploadSuccess = (
    portfolioFundsToSet: PortfolioFundForUpdate[],
    filenames: string[],
    dates: string[],
  ) => {
    setFileList(filenames);
    setAllDates(dates);
    setPortfolioFunds(portfolioFundsToSet);
  };

  const backToImportScreen = () => {
    setIsLeavePageModalVisible(true);
  };

  const leavePortfolioDetailsScreen = () => {
    setIsFundsImported(false);
    setUploadModalInfo(emptyUploadModalInfo);
    setIsLeavePageModalVisible(false);
    setFileList([]);
    setAllDates([]);
    setPortfolioFunds([]);
    setImportedFundsList([]);
    setImportedIsins([]);
  };

  const handleAssignFundModalClose = () => {
    setIsLeavePageModalVisible(true);
  };

  return (
    <BasePageWithMetadata title={'Import Funds'}>
      <SectionBackButton
        previousLabel={'Back'}
        onClick={isFundsImported ? backToImportScreen : undefined}
      />
      <section className="flex w-full p-4 flex-col gap-y-4 bg-white max-lg:min-h-screen lg:pb-10">
        <h1 className="text-xl font-bold text-darkest">Add Portfolio</h1>
        <div className="flex flex-1 flex-col">
          {isFundsImported && !isMobile ? (
            <AssignFundsToPortfolioComponent
              allDates={allDates}
              onFundsUploadFailure={onFundsUploadFailure}
              onFundsUploadSuccess={onFundsUploadSuccess}
              importedFundsList={importedFundsList || []}
              areISINsUnavailable={
                uploadModalInfo.unavailableISINsList &&
                uploadModalInfo.unavailableISINsList.length !== 0
              }
              onModalOpen={(isOpen: boolean) => {
                if (isOpen) {
                  setIsUploadModalOpen(isOpen);
                }
              }}
            />
          ) : (
            <UploadFunds
              onFundsUploadFailure={onFundsUploadFailure}
              onFundsUploadSuccess={onFundsUploadSuccess}
            />
          )}
          {isMobile && (
            <Modal
              title="Add Portfolio"
              open={isFundsImported}
              className="full-page-modal information-modal [&_.ant-modal-footer]:z-10"
              onCancel={handleAssignFundModalClose}
              onClose={handleAssignFundModalClose}
              destroyOnClose={true}
              footer={[
                <Button
                  type="link"
                  onClick={handleAssignFundModalClose}
                  key="cancel-button"
                >
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  onClick={() => {
                    if (assignFundFormRef.current) {
                      assignFundFormRef.current.submitForm?.();
                    }
                  }}
                  disabled={isAssignModalSaveButtonDisabled}
                  key="add-button"
                >
                  Add
                </Button>,
              ]}
            >
              <AssignFundsToPortfolioComponent
                allDates={allDates}
                ref={assignFundFormRef}
                onFundsUploadFailure={onFundsUploadFailure}
                onFundsUploadSuccess={onFundsUploadSuccess}
                importedFundsList={importedFundsList || []}
                areISINsUnavailable={
                  uploadModalInfo.unavailableISINsList &&
                  uploadModalInfo.unavailableISINsList.length !== 0
                }
                onModalOpen={(isOpen: boolean) => {
                  if (isOpen) {
                    setIsUploadModalOpen(isOpen);
                  }
                }}
                onButtonDisabled={isDisabled => {
                  setIsAssignModalSaveButtonDisabled(!!isDisabled);
                }}
              />
            </Modal>
          )}
        </div>
        <UploadModal
          isSuccess={
            isFundsImported &&
            uploadModalInfo.failedStatus === FailedStatus.NONE
          }
          fileNames={fileList}
          isVisible={isUploadModalOpen}
          toggleModal={handleUploadModalOpen}
          failedStatusModalInfo={uploadModalInfo}
        />
        <ConfirmationModalDanger
          isVisible={isLeavePageModalVisible}
          toggleModal={handleLeaveModalOpen}
          onConfirm={leavePortfolioDetailsScreen}
          destroyOnClose={true}
          modalInfo={{
            primaryActionLabel: 'Leave this page',
            secondaryActionLabel: 'Continue editing',
            title: 'You have unsaved changes',
            description:
              'Changes you made will be discarded if you leave this page.',
          }}
        />
      </section>
    </BasePageWithMetadata>
  );
};

export default ImportPortfolio;
