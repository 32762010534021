import { RatingBody, RatingRecord, RatingsApi } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { RATINGS } from './query-keys';

const ratingsApi = new RatingsApi(openApiConfig());

const invalidateRatings = () => {
  queryClient.invalidateQueries({ queryKey: [RATINGS] });
};

export const useGetRatings = (fundId: string) => {
  return useQuery<RatingRecord[] | null, unknown>(
    [RATINGS, fundId],
    async () => {
      const result = await ratingsApi.getRatings(fundId);
      return result.data;
    },
    { enabled: Boolean(fundId) },
  );
};

export const useCreateRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; body: RatingBody }) =>
      ratingsApi.createRatings(input.fundId, input.body),
    {
      onSettled: () => invalidateRatings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Rating has been added' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding rating',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; ratingId: string; body: RatingBody }) =>
      ratingsApi.updateRatings(input.fundId, input.ratingId, input.body),
    {
      onSettled: () => invalidateRatings(),
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error updating rating',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteRatings = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; ratingId: string }) =>
      ratingsApi.deleteRatings(input.fundId, input.ratingId),
    {
      onSettled: () => invalidateRatings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Rating has been deleted' },
        });
      },
    },
  );
  return mutation;
};
