import {
  PortfolioFundForUpdate,
  PortfolioWeighting,
} from '@aminsights/contract';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { DATE_FORMAT_DASHED, DATE_FORMAT_SLASHED } from '../constants/misc';
import { calculatePortfolioTotalForDate } from './calculator';

dayjs.extend(customParseFormat); // Needed to use DATE_FORMAT_DASHED during reverse formatting
dayjs.extend(utc); // Needed to ignore timezones during reverse formatting

/**
 * Set isValid for portfolio dates after calculating the total for the date
 * @param {Set<string>} allDates unique dates
 * @param {PortfolioWeighting[]} allWeightings weightings of a single portfolio.
 * @returns {Map<string, boolean>} datesToValidate - a map of dates with their isValid set to boolean
 */
export const validatePortfolioDates = (
  allDates: Set<string>,
  allWeightings: PortfolioWeighting[],
) => {
  const validatedDates: Map<string, boolean> = new Map<string, boolean>();
  allDates.forEach(d => {
    const sumForTheDate = calculatePortfolioTotalForDate(d, allWeightings);
    if (!!sumForTheDate && sumForTheDate < 0) {
      throw new Error('Weightings contain incorrect values');
    }

    const weightingsForDate = allWeightings.filter(w => w.date === d);
    const wereAllWeightingsValidForDate = weightingsForDate.every(
      w => w.isValid,
    );
    validatedDates.set(
      d,
      sumForTheDate === 100 ||
        (wereAllWeightingsValidForDate && sumForTheDate === 0),
    );
  });

  return validatedDates;
};

/**
 * Evaluate the is valid for each weighting in each fund of the portfolio
 * @param {Set<string>} allDates unique dates
 * @param {PortfolioWeighting[]} allWeightings weightings of a single portfolio.
 * @param {PortfolioFund[]} portfolioFunds all funds of the portfolio
 * @returns {Map<string, boolean>} datesToValidate - a map of dates with their isValid set to boolean
 */
export const evaluatePortfolioFundsValidity = (
  allDates: Set<string>,
  allWeightings: PortfolioWeighting[],
  portfolioFunds: PortfolioFundForUpdate[],
) => {
  const validatedDates = validatePortfolioDates(allDates, allWeightings);

  const result = [...portfolioFunds];
  validatedDates.forEach((isValid, date) => {
    result.forEach(f => {
      f.weightings.forEach((w: PortfolioWeighting) => {
        if (w.date === date) {
          w.isValid = isValid;
        }
        if (!w.value) {
          w.value = 0;
        }
      });
    });
  });
  return result;
};

/**
 * Sorts fund desc by value of the last date for portfolio holdings page
 * @param {PortfolioFund[]} fundsInPortfolio funds of the portfolio
 * @returns {Map<string, boolean>} datesToValidate - a map of dates with their isValid set to boolean
 */
export const sortFundsByValueForLatestDate = (
  fundsInPortfolio: PortfolioFundForUpdate[],
) => {
  let result = fundsInPortfolio.map(f => ({
    ...f,
    weightings: f.weightings,
  }));
  const allWeightings = result.flatMap(f => f.weightings);
  const uniqueDates = new Set(
    allWeightings
      .map(aW => aW.date)
      .sort(
        (a, b) =>
          dayjs(b, DATE_FORMAT_DASHED).valueOf() -
          dayjs(a, DATE_FORMAT_DASHED).valueOf(),
      ),
  );
  let latestDateForPortfolio: string | undefined;
  for (const date of uniqueDates) {
    const totalForDate = calculatePortfolioTotalForDate(date, allWeightings);
    if (totalForDate !== 0) {
      latestDateForPortfolio = date;
      break;
    }
  }

  if (latestDateForPortfolio) {
    const sortedFunds = result.sort(
      (a, b) =>
        (b.weightings.find(w => w.date === latestDateForPortfolio)?.value ??
          0) -
        (a.weightings.find(w => w.date === latestDateForPortfolio)?.value ?? 0),
    );
    result = sortedFunds;
  }
  return result;
};

export const formatPortfolioWeightingsDate = (value: string): string => {
  const date = dayjs(value, [DATE_FORMAT_DASHED, DATE_FORMAT_SLASHED], true);
  if (!date.isValid()) throw new Error(`Invalid date format: ${value}`);

  return date.format(DATE_FORMAT_DASHED);
};
