import { Portfolio, PortfolioWeighting } from '@aminsights/contract';
import React from 'react';

export enum PORTFOLIO_ACTIONS {
  ADD_FUND_TO_CURRENT_PORTFOLIO = 'portfolio:add-fund',
  REMOVE_FUND_FROM_CURRENT_PORTFOLIO = 'portfolio:remove-fund-from-current-portfolio',
  ADD_FUND_SEARCH_BOX_TO_CURRENT_PORTFOLIO = 'portfolio:add-fund-searchbox-to-current-portfolio',
  REMOVE_FUND_SEARCH_BOX_FROM_CURRENT_PORTFOLIO = 'portfolio:remove-fund-searchbox-from-current-portfolio',
  UPDATE_WEIGHTING = 'portfolio:update-weighting',
  SET_PORTFOLIOS = 'portfolio:set-portfolios',
  SET_IS_LOADING = 'portfolio:set-is-loading',
  SET_ISNOT_LOADING = 'portfolio:set-isnot-loading',
  SET_CURRENT_PORTFOLIO = 'portfolio:set-current-portfolio',
  ADD_DATE = 'portfolio:add-date',
  UPDATE_DATE = 'portfolio:update-date',
  DELETE_DATE = 'portfolio:delete-date',
  IDENTIFY_FUND_FOR_DELETE = 'portfolio:identify-funds-for-delete',
  UNMARK_ALL_FUNDS_FOR_DELETE = 'portfolio:unmark-all-funds-for-delete',
  MARK_ALL_FUNDS_FOR_DELETE = 'portfolio:mark-all-funds-for-delete',
  SET_IS_PERFORMANCE_TAB_LOADING = 'portfolio:set-is-performance-tab-loading',
  DELETE_PORTFOLIO = 'portfolio:delete-portfolio',
  CLEAN_EMPTY_DATES = 'portfolio:clean-empty-dates',
}

export type PortfolioActionTypes =
  | {
      type: PORTFOLIO_ACTIONS.ADD_FUND_TO_CURRENT_PORTFOLIO;
      payload: {
        isin: string;
        broadAssetClass: string;
        fundName: string;
        code: string;
      };
    }
  | { type: PORTFOLIO_ACTIONS.REMOVE_FUND_FROM_CURRENT_PORTFOLIO }
  | { type: PORTFOLIO_ACTIONS.ADD_FUND_SEARCH_BOX_TO_CURRENT_PORTFOLIO }
  | { type: PORTFOLIO_ACTIONS.REMOVE_FUND_SEARCH_BOX_FROM_CURRENT_PORTFOLIO }
  | {
      type: PORTFOLIO_ACTIONS.UPDATE_WEIGHTING;
      payload: {
        isin: string;
        portfolioWeighting: PortfolioWeighting;
      };
    }
  | { type: PORTFOLIO_ACTIONS.SET_CURRENT_PORTFOLIO; payload: string }
  | { type: PORTFOLIO_ACTIONS.SET_IS_LOADING }
  | { type: PORTFOLIO_ACTIONS.SET_ISNOT_LOADING }
  | { type: PORTFOLIO_ACTIONS.SET_PORTFOLIOS; payload: Array<Portfolio> }
  | { type: PORTFOLIO_ACTIONS.ADD_DATE }
  | {
      type: PORTFOLIO_ACTIONS.UPDATE_DATE;
      payload: { oldDate: string; newDate: string };
    }
  | { type: PORTFOLIO_ACTIONS.DELETE_DATE; payload: { date: string } }
  | { type: PORTFOLIO_ACTIONS.CLEAN_EMPTY_DATES }
  | { type: PORTFOLIO_ACTIONS.IDENTIFY_FUND_FOR_DELETE; payload: string }
  | { type: PORTFOLIO_ACTIONS.SET_IS_PERFORMANCE_TAB_LOADING; payload: boolean }
  | { type: PORTFOLIO_ACTIONS.UNMARK_ALL_FUNDS_FOR_DELETE }
  | { type: PORTFOLIO_ACTIONS.MARK_ALL_FUNDS_FOR_DELETE }
  | {
      type: PORTFOLIO_ACTIONS.DELETE_PORTFOLIO;
      payload: {
        portfolioIdToRemoved: string;
      };
    };

export type PortfolioState = {
  portfolios: Array<Portfolio>;
  arePortfoliosLoading: boolean;
  isPerformanceTabLoading: boolean;
  markedFundsForDelete: string[];
  currentPortfolioId?: string;
};

export type PortfolioContextProps = {
  state: PortfolioState;
  dispatch: React.Dispatch<PortfolioActionTypes>;
};

export enum PORTFOLIO_TAB {
  CHARTS = 'charts',
  PERFORMANCE = 'performance',
  HOLDINGS = 'holdings',
  SETTINGS = 'settings',
  PLATFORMS = 'platforms',
}

export const EMPTY_PAGE_SLUG = 'empty';
