/**
 * README
 * Shared functions that are bite-size. Please don't dump any business logic here.
 * It needs to ba as simple as possible and actually reused beyoned 3 times.
 */

import { IDataTableColumns } from '@/components/Table/DataTable';
import { notFalsy } from '@/utils/notEmpty';

type PaginatedData<T> = {
  pages: Array<{
    data: T;
    total?: number;
  }>;
};

export const flattenInfiniteQueryData = <T>(
  data?: PaginatedData<T>,
): (T extends readonly (infer InnerArr)[] ? InnerArr : T)[] => {
  if (!data) return [];

  // biome-ignore lint/complexity/useFlatMap: can't figure out why ts complains when I switch to flatMap
  const flattenedItems = data.pages?.flatMap(page => page.data).flat();
  return flattenedItems;
};

export const getTotalFromPaginatedData = <T>(data?: PaginatedData<T>) => {
  return data?.pages?.[0]?.total ?? 0;
};

/**
 * Inspired by clsx - https://www.npmjs.com/package/clsx
 * clsx('foo', true && 'bar', false && 'baz'); -> 'foo bar'
 */
type Columnizer = (
  ...args: Array<IDataTableColumns | undefined | false | null>
) => IDataTableColumns[];
export const columnizer: Columnizer = (...args) => {
  const filterdColumns = args.filter(notFalsy);
  return filterdColumns;
};
