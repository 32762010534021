import { SignificantChangesItem } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  getFundManagerDescription,
  getFundOrInvestmentTrustTitle,
  getShortHumanReadableStyleboxName,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';

export interface PerSectionModalItems {
  fundDetailsSection: ISignificantChanges[];
  changeTypeManagerChangeSection: ISignificantChanges[];
  changeTypeStyleDriftSection: ISignificantChanges[];
  performanceSection: ISignificantChanges[];
  historyManagerChangeSection: ISignificantChanges[];
}
export interface ISignificantChanges {
  label: string;
  value: string | number | string[];
  date?: string;
  key: string;
}

const formatNumericValue = (value: number) => {
  if (!value) {
    return EMPTY_DATA_POINT;
  }
  return Number.isInteger(value) ? `${value}` : `${value.toFixed(1)}`;
};

export const PerSectionModalData = (
  significantChangesData: SignificantChangesItem,
  fund: {
    OneMonthPct: number;
    YtdPct: number;
  },
  isInvestmentTrust: boolean,
): PerSectionModalItems => {
  const managerChangeObject = getFundManagerDescription(significantChangesData);
  const boxLabels = isFixedIncomeBroadAssetClass(
    significantChangesData.broadAssetClass || '',
  )
    ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
    : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ;
  return {
    fundDetailsSection: [
      {
        label: getFundOrInvestmentTrustTitle(isInvestmentTrust),
        value: significantChangesData.fundName || EMPTY_DATA_POINT,
        key: 'FundOrTrustColumnId',
      },
      {
        label: 'Sector',
        value: significantChangesData.msCategoryDefinition || EMPTY_DATA_POINT,
        key: 'SectorColumnId',
      },
      {
        label: 'Date',
        value: significantChangesData.updatedDate || '',
        key: 'DateColumnId',
      },
    ],
    changeTypeManagerChangeSection: [
      {
        label: 'Incoming Manager(s)',
        value: managerChangeObject
          ? managerChangeObject.incomingManagerDescription.replace('+ ', '')
          : EMPTY_DATA_POINT,
        key: 'IncomingManagerColumnId',
      },
      {
        label: 'Outgoing Manager(s)',
        value: managerChangeObject
          ? managerChangeObject.removedManagerDescription.replace('- ', '')
          : EMPTY_DATA_POINT,
        key: 'OutgoingManagerColumnId',
      },
    ],
    changeTypeStyleDriftSection: [
      {
        label: 'Latest',
        value: significantChangesData?.newValue
          ? getShortHumanReadableStyleboxName(
              significantChangesData.newValue,
              boxLabels,
            )
          : EMPTY_DATA_POINT,
        key: 'latest',
      },
      {
        label: 'Previous',
        value: significantChangesData?.oldValue
          ? getShortHumanReadableStyleboxName(
              significantChangesData.oldValue,
              boxLabels,
            )
          : EMPTY_DATA_POINT,
        key: 'previous',
      },
    ],
    performanceSection: [
      {
        label: '1 Month',
        value: formatNumericValue(fund.OneMonthPct),
        date: significantChangesData.updatedDate,
        key: '3MonthPerformanceColumnId',
      },
      {
        label: 'Year to Date (YTD)',
        value: formatNumericValue(fund.YtdPct),
        date: significantChangesData.updatedDate,
        key: 'YtdPerformanceColumnId',
      },
    ],
    historyManagerChangeSection: [
      {
        label: 'Lead Manager',
        value:
          significantChangesData.newManagerList &&
          significantChangesData.newManagerList?.length >= 1
            ? `${significantChangesData.newManagerList[0].givenName} ${significantChangesData.newManagerList[0].familyName}`
            : EMPTY_DATA_POINT,
        date: significantChangesData.updatedDate,
        key: 'LeadManagerColumnId',
      },
      {
        label: 'Fund Manager',
        value:
          significantChangesData.newManagerList &&
          significantChangesData.newManagerList?.length >= 2
            ? `${significantChangesData.newManagerList[1].givenName} ${significantChangesData.newManagerList[1].familyName}`
            : EMPTY_DATA_POINT,
        date: significantChangesData.updatedDate,
        key: 'FundManagerColumnId',
      },
      {
        label: 'Deputy Fund Manager',
        value:
          significantChangesData.newManagerList &&
          significantChangesData.newManagerList?.length >= 3
            ? `${significantChangesData.newManagerList[2].givenName} ${significantChangesData.newManagerList[2].familyName}`
            : EMPTY_DATA_POINT,
        date: significantChangesData.updatedDate,
        key: 'DeputyFundManagerColumnId',
      },
    ],
  };
};
