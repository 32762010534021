import { BondStatisticsTypeCodes } from '@aminsights/shared';
import type { SetRequired } from 'type-fest';

import { SLIDER_MAX, SLIDER_MIN } from '@/constants';

import { countSelectedFilter } from '../utils/countSelectedFilter';
import { getAvgQualityTypeCode } from '../utils/getAvgQualityTypeCode';

export interface BondsFilterState {
  bondsStyle?: number[];
  modifiedDurationRange?: number[];
  yieldMaturityRange?: number[];
  avgCreditQualityRange?: number[];
}

export const YIELD_MATURITY_SLIDER_MAX = 99;
export const AVG_CREDIT_SLIDER_MAX = 90;

export const bondsInitialState: SetRequired<
  BondsFilterState,
  keyof BondsFilterState
> = {
  bondsStyle: [],
  modifiedDurationRange: [SLIDER_MIN, SLIDER_MAX],
  yieldMaturityRange: [SLIDER_MIN, YIELD_MATURITY_SLIDER_MAX],
  avgCreditQualityRange: [SLIDER_MIN, AVG_CREDIT_SLIDER_MAX],
};

export const modifiedDurationMarks: Record<number, string> = {
  0: '0',
  20: '2',
  40: '4',
  60: '6',
  80: '8',
  100: '10>',
};

export const yieldMaturityMarks: Record<number, string> = {
  0: '0',
  33: '2%',
  66: '4%',
  99: '6%>',
};

export const avgCreditMarks: Record<number, string> = {
  // 0: 'Not Rated', // Uncomment when M* starts to return value for NR
  0: 'Below B',
  15: 'B',
  30: 'BB',
  45: 'BBB',
  60: 'A',
  75: 'AA',
  90: 'AAA',
};

/**
 * Said range is the value of the above avgCreditMarks KEYs.
 * 0 - 91
 */
export const getExploreValueRangeFromAvgQuality = (
  range?: [number | undefined, number | undefined],
) => {
  if (!range) return undefined;
  const [avgCreditQualityRangeFirst, avgCreditQualityRangeSecond] = range;

  if (
    avgCreditQualityRangeFirst === undefined ||
    avgCreditQualityRangeSecond === undefined
  )
    return undefined;

  const avgCreditQualityRangeMin = Math.min(
    avgCreditQualityRangeFirst,
    avgCreditQualityRangeSecond,
  );
  const avgCreditQualityRangeMax = Math.max(
    avgCreditQualityRangeFirst,
    avgCreditQualityRangeSecond,
  );

  const creditMarkMin = avgCreditMarks[avgCreditQualityRangeMin];
  const creditMarkMax = avgCreditMarks[avgCreditQualityRangeMax];

  const avgCreditQualityMin =
    getAvgQualityTypeCode(creditMarkMin)?.split('-') || [];
  const avgCreditQualityMax =
    getAvgQualityTypeCode(creditMarkMax)?.split('-') || [];

  return [
    Number(avgCreditQualityMax[0]),
    Number(avgCreditQualityMin[0]) === 18
      ? Number.MAX_SAFE_INTEGER
      : Number(avgCreditQualityMin[1]),
  ];
};

/**
 * We need to rethink Average Credit Quality slider
 */
export const getAvgCreditQualitySliderValueFromActualValue = (
  num: number,
): number => {
  // Find the corresponding bond statistic based on the number in typeCode
  let bondStatisticDefinition: string | undefined;
  if (num > SLIDER_MAX) {
    bondStatisticDefinition = BondStatisticsTypeCodes.find(
      b => b.definition === 'Below B',
    )?.definition;
  } else {
    bondStatisticDefinition = BondStatisticsTypeCodes.find(({ typeCode }) => {
      const ranges = typeCode
        .split('-')
        .map(str =>
          str.includes('+')
            ? Number.POSITIVE_INFINITY
            : Number.parseInt(str, 10),
        );
      return num >= ranges[0] && num <= ranges[1];
    })?.definition;
  }

  if (!bondStatisticDefinition) {
    console.error('Failed to parse avg credit quality');
    return 1; // Guessing
  }

  // Find the key in avgCreditMarks that matches the definition from BondStatisticsTypeCodes
  const matchingKey = Object.keys(avgCreditMarks).find(
    key => avgCreditMarks[Number.parseInt(key, 10)] === bondStatisticDefinition,
  );

  return Number.parseInt(matchingKey ?? '0');
};

// Average credit quality goes from 1 to 17
export const avgCreditMarksValues = {
  'Not Rated': 20,
  AAA: { lowerLimit: 1, upperLimit: 2 },
};

export const countBondsFilters = (filterState?: BondsFilterState) => {
  if (!filterState) return 0;

  const a = [
    !!filterState.bondsStyle,
    !!filterState.modifiedDurationRange,
    !!filterState.yieldMaturityRange,
    !!filterState.avgCreditQualityRange,
  ];

  return countSelectedFilter(a);
};
