import {
  DataSourceType,
  GeographyItem,
  HoldingDetail,
  ItemsSectorDetail,
} from '@aminsights/contract';
import { Fund } from '@aminsights/contract';

import {
  MAXIMUM_HOLDINGS_ROWS_PER_FUND,
  MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS,
  TOTAL_HOLDINGS_ROWS,
} from '@/constants';
import { getItemsToFillTheRestOfArray } from '@/utils/array';
import { sectorLegendList } from '@/utils/sectorLegendList';

type GeographyCompare = GeographyItem & {
  id: string;
};

function evaluateGeographyForFund(
  fundResponse: Fund,
  shouldTakeEquityField: boolean,
  isPadiFundNeeded = false,
) {
  const fund = fundResponse;
  if (isPadiFundNeeded) {
    return fund.portfolioDataSource === DataSourceType.Padi
      ? fund.geographyPortfolio
      : [];
  }
  const geography = shouldTakeEquityField
    ? fund.countriesEquity
    : fund.countriesFixedIncome;

  return geography ?? [];
}

export type GeographyCompareData = {
  data: { id?: string; name: string }[];
  geographies: GeographyCompare[][];
  flatGeographies: GeographyCompare[];
};

export function calculateDataForGeographyCompareTable(
  shouldTakeEquityField: boolean,
  funds: Fund[],
  //We have PADI, EQ, FI for Sectors, geography. Perhaps we should introduce some enum with these 3  values
  isPadiFundNeeded = false,
): GeographyCompareData {
  const fundGeographies = funds.map(
    f =>
      evaluateGeographyForFund(f, shouldTakeEquityField, isPadiFundNeeded) ||
      [],
  );
  const geographies = fundGeographies.map(geography =>
    geography
      .sort((a, b) => ((a.value ?? 0) > (b.value ?? 0) ? -1 : 1))
      .slice(0, MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS),
  );

  let flatGeographies = geographies.flat();
  const geographiesLength = geographies.length;

  const geographiesMap = new Map(flatGeographies.map(c => [c.id, c]));

  for (let i = 0; i < geographiesLength; i++) {
    const geographiesNoValues = geographies[i].filter(
      g => !g.value || g.value === 0,
    );
    const emptyValuesToAdd = getItemsToFillTheRestOfArray(
      geographiesMap.size,
      MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS * 2,
      geographiesNoValues,
    );
    emptyValuesToAdd.forEach(g => {
      geographiesMap.set(g.id, g);
    });
    flatGeographies = [...flatGeographies, ...geographiesNoValues];
  }

  const result = Array.from(geographiesMap.values()).slice(
    0,
    MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS,
  );

  const data = result.every(r => r.value === 0) ? [] : result;

  return { data, geographies, flatGeographies };
}

export type HoldingsCompareData = {
  data: { id: string; isin: string }[];
  fundHoldings: HoldingDetail[][];
  holdings: HoldingDetail[];
};

export function calculateDataForHoldingsCompareTable(
  funds: Fund[],
): HoldingsCompareData {
  const fundHoldingDetails = funds.map(f => f.holdingDetail || []);

  const fundHoldings = fundHoldingDetails.map(holding =>
    holding
      .filter(h => (h.weighting ?? 0) > 0)
      .sort((a, b) => ((a.weighting ?? 0) > (b.weighting ?? 0) ? -1 : 1))
      .slice(0, MAXIMUM_HOLDINGS_ROWS_PER_FUND),
  );
  let flatHoldings = fundHoldings.flat();
  const holdingsLength = fundHoldings.length;

  const holdingsMap = new Map(
    flatHoldings.filter(h => !!h.id).map(h => [h.id, h]),
  );

  for (let i = 0; i < holdingsLength; i++) {
    const holdingsNoValues = fundHoldings[i].filter(
      h => !!h.id && (h.weighting ?? 0) === 0,
    );
    const emptyValuesToAdd = getItemsToFillTheRestOfArray(
      holdingsMap.size,
      MAXIMUM_HOLDINGS_ROWS_PER_FUND * 2,
      holdingsNoValues,
    );
    emptyValuesToAdd.forEach(h => {
      holdingsMap.set(h.id, h);
    });
    flatHoldings = [...flatHoldings, ...holdingsNoValues];
  }

  const result = Array.from(holdingsMap.values())
    .slice(0, TOTAL_HOLDINGS_ROWS)
    .filter(d => !!d.id);

  const data = result.every(r => (r.weighting ?? 0) === 0)
    ? []
    : result.map(s =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ({ id: s.id!, isin: s.isin! }),
      );
  return { data, holdings: flatHoldings, fundHoldings };
}

export type SectorCompareData = {
  data: { type: number; name: string }[];
  sectors: ItemsSectorDetail[][];
  flatSectors: ItemsSectorDetail[];
};

export function calculateDataForSectorsCompareTable(
  shouldTakeEquityField: boolean,
  funds: Fund[],
  shouldTakePadi = false,
): SectorCompareData {
  const fundSectors = funds.map(
    f =>
      (shouldTakeEquityField
        ? (f.sectorsEquity?.filter(d => {
            const hasEqSectorsFromLegend = sectorLegendList.some(i =>
              i.type.includes(d.type),
            );
            return shouldTakePadi
              ? !hasEqSectorsFromLegend
              : hasEqSectorsFromLegend;
          }) ?? [])
        : f.sectorsFixedIncome) || [],
  );

  const sectors = fundSectors.map(sectors =>
    sectors
      .sort((a, b) => ((a.value ?? 0) > (b.value ?? 0) ? -1 : 1))
      .slice(0, MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS),
  );
  let flatSectors = sectors.flat();
  const sectorsLength = sectors.length;

  const sectorsMap = new Map(flatSectors.map(s => [s.name, s]));

  for (let i = 0; i < sectorsLength; i++) {
    const sectorsNoValues = sectors[i].filter(s => !s.value || s.value === 0);
    const emptyValuesToAdd = getItemsToFillTheRestOfArray(
      sectorsMap.size,
      MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS * 2,
      sectorsNoValues,
    );
    emptyValuesToAdd.forEach(s => {
      sectorsMap.set(s.name, s);
    });
    flatSectors = [...flatSectors, ...sectorsNoValues];
  }

  const result = Array.from(sectorsMap.values()).slice(
    0,
    MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS,
  );

  const data = result.every(r => r.value === 0)
    ? []
    : result.map(s => ({ type: s.type, name: s.name }));

  return { data, sectors, flatSectors };
}
