import { AppApi } from '@aminsights/contract';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useEffect } from 'react';

import { openApiConfig } from '@/utils';

const AnalyticsContext = createContext<unknown>({
  state: {},
  dispatch: () => ({}),
});

const AnalyticsProvider: React.FCWithChild = ({ children }) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const { pendo } = window as any;
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isAuthenticated) {
      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      getIdTokenClaims().then((value?: IdToken) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (value?.sub) {
          const appApi = new AppApi(openApiConfig());
          if (!value?.email) {
            appApi.postLog({
              message: 'User has no email',
              ...value,
            });
          }
          pendo.initialize({
            visitor: {
              id: value?.sub, // Required if user is logged in
              email: value?.email, // Recommended if using Pendo Feedback, or NPS Email
            },
            account: {
              id: value?.sub, // Required if user is logged in
            },
          });
        }
      });
    }
  }, [isAuthenticated, pendo]);

  return (
    <AnalyticsContext.Provider value={{ state: {} }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider };
