import { ChangeTypeLabels } from '@aminsights/shared';
import { Button, Checkbox, Divider, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import { ReactComponent as ClearFilterIcon } from '@/assets/svg/icons/icon-clear-red.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import {
  SignificantChangesParameters,
  SignificantChangesState,
} from '@/pages/app/SignificantChanges/types';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { toCamelCase } from '@/utils/toCamelCase';

interface ISignificantChangesFilter {
  state: SignificantChangesState;
  onParamsChange: (params: Partial<SignificantChangesParameters>) => void;
  onClear: () => void;
  anyFiltersSelected?: boolean;
}

const SignificantChangesFilter: React.FCWithChild<
  ISignificantChangesFilter
> = ({ state, onParamsChange, onClear, anyFiltersSelected }) => {
  const [isDateNestedDrawerVisible, setIsDateNestedDrawerVisible] =
    useState(false);
  const [isTypeNestedDrawerVisible, setIsTypeNestedDrawerVisible] =
    useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const screenWidthMode = getScreenWidthMode();
  const isDesktopAndSmaller = screenWidthMode[ScreenWidthEnum.MaxLg];
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const changeTypes = useMemo(() => {
    const result = Object.entries(ChangeTypeLabels).map(([key, value]) => {
      return {
        label: (
          <Checkbox
            data-test-id={toCamelCase(value)}
            key={key}
            value={value}
            className="flex [&>.ant-checkbox-wrapper]:w-4 [&>.ant-checkbox-wrapper]:h-4"
            checked={checkedItems.includes(value)}
            onChange={() => {
              setCheckedItems(
                checkedItems.includes(value)
                  ? checkedItems.filter(item => item !== value)
                  : [...checkedItems, value],
              );
            }}
          >
            {value}
          </Checkbox>
        ),
        checked: checkedItems.includes(value),
        key: value,
      };
    });

    return result;
  }, [state.significantChangesParameters.changeTypes, checkedItems]);

  const isEveryItemInViewChecked = useMemo(
    () => changeTypes.every(item => item.checked),
    [changeTypes],
  );

  const onChange = (selectedValues: string[]) => {
    const typeMap = selectedValues.map(selectedValue => {
      return (
        Object.entries(ChangeTypeLabels).find(
          ([_key, value]) => value === selectedValue,
        )?.[0] || ''
      );
    });
    onParamsChange({ changeTypes: typeMap });
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const onSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      // pass the checkedItems to the parent component if onChange is provided
      onChange?.(checkedItems);
      setIsTypeNestedDrawerVisible(false);
    },
    [checkedItems],
  );

  const footerButtons = (
    <div className="flex justify-between">
      <Button
        data-test-id="clearType"
        size="large"
        type="link"
        htmlType="button"
        className={cx(
          'text-sm rounded flex items-center justify-center px-4 py-2 hover:opacity-90 hover:text-primary text-neutral-450',
          isMobile && 'w-full',
        )}
        onClick={() => setCheckedItems([])}
        disabled={checkedItems.length === 0}
      >
        Clear
      </Button>
      <Button
        data-test-id="applyType"
        size="large"
        type="primary"
        htmlType="submit"
        className={cx(
          'text-sm rounded flex items-center justify-center px-4 py-2 hover:opacity-90 text-white border-[#0072e6] bg-primary font-medium',
          isMobile && 'w-full',
        )}
        onClick={onSubmit}
        disabled={
          !state.significantChangesParameters.changeTypes?.length &&
          !checkedItems.length
        }
      >
        Apply
      </Button>
    </div>
  );

  const selectAll = (
    <div>
      <div className="flex justify-between items-center md:px-2">
        <Checkbox
          checked={isEveryItemInViewChecked}
          className="px-2"
          onChange={() => {
            const clonedCheckedItems = [...checkedItems];
            clonedCheckedItems.length === changeTypes.length
              ? setCheckedItems([])
              : setCheckedItems(
                  changeTypes.map(item => item.label.props.value),
                );
          }}
        >
          Select all
        </Checkbox>
        <span className="font-bold text-sm text-neutral">
          {checkedItems?.length ? `(${checkedItems.length})` : ''}
        </span>
      </div>
      <Divider className="m-0" />
    </div>
  );

  const dateDropDownData = [
    {
      key: '7D',
      label: <>Last 7 days</>,
      value: '7D',
    },
    {
      key: '30D',
      label: <>Last 30 days</>,
      value: '30D',
    },
    {
      key: '1YR',
      label: <>Last year</>,
      value: '1YR',
    },
  ];

  const dateMenuItems = dateDropDownData.map(date => ({
    ...date,
    key: date.value,
    onClick: () => {
      onParamsChange({ period: date.value });
    },
    selected: date.value === state.significantChangesParameters.period,
  }));

  return (
    <div className="flex gap-2 items-center">
      <div
        className="w-full"
        data-test-id="fundDetailsSignificantChangesTabTypeDropdown"
      >
        <Dropdown
          dropdownRender={() => {
            return !isMobile ? (
              <Menu
                className={cx(
                  'max-sm:!w-full overflow-hidden mt-2 pt-2 px-0 pb-0 !rounded-lg w-[350px]',
                )}
                style={{
                  boxShadow: '0px 3px 6px rgba(185, 191, 207, 0.749)',
                  display: isMobile ? 'none' : 'block',
                }}
                data-test-id="searchDropdownMenuType"
              >
                {selectAll}
                <div className="max-h-[209px] pb-2 overflow-auto">
                  <div className="px-4 max-h-[150px] overflow-y-auto">
                    {changeTypes.map(item => item.label)}
                  </div>
                </div>
                <div className="border border-solid border-grey-light-50 p-[15px_9px_8px]">
                  {footerButtons}
                </div>
              </Menu>
            ) : (
              <></>
            );
          }}
        >
          <Button
            size="large"
            className={cx(
              'group rounded-[4px] border-light border text-sm',
              checkedItems.length > 0
                ? 'font-bold text-neutral-700'
                : 'text-neutral-100',
              'hover:border-primary hover:text-primary',
            )}
            onClick={() => isMobile && setIsTypeNestedDrawerVisible(true)}
            data-test-id="type"
          >
            <div className={cx('flex items-center')}>
              {'Type'}
              {checkedItems.length > 0 && (
                <div className="flex items-center">
                  <div className="mx-2">&bull;</div>
                  {checkedItems.length}
                </div>
              )}
            </div>
            <NextArrow className="fill-current h-3 w-3 rotate-90" />
          </Button>
        </Dropdown>
        {isMobile && (
          <NestedDrawer
            menuItems={changeTypes}
            visible={isTypeNestedDrawerVisible}
            onClose={() => setIsTypeNestedDrawerVisible(false)}
            title="Select Type"
            subComponent={<div className="w-full px-4">{selectAll}</div>}
            footer={footerButtons}
          />
        )}
      </div>
      <div
        className="w-full"
        data-test-id="fundDetailsSignificantChangesTabDateDropdown"
      >
        <Dropdown
          menu={{
            items: isMobile
              ? []
              : dateMenuItems.map(item => ({
                  ...item,
                  key: item.value,
                })),
          }}
        >
          <Button
            size="large"
            className={cx(
              'group rounded-[4px] border-light border text-sm',
              state.significantChangesParameters.period &&
                state.significantChangesParameters.period !== 'All'
                ? 'font-bold text-neutral-700'
                : 'text-neutral-100',
              'hover:border-primary hover:text-primary',
            )}
            onClick={() => isMobile && setIsDateNestedDrawerVisible(true)}
          >
            <div>
              {dateDropDownData.find(
                date => date.key === state.significantChangesParameters.period,
              )?.label || 'Date'}
            </div>
            <NextArrow className="fill-current h-3 w-3 rotate-90" />
          </Button>
        </Dropdown>
        {isMobile && (
          <NestedDrawer
            menuItems={dateMenuItems}
            visible={isDateNestedDrawerVisible}
            onClose={() => setIsDateNestedDrawerVisible(false)}
            title="Select Date"
          />
        )}
      </div>
      {anyFiltersSelected || state.significantChangesParameters.term !== '' ? (
        <div className="w-full py-1 pl-1 whitespace-nowrap">
          <div
            className="clear-filters"
            onClick={() => {
              setCheckedItems([]);
              onClear();
            }}
            aria-hidden="true"
            id="clear-filter"
            data-test-id={'clearFilter'}
          >
            {isDesktopAndSmaller ? <ClearFilterIcon /> : 'Clear filters'}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SignificantChangesFilter;
