import { LEGAL_STRUCTURE } from '@aminsights/shared';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useExploreFilters from '../../../hooks/useExploreFilters';

const MoreFiltersLegalStructure = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const { state: featureSwitch } = useFeatureSwitchContext();

  return (
    <MultiCheckBox
      data={[
        {
          label: 'Fund',
          value: LEGAL_STRUCTURE.FUNDS,
        },
        {
          label: 'Investment trust',
          value: LEGAL_STRUCTURE.INVESTMENT_TRUSTS,
        },
        {
          label: 'ETF',
          value: LEGAL_STRUCTURE.ETF,
        },
        ...(featureSwitch.isMPSEnabled
          ? [{ label: 'MPS', value: LEGAL_STRUCTURE.MPS }]
          : []),
      ]}
      value={tentativeFilters.moreFilters?.legalStructure}
      onChange={value => {
        const filters = getMutableFilters();
        filters.moreFilters = filters.moreFilters ?? {};
        filters.moreFilters.legalStructure =
          value.length > 0 ? value : undefined;
        updateTentativeFilters(filters);
      }}
    />
  );
};

export default MoreFiltersLegalStructure;
