import * as portfolioRecuderHelper from './reducerHelper';
import {
  PORTFOLIO_ACTIONS,
  PortfolioActionTypes,
  PortfolioState,
} from './types';

export const portfolioReducer = (
  state: PortfolioState,
  action: PortfolioActionTypes,
): PortfolioState => {
  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );
  switch (action.type) {
    case PORTFOLIO_ACTIONS.ADD_FUND_TO_CURRENT_PORTFOLIO:
      return portfolioRecuderHelper.addFundToCurrentPortfolio(
        state,
        action.payload,
      );
    case PORTFOLIO_ACTIONS.REMOVE_FUND_FROM_CURRENT_PORTFOLIO:
      return portfolioRecuderHelper.removeFundFromCurrentPortfolio(state);
    case PORTFOLIO_ACTIONS.ADD_FUND_SEARCH_BOX_TO_CURRENT_PORTFOLIO:
      return portfolioRecuderHelper.addFundSearchboxToCurrentPortfolio(state);
    case PORTFOLIO_ACTIONS.REMOVE_FUND_SEARCH_BOX_FROM_CURRENT_PORTFOLIO:
      return portfolioRecuderHelper.removeFundSearchboxToCurrentPortfolio(
        state,
      );
    case PORTFOLIO_ACTIONS.SET_PORTFOLIOS:
      return {
        ...state,
        portfolios: action.payload,
        arePortfoliosLoading: false,
        isPerformanceTabLoading: false,
        markedFundsForDelete: [],
      };
    case PORTFOLIO_ACTIONS.SET_IS_LOADING:
      return { ...state, arePortfoliosLoading: true };
    case PORTFOLIO_ACTIONS.SET_ISNOT_LOADING:
      return { ...state, arePortfoliosLoading: false };
    case PORTFOLIO_ACTIONS.SET_CURRENT_PORTFOLIO:
      return {
        ...state,
        currentPortfolioId: action.payload,
      };
    case PORTFOLIO_ACTIONS.IDENTIFY_FUND_FOR_DELETE:
      return portfolioRecuderHelper.identifyFundForDelete(
        state,
        action.payload,
      );
    case PORTFOLIO_ACTIONS.ADD_DATE:
      return portfolioRecuderHelper.addDate(state);
    case PORTFOLIO_ACTIONS.UPDATE_DATE:
      return portfolioRecuderHelper.updateDate(state, action.payload);
    case PORTFOLIO_ACTIONS.DELETE_DATE:
      return portfolioRecuderHelper.deleteDate(state, action.payload);
    case PORTFOLIO_ACTIONS.CLEAN_EMPTY_DATES:
      return portfolioRecuderHelper.cleanEmptyDates(state);
    case PORTFOLIO_ACTIONS.UNMARK_ALL_FUNDS_FOR_DELETE:
      return { ...state, markedFundsForDelete: [] };
    case PORTFOLIO_ACTIONS.MARK_ALL_FUNDS_FOR_DELETE:
      return {
        ...state,
        markedFundsForDelete: (currentPortfolio?.funds ?? []).map(f => f.isin),
      };
    case PORTFOLIO_ACTIONS.UPDATE_WEIGHTING:
      return portfolioRecuderHelper.upsertWeighting(state, action.payload);
    case PORTFOLIO_ACTIONS.DELETE_PORTFOLIO:
      return {
        ...state,
        portfolios: state.portfolios.filter(
          p => p._id !== action.payload.portfolioIdToRemoved,
        ),
      };
    case PORTFOLIO_ACTIONS.SET_IS_PERFORMANCE_TAB_LOADING:
      return {
        ...state,
        isPerformanceTabLoading: action.payload,
      };
    default:
      return state;
  }
};
