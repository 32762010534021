import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { AutoComplete, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import cx from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';

import { ReactComponent as IconClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconEnter } from '@/assets/svg/icons/icon-enter.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';

import style from './style.module.less';
import { getHighlightedText } from './utils';

interface SearchPropsV2 extends Omit<SearchInputProps, 'setErrorMessage'> {
  onBlur?: () => void;
  className?: string;
  popupClassName?: string;
}

/**
 * PLEASE DON'T ADD CUSTOM PROPS UNLESS NECESSARY.
 * TO CUSTOMIZE EACH SEARCHBOX, CREATE A NEW COMPONENT.
 * June 14, 2024
 */
interface SearchInputProps {
  autoFocus?: boolean;
  showArrow?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  options: DefaultOptionType[];
  value: string;
  onKeyEnter?: (
    value: string,
    callback: React.Dispatch<React.SetStateAction<string | null>>,
  ) => void;
  onSelect: (
    value: string,
    callback: React.Dispatch<React.SetStateAction<string | null>>,
  ) => void;
  onSubmit: (value: string) => void;
  onChange: (value: string) => void;
  loading?: boolean;
  onClear?: () => void;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  extraContent?: React.ReactNode;
  optionsFooter?: React.ReactNode;
  placeholder?: string;
  popupClassName?: string;
}

export const renderOptions = ({
  title,
  value,
  highlightTerm,
  primaryShareClass,
  isLegalStructureMPS,
  isPortfolio,
  disabled,
}: {
  title: string;
  value: string;
  highlightTerm: string;
  primaryShareClass?: boolean;
  isLegalStructureMPS?: boolean;
  isPortfolio?: boolean;
  disabled?: boolean;
}) => ({
  value,
  fundName: title,
  disabled,
  label: (
    <Tooltip
      placement="bottomLeft"
      title={disabled ? 'This fund has already been added' : ''}
      color="#313341"
      arrow={false}
    >
      <div
        data-test-id={value}
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
        className={cx({ 'text-neutral-300': disabled })}
      >
        <div className="flex items-center justify-center">
          <StarFilled
            style={{
              color: disabled ? '#BAC0D0' : '#0072E6',
              opacity: primaryShareClass ? '1' : '0',
              fontSize: '14px',
            }}
          />
        </div>
        <div className="flex-1">
          {getHighlightedText(title, value, highlightTerm)}
        </div>
        {isPortfolio && (
          <div
            className={cx(
              'bg-grey-lighter py-0.5 px-2 text-xs font-normal text-neutral-700',
              { 'text-neutral-300': disabled },
            )}
          >
            Portfolio
          </div>
        )}
        {isLegalStructureMPS && (
          <div
            className={cx(
              'rounded bg-info-50 text-xs font-normal text-info-600 py-0.5 px-2',
              { 'text-neutral-300 bg-grey-lighter': disabled },
            )}
          >
            MPS
          </div>
        )}
      </div>
    </Tooltip>
  ),
  id: value,
});

/**
 * PLEASE DON'T ADD CUSTOM PROPS UNLESS NECESSARY.
 * TO CUSTOMIZE EACH SEARCHBOX, CREATE A NEW COMPONENT.
 * June 14, 2024
 */
export const ExploreSearchInput = forwardRef<any, SearchInputProps>(
  (
    {
      showArrow,
      dataTestId,
      disabled,
      options,
      value,
      onKeyEnter,
      onSubmit,
      onChange,
      onSelect,
      loading,
      onClear,
      setErrorMessage,
      extraContent,
      optionsFooter: optionsFooterProp,
      placeholder = 'Search funds by ISIN or keyword',
      autoFocus,
      popupClassName,
    },
    ref,
  ) => {
    const optionsFooter = (
      <span className="ant-select--title">
        Press <b>Enter</b> or <IconEnter className="icon search-enter" /> to
        search and <b>ESC</b> or <b>X</b> to cancel from search
      </span>
    );
    const optionsMapped = [
      ...options,
      { label: optionsFooterProp || optionsFooter, options: [], id: 'footer' },
    ];

    return (
      <div
        className={cx(style['search-wrapper'], 'inner-container')}
        data-test-id={dataTestId}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <AutoComplete
          ref={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          popupClassName={cx(
            style['search-dropdown'],
            'max-[655px]:!w-[78vw]',
            'explore-search-autocomplete',
            popupClassName,
          )}
          className="
          [&_.ant-select-selection-placeholder]:text-sm [&_.ant-select-selection-placeholder]:text-neutral-100
          [&_.ant-select-selection-search-input]:text-neutral-700
        "
          options={optionsMapped.length > 1 ? optionsMapped : []}
          value={value}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              if (onKeyEnter) {
                onKeyEnter(value, setErrorMessage);
              } else {
                onSubmit(value);
              }
              return;
            }
            setErrorMessage(null);
          }}
          onChange={(newTerm, selectedValue) => {
            if (Array.isArray(selectedValue)) {
              console.error(
                'Explore search filter does not allow for multiple selections',
              );
              return;
            }
            if ('value' in selectedValue && 'fundName' in selectedValue) {
              onChange(selectedValue.fundName); // If an item is selected the newTerm will be its label anyway
            } else {
              onChange(newTerm); // If an item is selected the newTerm will be its label anyway
            }
          }}
          onSelect={async (selectedValue: string) => {
            if (onSelect) {
              await onSelect(selectedValue, setErrorMessage);
              return;
            }
          }}
          notFoundContent={(() => {
            if (loading) {
              return <LoadingOutlined />;
            }
            if (value === '' || (value === ' ' && !options?.length)) {
              return undefined;
            }
            return 'No results found';
          })()}
        />
        <IconSearch
          className={cx('icon', style['search-icon'], 'search-icon')}
        />
        {(onClear || showArrow) && (
          <div className={cx(style['search-nav'], style['search-nav--navbar'])}>
            {onClear && value && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  onClear?.();
                }}
              >
                <IconClose
                  className={cx(
                    style['search-nav--clear'],
                    'explore-search-clear icon',
                  )}
                />
              </button>
            )}
            {showArrow && (
              <NextArrow
                className={cx(
                  'icon',
                  style['dropdown-icon-caret'],
                  style['search-nav--arrow'],
                )}
              />
            )}
          </div>
        )}
        {extraContent}
      </div>
    );
  },
);

export const ExploreSearchV2 = forwardRef<HTMLDivElement, SearchPropsV2>(
  ({ className, onBlur, ...props }, ref) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { currentWidth } = useScreenWidth();
    const isMobile = currentWidth < screenBreakpoints.md;
    useEffect(() => {
      if (!isMobile) {
        // this ensures the searchbox is focused when the page loads
        // requires a timeout to ensure the element is rendered
        setTimeout(() => {
          const input = document.querySelector(
            '.ant-select-selector input',
          ) as HTMLInputElement;
          input?.focus();
        }, 100);
      }
    }, [isMobile]);
    return (
      <div className={cx('flex flex-col w-full', className)} onBlur={onBlur}>
        <ExploreSearchInput
          autoFocus={!isMobile}
          {...props}
          setErrorMessage={setErrorMessage}
          ref={ref}
        />
        {errorMessage && (
          <div className="pt-1 pl-1 text-xs font-medium text-danger">
            {errorMessage}
          </div>
        )}
      </div>
    );
  },
);

export default ExploreSearchV2;
