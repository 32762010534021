import {
  NoteBody,
  NoteRecord,
  NoteStatus,
  NotesApi,
} from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { NOTES } from './query-keys';

const noteApi = new NotesApi(openApiConfig());

const invalidateNotes = () => {
  queryClient.invalidateQueries({ queryKey: [NOTES] });
};

export const useGetNotes = (fundId: string) => {
  return useQuery<NoteRecord[]>(
    [NOTES, fundId],
    async () => {
      const result = await noteApi.getNotes(fundId);
      return result.data;
    },
    { enabled: Boolean(fundId) },
  );
};

export const useCreateNote = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; body: NoteBody }) =>
      noteApi.createNote(input.fundId, input.body),
    {
      onSettled: () => invalidateNotes(),
      onSuccess: (data, input) => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: `${input.body.category} has been added` },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error creating notes',
        });
      },
    },
  );

  return mutation;
};

export const usePublishNotes = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; noteIds: string[] }) =>
      noteApi.publishNotes(input.fundId, {
        noteIds: input.noteIds,
        status: NoteStatus.Published,
      }),
    {
      onSettled: () => invalidateNotes(),
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error publishing notes',
        });
      },
    },
  );

  return mutation;
};

export const useDeleteAllNotes = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string }) => noteApi.deleteAllNotes(input.fundId),
    {
      onSettled: () => invalidateNotes(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Notes has been deleted' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error deleting notes',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateNote = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; noteId: string; body: NoteBody }) =>
      noteApi.updateNote(input.fundId, input.noteId, input.body),
    {
      onSettled: () => invalidateNotes(),
      onSuccess: (data, input) => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: `${input.body.category} has been updated` },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error updating note',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteNote = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; noteId: string }) =>
      noteApi.deleteNote(input.fundId, input.noteId),
    {
      onSettled: () => invalidateNotes(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Note has been deleted' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error deleting note',
        });
      },
    },
  );
  return mutation;
};
