export const adjustMarksToAvoidDuplicates = (
  values: number | number[],
  marks: Record<number, string>,
) => {
  if (!Array.isArray(values)) {
    return [values];
  }

  const [min, max] = values;
  const adjustedValues = [min, max];
  if (min === max) {
    adjustedValues.length = 0;
    const marksArray = Object.keys(marks).map(Number);
    const nearestMin = marksArray.reduce((a, b) => (b < min ? b : a));
    const nearestMax = marksArray.reduce((a, b) => (b > max ? b : a));
    adjustedValues.push(nearestMin, nearestMax);
  }

  return adjustedValues;
};
