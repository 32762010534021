// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I0UbnIVBqnMR67WcKcEp .ant-table-cell:first-child {\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n.I0UbnIVBqnMR67WcKcEp .ant-table-thead .ant-table-cell {\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n  background-color: #f7f7f7\n}\n.I0UbnIVBqnMR67WcKcEp .ant-table-thead .ant-table-cell:first-child::before {\n  display: none\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/InfoAndStats/SummaryStats/style.module.less"],"names":[],"mappings":"AAII;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAGA;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;EACA;AADA;AAIA;EAAA;AAAA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.summary-stats__table :global {\n  .ant-table-cell:first-child {\n    @apply font-bold text-darkest;\n  }\n  .ant-table-thead .ant-table-cell {\n    @apply text-xs font-normal text-neutral py-2;\n    background-color: @bg;\n  }\n  .ant-table-thead .ant-table-cell:first-child::before {\n    @apply hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary-stats__table": "I0UbnIVBqnMR67WcKcEp"
};
export default ___CSS_LOADER_EXPORT___;
