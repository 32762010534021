import { Meeting } from '@aminsights/contract';
import dayjs from 'dayjs';

export const TIME_OPTIONS = [
  '12:00 AM',
  '01:00 AM',
  '02:00 AM',
  '03:00 AM',
  '04:00 AM',
  '05:00 AM',
  '06:00 AM',
  '07:00 AM',
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
  '04:00 PM',
  '05:00 PM',
  '06:00 PM',
  '07:00 PM',
  '08:00 PM',
  '09:00 PM',
  '10:00 PM',
  '11:00 PM',
].map(value => ({
  value,
  key: value,
  label: value,
}));

export const composeAttendeesList = (attendees: Meeting['attendees']) => {
  return attendees
    .slice(0, 2)
    .map(attendee => attendee.name)
    .join(', ')
    .concat(attendees.length > 2 ? `, +${attendees.length - 2} more` : '');
};

type GroupedMeetings = Record<string, Meeting[]>;

export const groupMeetingsByYear = (meetings: Meeting[]) => {
  const groupedMeetings = meetings.reduce(
    (groupedMeetings: GroupedMeetings, meeting) => {
      const year = dayjs(meeting.date).year().toString();
      if (!groupedMeetings[year]) groupedMeetings[year] = [];

      groupedMeetings[year].push(meeting);
      return groupedMeetings;
    },
    {},
  );

  const sortedGroups = Object.keys(groupedMeetings).sort(
    (a, b) => Number(b) - Number(a),
  );

  return {
    groupedMeetings,
    sortedGroups,
  };
};
