import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as GraphWithMagnifier } from '@/assets/svg/graph-with-magnifier.svg';

import styles from './style.module.less';

interface EmptyPortfolioTableProps {
  currentPortfolioId?: string;
  onAddFundClick: () => void;
  isHoldingsTab?: boolean;
  hideEditActions?: boolean;
}

const EmptyPortfolioTable: React.FCWithChild<EmptyPortfolioTableProps> = ({
  currentPortfolioId,
  onAddFundClick,
  isHoldingsTab,
  hideEditActions,
}) => {
  const history = useHistory();
  return (
    <div
      className={`${styles['inner-portfolio-body']} w-full p-5 pt-10 flex flex-col items-center justify-center gap-y-4`}
    >
      <GraphWithMagnifier />
      <b className="text-lg font-black text-center">
        {isHoldingsTab && !hideEditActions
          ? 'No funds yet'
          : 'This portfolio is not ready'}
      </b>
      <span className="text-sm font-normal text-center">
        {isHoldingsTab
          ? 'Start adding funds to your portfolio by clicking on the button below.'
          : 'No data available'}
      </span>
      {!hideEditActions && (
        <Button
          data-test-id="addFundButton"
          disabled={!currentPortfolioId}
          type="primary"
          size="large"
          className={`${styles['portfolio-btn']} ${styles['add-fund-btn']}`}
          onClick={() => {
            onAddFundClick();
            history.push(`/portfolios/${currentPortfolioId}/holdings`);
          }}
        >
          + Add fund
        </Button>
      )}
      {!hideEditActions && (
        <span>
          or{' '}
          <Link
            to={`/${APP_ROUTE_PORTFOLIOS}/import-portfolio`}
            className={styles['import-portfolio-btn']}
          >
            import
          </Link>
        </span>
      )}
    </div>
  );
};

export default EmptyPortfolioTable;
