import { RatingRecord } from '@aminsights/contract';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { Modal } from 'antd';
import { RatingListing } from './RatingListing';

interface RatingsByYearProps {
  ratings: RatingRecord[];
  isModalOpen: boolean;
  toggleModal: () => void;
}

export const RatingsByYear: FC<RatingsByYearProps> = ({
  ratings,
  isModalOpen,
  toggleModal,
}) => {
  const [isNestedModalOpen, setIsNestedModalOpen] = useState(false);
  // Group ratings by year using dayjs
  const ratingsByYear = ratings.reduce<{ [year: string]: RatingRecord[] }>(
    (acc, rating) => {
      const year = dayjs(rating._createdAt).year().toString();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(rating);
      return acc;
    },
    {},
  );

  // Sort years descending
  const sortedYears = Object.keys(ratingsByYear).sort(
    (a, b) => Number(b) - Number(a),
  );

  return (
    <Modal
      open={isModalOpen && !isNestedModalOpen}
      title="Ratings"
      onCancel={toggleModal}
      className="action-modal action-modal-confirmation [&_.ant-modal-body]:px-0"
      footer={null}
    >
      <div className="py-4">
        <div className="px-10 max-h-128 overflow-auto">
          {sortedYears.map(year => (
            <div className="first:mt-0 mt-6" key={year}>
              <h2 className="text-neutral-900 font-bold mb-2">{year}</h2>
              {ratingsByYear[year].map(rating => (
                <RatingListing
                  key={rating._id}
                  rating={rating}
                  onToggleNestedModal={() => {
                    setIsNestedModalOpen(prevValue => !prevValue);
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
