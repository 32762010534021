import React from 'react';

import SignificantChangesForm from '@/partials/SignificantChanges';

const SignificantChanges: React.FCWithChild = () => (
  <div className="px-4 pb-4 pt-5 flex flex-row justify-center min-h-[512px]">
    <div className="md:w-1/2 w-full">
      <h5
        data-test-id="significantChangesSectionTitle"
        className="text-darkest font-bold"
      >
        Significant Changes
      </h5>
      <p className="pt-4 pb-6 text-neutral">
        Edit your parameters to be alerted about key changes in your watchlist.
      </p>

      <SignificantChangesForm />
    </div>
  </div>
);

export default SignificantChanges;
