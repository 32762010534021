import { ChartsApi, MarketData } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RangeValue, parseRangeValues } from '@/partials/DatePicker';
import { openApiConfig } from '@/utils';

type UseMarketDataByRangeResponse = MarketData[] | undefined;
type UseMarketDataByRangeError = unknown; // If API had error types place here
type UseMarketDataByRangeptions = Omit<
  UseQueryOptions<UseMarketDataByRangeResponse, UseMarketDataByRangeError>,
  'queryKey' | 'queryFn' | 'initialData'
>; // Probably won't be used often but its what makes react-query so easy to tweak as needed

const useMarketDataByRange = (
  rangeInput: RangeValue | undefined | undefined,
  options?: UseMarketDataByRangeptions,
) => {
  const range = rangeInput ? parseRangeValues(rangeInput) : undefined;

  return useQuery<UseMarketDataByRangeResponse, UseMarketDataByRangeError>(
    ['use_market_data_by_range', range],
    async () => {
      if (range) {
        const chartsAPI = new ChartsApi(openApiConfig());
        const { data } = await chartsAPI.getMarketDataByRange(range);
        return data.data;
      }
      return undefined;
    },
    options,
  );
};

export default useMarketDataByRange;
