export const TREATMENT_NAME_CLARITY = 'clarity';
export const TREATMENT_NAME_FUND_DETAILS_ACTIONS = 'fund-details-actions';
export const TREATMENT_NAME_WEEKLY_UPDATE = 'weekly-update';
export const TREATMENT_AI_SEARCH = 'ai-search';
export const TREATMENT_APP_LIMITED_ACCESS = 'app-limited-access';
export const TREATMENT_RESEARCH = 'research';
export const TREATMENT_MPS = 'mps';
export const TREATMENT_SECTOR_IN_CHARTING = 'sector-in-charting';

export enum TreatmentValues {
  On = 'on',
  Off = 'off',
  Control = 'control',
}
