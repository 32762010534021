// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iKgBJ7agltYhz7oizUPY.ant-btn:hover {\n  opacity: 0.9;\n  color: #40a9ff;\n}\n.iKgBJ7agltYhz7oizUPY.ant-btn.ant-btn-primary {\n  display: flex;\n  align-items: center;\n  border-radius: 0.25rem;\n  --tw-text-opacity: 1;\n  color: rgb(255 255 255 / var(--tw-text-opacity));\n  outline-color: #0072e6;\n  background-color: #007fff;\n}\n.iKgBJ7agltYhz7oizUPY.ant-btn.ant-btn-link {\n  color: #6f707a;\n}\n.iKgBJ7agltYhz7oizUPY.ant-btn.ant-btn-link:hover {\n  color: #007fff;\n}\n.iKgBJ7agltYhz7oizUPY.ant-btn:disabled:not(.ant-btn-link),\n.iKgBJ7agltYhz7oizUPY.ant-btn[disabled]:not(.ant-btn-link) {\n  background-color: #BAC0D0;\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/style.module.less"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,cAAA;AAFJ;AAMI;EAAA,aAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,oBAAA;EAAA,gDAAA;EACA,sBAAA;EACA;AAFA;AAKF;EACE,cAAA;AAJJ;AAMI;EACE,cAAA;AAJN;AAQE;;EAEE,yBAAA;EACA,YAAA;AANJ","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.custom-btn:global.ant-btn {\n  &:hover {\n    opacity: 0.9;\n    color: #40a9ff;\n  }\n\n  &.ant-btn-primary {\n    @apply flex items-center text-white rounded;\n    outline-color: #0072e6;\n    background-color: #007fff;\n  }\n\n  &.ant-btn-link {\n    color: #6f707a;\n\n    &:hover {\n      color: #007fff;\n    }\n  }\n\n  &:disabled:not(.ant-btn-link),\n  &[disabled]:not(.ant-btn-link) {\n    background-color: theme('backgroundColor.neutral.300');\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-btn": "iKgBJ7agltYhz7oizUPY"
};
export default ___CSS_LOADER_EXPORT___;
