import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  GlobalSectorEquity,
  SectorWithExtremums,
} from '@aminsights/shared';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { roundOrEmpty } from '@/utils/roundOrEmpty';
import { sectorLegendList } from '@/utils/sectorLegendList';

import style from './style.module.less';

const generateWatchlistSectorEqColumn = (
  title: string,
  fullName: keyof typeof GlobalSectorEquity,
  sectorType: number,
  sectorsWithExtremums?: SectorWithExtremums[],
  currentBucket?: Bucket | undefined,
): IDataTableColumns => {
  const globalSector: GlobalSectorEquity = GlobalSectorEquity[fullName];
  const sectorTypeColor =
    (sectorType &&
      sectorLegendList.find(i => i.type.includes(sectorType))?.label) ||
    '';
  return {
    title: <span className="whitespace-normal text-start">{title}</span>,
    dataTestIdSuffix: title,
    sortKey: `sectorsEquity.${globalSector}`,
    tooltipText: fullName,
    backgroundColor: (item: Fund) => {
      const sectorType = globalSector;
      const sectorWithExtremumsForType = sectorsWithExtremums?.find(
        s => s.type === sectorType,
      );
      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item._id === f?.isin,
      )?.isFeatured;
      if (sectorWithExtremumsForType) {
        if (
          sectorWithExtremumsForType.isinsOfMaximum.some(i => i === item._id)
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          sectorWithExtremumsForType.isinsOfMinimum.some(i => i === item._id)
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const sectorType = Number(globalSector);
      const sectorValue = item.sectorsEquity?.find(
        s => s.type === sectorType,
      )?.value;

      return <span>{roundOrEmpty(sectorValue, 1, '%')}</span>;
    },
    renderType: 'number',
    width: 85,
    className: style[`${sectorTypeColor.toLowerCase()}`],
  };
};

export default generateWatchlistSectorEqColumn;
