import {
  ChangeTypeEnum,
  EMPTY_DATA_POINT,
  TrendType,
  formatChangeType,
} from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import cx from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import { ReactComponent as TrendIcon } from '@/assets/svg/icons/icon-trend-arrow.svg';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';

interface SectionItemProps {
  sectionId: number;
  title: string;
  content: string;
  loading?: boolean;
  dateData?: string;
  trend?: string;
  changeType: string;
  currencyCode: string;
  broadAssetClass: string | undefined;
  formatFlow?: boolean;
}

const ModalSectionItem: React.FCWithChild<SectionItemProps> = ({
  sectionId,
  title,
  content,
  loading,
  dateData,
  trend,
  changeType,
  broadAssetClass,
  formatFlow,
}) => {
  return (
    <div className="md:items-start md:w-auto flex flex-col p-2 w-[30%] [&_h1]:text-[18px] [&_h5]:text-[0.75rem]">
      <Loader
        width="50px"
        loading={loading}
        component={
          <h5
            className={`text-left text-sm md:text-xs w-30 md:w-auto font-regular text-neutral md:mb-2 ${
              (changeType === ChangeTypeEnum.Flow &&
                sectionId === 2 &&
                'pl-7') ||
              (changeType === ChangeTypeEnum.Flow && 'pl-3')
            }`}
          >
            {title}
          </h5>
        }
      />
      <Loader
        width="50px"
        loading={loading}
        component={
          <div className="flex mb-2">
            {sectionId === 2 ? (
              <>
                <span
                  className={cx(
                    'icon text-xl mr-2 flex items-center',
                    {
                      'text-success-trend': trend === TrendType.Upward,
                      'text-danger-trend': trend === TrendType.Downward,
                    },
                    trend === TrendType.Upward
                      ? '[transform:rotate(180deg)_scaleX(-1)]'
                      : 'pt-[10px]',
                  )}
                >
                  <TrendIcon />
                </span>
                <h1
                  className={cx('text-2xl font-bold', {
                    'text-success-trend': trend === TrendType.Upward,
                    'text-danger-trend': trend === TrendType.Downward,
                  })}
                >
                  {content ? `${content}%` : EMPTY_DATA_POINT}
                </h1>
              </>
            ) : (
              <h1 className="text-2xl text-darkest font-bold">
                {formatChangeType(
                  changeType,
                  Number(content),
                  broadAssetClass,
                  formatFlow,
                )}
              </h1>
            )}
          </div>
        }
      />
      <Loader
        width="50px"
        loading={loading}
        component={
          <h5 className="text-sm md:text-xs w-30 md:w-auto font-regular text-neutral">
            {sectionId !== 2 &&
              dateData &&
              dayjs(dateData).isValid() &&
              dayjs(dateData).format(DISPLAY_DATE_FORMAT)}
          </h5>
        }
      />
    </div>
  );
};

export default ModalSectionItem;
