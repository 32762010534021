import { ChartDataTag } from '@aminsights/contract';
import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import ChartSettings from '@/components/ChartSettings/ChartSettings';
import useGetPremiumDiscountChartData from '@/hooks/query-hooks/chart-hooks/useGetPremiumDiscountChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import RangeDatePicker, {
  parseRangeValues,
  useDatePickerContext,
} from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartProps } from '../components/BaseChart';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';
import useChartColors from '../utils/useChartColors';

interface PremiumDiscountChartProps extends BaseChartProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  allIsins?: string[];
}

const PremiumDiscountChart: React.FCWithChild<PremiumDiscountChartProps> = ({
  chartOnly,
  emptyState,
  showFundLinks = true,
  onRemoveFund,
  isins,
  allIsins,
  benchmarkId,
  featuredIsins,
  chartColors: chartColorsProps,
}) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading, isFetching } = useGetPremiumDiscountChartData({
    benchmarkId,
    isins,
    period,
    featuredIsins,
  });
  const premiumDiscountData = data?.data || [];
  const chartColorsHook = useChartColors({
    isins: allIsins || isins,
    legend: data?.legendData || [],
  });
  const chartColors = chartColorsProps || chartColorsHook;

  const isChartLoaded = useMemo(() => {
    if (!isLoading && !!data) {
      if (chartColors) {
        return !!Object.keys(chartColors).length;
      }
      return true;
    }
    return false;
  }, [isLoading, data, chartColors]);

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          value: f.value || '',
          id: f.id || '',
          label: f.label || '',
          color: chartColors[f.id || ''] || '',
          date: f.date || '',
          dataTag: f.dataTag,
        })),
      );
    }
  }, [data, chartColors]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: premiumDiscountData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [premiumDiscountData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!premiumDiscountData.length && !isLoading && !isFetching) {
    return <ChartEmptyState title={emptyState.title} isDashboardPage={true} />;
  }

  return (
    <div>
      {!chartOnly && (
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between my-2">
          <h5
            data-test-id="premiumDiscountChartLabel"
            className="text-sm font-bold text-darkest"
          >
            <Loader
              width="150px"
              loading={isLoading}
              component={<>Premium/Discount</>}
            />
          </h5>
          <div className="flex items-center gap-x-2">
            <RangeDatePicker
              disabled={isLoading}
              loading={isLoading}
              onChange={handleDatePickerChange}
              value={datePickerValue}
              btnClassName="justify-self-end col-span-2 sm:col-span-1 w-full"
              dataTestId="premiumDiscountChartDropdown"
            />
            <ChartSettings hidePanelKeys={'investment-trusts-returns'} />
          </div>
        </div>
      )}
      {isChartLoaded && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length &&
            legendData.some(d => d.dataTag === ChartDataTag.Featured)
          }
          shouldYScaleBeChanged
          dataTestId="premiumDiscount"
          onUpdateLegend={setLegendData}
          tooltipOrder={tooltipOrder}
        />
      )}
      {(isLoading || !isChartLoaded) && (
        <img className="w-full mt-5 mb-5" src={loaderImage} />
      )}
      <div
        data-test-id="premiumDiscountChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                id={getLegendIdToRemove(legendItem)}
                tooltip={legendItem.tooltip || legendItem.label}
                color={legendItem.color}
                date={legendItem.date}
                value={`${legendItem.value}`}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                targetLink={
                  showFundLinks ? getLegendTargetLink(legendItem) : undefined
                }
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default PremiumDiscountChart;
