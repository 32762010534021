import { SignificantChangesThreshold } from '@aminsights/contract';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Button } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import useSaveSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useSaveSignificantChangesThreshold';
import { SIGNIFICANT_CHANGES } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import queryClient from '@/queryClient';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import NestedDrawer from '../NestedDrawer';
import InfoTooltip from './InfoTooltip';
import significantValues from './significantValues';

const SignificantChanges: React.FCWithChild = () => {
  const { user } = useAuth0();
  const { dispatch: dispatchApp } = useAppContext();

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const { mutateAsync: saveSignificantChangesThreshold, isLoading } =
    useSaveSignificantChangesThreshold({
      onSuccess: () => {
        queryClient.invalidateQueries([SIGNIFICANT_CHANGES]);
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Significant Changes settings updated.' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Something went wrong.',
        });
      },
    });
  const { data: significantChangesThresholdResponse } =
    useGetSignificantChangesThreshold();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    setValue,
    reset,
  } = useForm<{ significantChangesThreshold: SignificantChangesThreshold }>({
    mode: 'all',
  });

  const setSignificantChangesThresholdValue = () => {
    if (significantChangesThresholdResponse) {
      setValue(
        'significantChangesThreshold',
        significantChangesThresholdResponse,
      );
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(setSignificantChangesThresholdValue, [
    significantChangesThresholdResponse,
  ]);

  const onFormSubmit = async (values: {
    significantChangesThreshold: SignificantChangesThreshold;
  }) => {
    if (!user?.sub || !isValid) {
      return;
    }

    await saveSignificantChangesThreshold({
      ...values.significantChangesThreshold,
      userId: user?.sub,
    });
    reset({
      significantChangesThreshold: values.significantChangesThreshold,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-1/2">
            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Fund Size</p>
                <InfoTooltip title="+/-25% means you will be alerted if Fund Size moves from e.g. £500m to >£625m." />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.fundSizeThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.default.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.default.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="fundSizeThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">OCF</p>
                <InfoTooltip title="+/-0.5% means you will be alerted if the OCF moved from e.g. 1.1% to >1.6%" />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.ocfThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.ocf.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.ocf.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));

                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="ocfThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Premium/Discount</p>
                <InfoTooltip title="+/-4% means you will be alerted if the Premium/Discount moved from e.g. -3% to -7%" />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.premiumDiscountThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.premiumDiscount.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.premiumDiscount.map(
                    item => ({
                      key: item.label,
                      label: item.label,
                      onClick: () => {
                        onChange(item.value);
                        setIsNestedDrawerVisible(false);
                      },
                      selected: item.value === value,
                    }),
                  );
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="premiumDiscountThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Modified Duration</p>
                <InfoTooltip title="+/-25% means you will be alerted if Modified Duration moves from e.g. 2 to > 2.5" />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.modifiedDurationThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.default.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.default.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="durationThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            <div className="mb-4 pl-0 md:pl-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Flow</p>
                <InfoTooltip title="+/-25% means you will be alerted if Flow moves from e.g. 2 to > 2.5" />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.flowThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.default.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.default.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="flowThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="mb-4 pl-0 md:pl-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Holdings</p>
                <InfoTooltip title="+/-25% means you will be alerted if Holdings change from e.g. 50 to >63." />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.holdingsThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.default.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.default.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        className="dropdown"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="holdingsThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="mb-4 pl-0 md:pl-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Yield</p>
                <InfoTooltip title="+/-2% means you will be alerted if the Yield moved from e.g. 3.1% to >5.1%" />
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.yieldThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const [isNestedDrawerVisible, setIsNestedDrawerVisible] =
                    useState(false);
                  const label = significantValues.yield.find(
                    item => item.value === value,
                  )?.label;
                  const menuItems = significantValues.yield.map(item => ({
                    key: item.label,
                    label: item.label,
                    onClick: () => {
                      onChange(item.value);
                      setIsNestedDrawerVisible(false);
                    },
                    selected: item.value === value,
                  }));
                  return (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        menu={{
                          items: isMobile ? [] : menuItems,
                        }}
                        overlayClassName="!shadow-[0px_3px_6px_rgba(185,_191,_207,_0.749)]"
                      >
                        <div
                          onClick={() =>
                            isMobile && setIsNestedDrawerVisible(true)
                          }
                        >
                          <Button
                            className={cx(
                              'flex items-center !border border-light hover:border-primary hover:[&>svg]:text-primary',
                              'hover:[&>p]:text-primary rounded h-8 min-w-20 px-3 py-1 text-left text-neutral-700 [&>p]:text-neutral-700 !w-full gap-3',
                            )}
                            type="link"
                            data-test-id="yieldThresholdDropdown"
                          >
                            <p className="truncate text-sm mb-0 flex-grow">
                              {label}
                            </p>
                            <NextArrow className="icon text-xs transform rotate-90 fill-current" />
                          </Button>
                        </div>
                      </Dropdown>
                      {isMobile && (
                        <NestedDrawer
                          menuItems={menuItems}
                          visible={isNestedDrawerVisible}
                          onClose={() => setIsNestedDrawerVisible(false)}
                          title="Select"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            disabled={
              !isValid ||
              isLoading ||
              !isDirty ||
              Object.keys(errors).length !== 0
            }
            className="submit-btn"
            type="primary"
            htmlType="submit"
            data-test-id="saveSignificantChangesButton"
          >
            {isLoading ? 'Please wait...' : 'Save Changes'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SignificantChanges;
