import React from 'react';

import { VerificationError } from './VerificationError';
import { VerificationSuccess } from './VerificationSuccess';
import { useQueryParams } from './hooks';

export const VerificationEmail: React.FCWithChild = () => {
  const query = useQueryParams();
  const message = query.get('message');
  const success = query.get('success');
  const errorDescription = query.get('error_description');
  return success === 'false' || Boolean(errorDescription) ? (
    <VerificationError message={message} description={errorDescription} />
  ) : (
    <VerificationSuccess />
  );
};
