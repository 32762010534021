import { Meeting, MeetingBody, MeetingsApi } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { MEETINGS } from './query-keys';

const meetingsApi = new MeetingsApi(openApiConfig());

const invalidateMeetings = () => {
  queryClient.invalidateQueries({ queryKey: [MEETINGS] });
};

export const useGetMeetings = (fundId: string) => {
  return useQuery<Meeting[] | null, unknown>(
    [MEETINGS, fundId],
    async () => {
      const result = await meetingsApi.getMeetings(fundId);
      return result.data;
    },
    { enabled: Boolean(fundId) },
  );
};

export const useCreateMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; body: MeetingBody }) =>
      meetingsApi.createMeeting(input.fundId, input.body),
    {
      onSettled: () => invalidateMeetings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been added' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding meeting',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; meetingId: string; body: MeetingBody }) =>
      meetingsApi.updateMeeting(input.fundId, input.meetingId, input.body),
    {
      onSettled: () => invalidateMeetings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been updated' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error updating meeting',
        });
      },
    },
  );

  return mutation;
};

export const useDeleteMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();

  return useMutation(
    ({ fundId, meetingId }: { fundId: string; meetingId: string }) =>
      meetingsApi.deleteMeeting(fundId, meetingId),
    {
      onSettled: () => invalidateMeetings(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been deleted' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error deleting meeting',
        });
      },
    },
  );
};
