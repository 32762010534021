import { SignificantChangesApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { SIGNIFICANT_CHANGES } from '../watchlist-hooks/query-keys';

const useGetSignificantChangeForModal = (id: string) => {
  const significantChangesApi = new SignificantChangesApi(openApiConfig());
  return useQuery([SIGNIFICANT_CHANGES, id], async () => {
    const response =
      await significantChangesApi.getSignificantChangeForModal(id);
    return response.data;
  });
};

export default useGetSignificantChangeForModal;
