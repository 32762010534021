import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  BenchmarkType,
  LIMIT_FUNDS_FOR_CHARTING,
  MsTimePeriod,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import { RenderStandardDeviation } from '@/partials/RenderFundValues/RenderStandardDeviation';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

type RiskColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  dateRange: MsTimePeriod;
  sortDirection: PageQueryParametersSortDirectionEnum;
  onSelectAll: (isChecked: boolean) => void;
};

const getRiskMeasureData = (
  fund: Fund,
  benchmarkType: BenchmarkType,
  year: MsTimePeriod,
) => {
  if (fund) {
    const riskMeasuresDetailData = fund.riskMeasuresDetail?.find(
      r => r.timePeriod === year,
    );
    const relativeRiskMeasuresDetailData =
      fund.relativeRiskMeasuresDetail?.find(
        r => r.timePeriod === year && r.benchmarkType === benchmarkType,
      );

    const maximumDrawdown = riskMeasuresDetailData?.maximumDrawdown;

    return {
      riskMeasuresDetail: riskMeasuresDetailData,
      relativeRiskMeasuresDetail: relativeRiskMeasuresDetailData,
      maximumDrawdown: maximumDrawdown,
    };
  }
  return {};
};

const RiskColumns = ({
  rowSelectionState,
  dateRange,
  onSelectAll,
}: RiskColumnsProps): IDataTableColumns[] => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();
  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (fund: Fund) => {
        return (
          <div className="flex items-center">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={fund._id}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(fund._id)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(fund._id)
                }
              />
            </div>
            <div>
              <FundInvestmentTrustColumn
                data={{
                  id: fund._id,
                  name: fund.shareClassDetails.code,
                  type: fund.legalStructure,
                  assetClassCode: fund.assetClassCode,
                  fundId: fund.fundId,
                  legalStructure: fund.legalStructure,
                }}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      sortKey: 'shareClassDetails.code',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Beta',
      idSubText: 'beta',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.beta.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 80,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = relativeRisk?.relativeRiskMeasuresDetail?.beta;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Upside / Downside</span>,
      dataTestIdSuffix: 'Upside / Downside',
      idSubText: 'upside-downside',
      renderType: 'number',
      align: 'center',
      sortKey: 'upsideDownsideSortKey',
      width: 120,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValueUpside =
          relativeRisk?.relativeRiskMeasuresDetail?.captureRatioTypeOne;
        const riskValueDownside =
          relativeRisk?.relativeRiskMeasuresDetail?.captureRatioTypeTwo;
        const roundUpside = roundOrEmpty(riskValueUpside, 0, '%');
        const roundDownside = roundOrEmpty(riskValueDownside, 0, '%');
        return `${roundUpside} / ${roundDownside}`;
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Max. Drawdown</span>,
      dataTestIdSuffix: 'Max. Drawdown',
      idSubText: 'max-drawdown',
      renderType: 'number',
      align: 'center',
      width: 100,
      sortKey: `scatterPointRiskMeasuresDetails.maximumDrawdown.${dateRange}`,
      tooltipText: (
        <span className="text-xs md:whitespace-nowrap">
          Based off NAV for investment trusts
        </span>
      ),
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.maximumDrawdown;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Batting Avg.',
      idSubText: 'batting-average',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.battingAverage.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue =
          relativeRisk?.relativeRiskMeasuresDetail?.battingAverage;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Std. Deviation</span>,
      dataTestIdSuffix: 'Std. Deviation',
      idSubText: 'standard-deviation',
      renderType: 'number',
      align: 'center',
      sortKey: `computedNavRiskDetails.standardDeviation.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        return <RenderStandardDeviation fund={fund} timePeriod={dateRange} />;
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sharpe',
      idSubText: 'sharpe',
      renderType: 'number',
      align: 'center',
      sortKey: `riskMeasuresDetail.sharpeRatio.${dateRange}`,
      width: 70,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.riskMeasuresDetail?.sharpeRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sortino',
      idSubText: 'sortino',
      renderType: 'number',
      align: 'center',
      sortKey: `riskMeasuresDetail.sortinoRatio.${dateRange}`,
      width: 70,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.riskMeasuresDetail?.sortinoRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Info Ratio',
      idSubText: 'info-ratio',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.informationRatio.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.informationRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Tracking Error</span>,
      dataTestIdSuffix: 'Tracking Error',
      idSubText: 'tracking-error',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.trackingError.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 90,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.trackingError;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Correlation',
      idSubText: 'correlation',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.correlation.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.correlation;
        const rickValueOver100 = riskValue && riskValue / 100;
        return roundOrEmpty(rickValueOver100, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
  ];
};

export default RiskColumns;
