import { useEffect } from 'react';

/**
 * This hook is used to change the theme color of the PWA
 */

const useChangePWATheme = () => {
  // Change the theme color of the PWA after loading is complete
  useEffect(() => {
    const themeColorElem = document.querySelector('meta[name="theme-color"]');
    const statusBarElem = document.querySelector(
      'meta[name="apple-mobile-web-app-status-bar-style"]',
    );
    themeColorElem?.setAttribute('content', '#ffffff');
    statusBarElem?.setAttribute('content', '#ffffff');
  }, []);

  // Attach event listeners to change the theme color of the PWA
  // based on the button clicked in the JSD widget
  useEffect(() => {
    const attachListeners = (iframeDocument: Document) => {
      const handleChangeColor = (selector: string, color: string) => {
        iframeDocument
          .querySelector(selector)
          ?.addEventListener('click', () => {
            const themeColorElem = document.querySelector(
              'meta[name="theme-color"]',
            );
            const statusBarElem = document.querySelector(
              'meta[name="apple-mobile-web-app-status-bar-style"]',
            );
            themeColorElem?.setAttribute('content', color);
            statusBarElem?.setAttribute('content', color);
          });
      };

      const findButton = (selector: string) => {
        const button = iframeDocument.querySelector(selector);
        if (button) {
          (button as HTMLElement).style.display = 'none';
        }
      };

      const observer = new MutationObserver(() => {
        handleChangeColor('#help-button', '#0072E6');
        handleChangeColor('button[aria-label="Dismiss"]', '#FFFFFF');
        findButton('#button-container'); // Target the container because it is an invisible div
      });

      observer.observe(iframeDocument.body, { childList: true, subtree: true });
    };

    const jsdWidgetObserver = new MutationObserver(() => {
      const widgetIframe = document.querySelector(
        'iframe#jsd-widget',
      ) as HTMLIFrameElement;

      if (widgetIframe?.contentDocument) {
        const iframeDocument = widgetIframe.contentDocument;
        if (iframeDocument) {
          attachListeners(iframeDocument);
          jsdWidgetObserver.disconnect();
        }
      }
    });

    jsdWidgetObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => jsdWidgetObserver.disconnect();
  }, []);
};

export default useChangePWATheme;
