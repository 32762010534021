import { AnalystBody, AnalystsApi, AnalystsRecord } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { ANALYSTS } from './query-keys';

const analystsApi = new AnalystsApi(openApiConfig());

const invalidateAnalysts = () => {
  queryClient.invalidateQueries({ queryKey: [ANALYSTS] });
};

export const useGetAnalysts = (fundId: string) => {
  return useQuery<AnalystsRecord | null, unknown>(
    [ANALYSTS, fundId],
    async () => {
      const result = await analystsApi.getAnalysts(fundId);
      return result.data;
    },
    { enabled: Boolean(fundId) },
  );
};

export const useCreateAnalysts = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; body: AnalystBody }) =>
      analystsApi.createAnalysts(input.fundId, input.body),
    {
      onSettled: () => invalidateAnalysts(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Analysts has been added' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding analysts',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateAnalysts = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; analystsId: string; body: AnalystBody }) =>
      analystsApi.updateAnalysts(input.fundId, input.analystsId, input.body),
    {
      onSettled: () => invalidateAnalysts(),
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding analysts',
        });
      },
    },
  );
  return mutation;
};
