import {
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  MS_DATE_FORMAT,
} from '@aminsights/shared';
import { ArrowRightOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DatePicker, Dropdown, Menu } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Arrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import { rollbackDayIfWeekend } from '@/partials/Charts/utils/chart-data';
import NestedDrawer from '@/partials/NestedDrawer';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import style from '../style.module.less';

const { RangePicker } = DatePicker;
export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof RangePicker>['onChange']>
>[0];
export interface DatePickerValue {
  range?: RangeValue;
  mode: EDateFilterValues;
}

export const parseDateValue = (opt: EDateFilterValues): RangeValue => {
  const endDate = dayjs().subtract(1, 'days');
  let startDate = endDate;
  switch (opt) {
    case EDateFilterValues['7D']:
      startDate = dayjs().subtract(7, 'day');
      break;
    case EDateFilterValues['30D']:
      startDate = dayjs().subtract(30, 'day');
      break;
    case EDateFilterValues.YTD:
      startDate = dayjs(`12-31-${dayjs().get('year') - 1}`, 'MM-DD-YYYY');
      break;
    case EDateFilterValues['1YR']:
      startDate = dayjs().subtract(1, 'year');
      break;
    case EDateFilterValues['3YR']:
      startDate = dayjs().subtract(3, 'year');
      break;
    case EDateFilterValues['5YR']:
      startDate = dayjs().subtract(5, 'year');
      break;
  }

  const result: RangeValue = [startDate, endDate];
  return result;
};

export const parseRangeValues = (
  rangeValue: RangeValue,
  mode?: EDateFilterValues,
  format = MS_DATE_FORMAT,
) => {
  let range = rangeValue;
  if (!!mode && mode !== EDateFilterValues.YTD) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    range = [rangeValue![0]!.subtract(1, 'days'), rangeValue![1]!];
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  range = [rollbackDayIfWeekend(range![0]!), range![1]!];
  return (range || [dayjs(), dayjs()]).map(d => {
    if (d) {
      return d.format(format);
    }
    return dayjs().format(format);
  });
};

interface Props {
  value?: DatePickerValue;
  onChange?: (value: RangeValue, mode: EDateFilterValues) => void;
  defaultValue?: DatePickerValue;
  disabled?: boolean;
  btnClassName?: string;
  loading?: boolean;
  dataTestId?: string;
}

const RangeDatePicker: React.FCWithChild<Props> = ({
  value,
  onChange,
  defaultValue = { mode: EDateFilterValues['30D'] },
  disabled,
  btnClassName,
  loading,
  dataTestId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dateVisible, setDateVisible] = useState(false);
  const [mobileDropdownDrawerVisible, setMobileDropdownDrawerVisible] =
    useState(false);
  const [rangeValues, setRangeValues] = useState<RangeValue>(
    defaultValue?.range || [dayjs(), dayjs()],
  );

  const [selectedMenu, setSelectedMenu] = useState<EDateFilterValues[]>([]);
  const containerRef = useRef(null);
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const handleClosePicker = () => {
    setIsVisible(false);
    setMobileDropdownDrawerVisible(false);
  };
  const handleCloseMobilePicker = () => {
    setMobileDropdownDrawerVisible(false);
  };
  const handleMenuChange = (val: EDateFilterValues) => {
    setSelectedMenu([val]);
    if (val !== EDateFilterValues.CUSTOM) {
      onChange?.(parseDateValue(val), val);
      handleClosePicker();
      setMobileDropdownDrawerVisible(false);
    }
  };
  const handleMenuClick = ({ key }: { key: string }) => {
    handleMenuChange(key as EDateFilterValues);
    if (key === EDateFilterValues.CUSTOM && isMobile) {
      setMobileDropdownDrawerVisible(false);
      handleClosePicker();
    }
  };
  const handleDatePickerChange = (value: RangeValue) => {
    setSelectedMenu([EDateFilterValues.CUSTOM]);
    setRangeValues(value);
  };
  const resetDefault = (): RangeValue => {
    if (
      defaultValue?.mode !== EDateFilterValues.CUSTOM &&
      !defaultValue?.range
    ) {
      const rangeValue = DATE_PERIOD_FILTER.find(
        opt => opt.value === defaultValue?.mode,
      )?.value;
      if (rangeValue) {
        return parseDateValue(rangeValue);
      }
    } else if (defaultValue?.range) {
      return defaultValue.range;
    }
    return null;
  };
  const handleClear = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onChange?.(resetDefault(), defaultValue.mode);
  };

  useEffect(() => {
    setDateVisible(isMobile ? mobileDropdownDrawerVisible : isVisible);
  }, [isVisible, mobileDropdownDrawerVisible, isMobile]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setRangeValues(resetDefault());
  }, []);

  useEffect(() => {
    if ((isVisible || mobileDropdownDrawerVisible) && value) {
      if (value.range) {
        setRangeValues(value.range);
      }
      setSelectedMenu([value.mode]);
    }
  }, [isVisible, value, mobileDropdownDrawerVisible]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    handleCloseMobilePicker();
    handleClosePicker();
  }, [isMobile]);

  const buttonText = () => {
    const currOpt = DATE_PERIOD_FILTER.find(opt => opt.value === value?.mode);
    if (currOpt?.value !== 'CUSTOM') {
      return (
        <>
          {currOpt?.label}{' '}
          <Arrow
            className={cx(style['dropdown-button-caret'], 'icon', {
              [style['dropdown-button-caret-up']]: isVisible,
            })}
          />
        </>
      );
    }
    if (value?.range?.[0] && value?.range?.[1]) {
      return (
        <>
          {value.range[0].format(MS_DATE_FORMAT)}{' '}
          <ArrowRightOutlined className="flex-1" />
          {` ${value.range[1].format(MS_DATE_FORMAT)}`}
          {!isVisible && (
            <CloseCircleOutlined
              className={style['dropdown-button-clear']}
              onClick={handleClear}
            />
          )}
        </>
      );
    }
    return '';
  };

  const DateRangePicker = (
    <div
      className={cx(
        '[&_.ant-picker-cell-in-view_.ant-picker-cell-inner]:text-neutral-700',
        '[&_.ant-picker-cell-disabled_.ant-picker-cell-inner]:!text-neutral-300',
        '[&_.ant-picker-cell-selected_.ant-picker-cell-inner]:!text-white',
      )}
    >
      <RangePicker
        open={dateVisible}
        getPopupContainer={() => {
          if (containerRef.current) {
            return containerRef.current;
          }
          return document.body;
        }}
        onCalendarChange={handleDatePickerChange}
        value={rangeValues}
        disabledDate={d => dayjs().diff(d, 'days') < 1}
      />
    </div>
  );
  const ApplyButton = (
    <Button
      type="primary"
      onClick={() => {
        if (rangeValues?.every(Boolean)) {
          onChange?.(rangeValues, selectedMenu[0]);
          handleClosePicker();
        }
      }}
      disabled={!rangeValues?.every(Boolean)}
    >
      Apply
    </Button>
  );

  const menuItems = DATE_PERIOD_FILTER.map(opt => ({
    label: opt.label,
    key: opt.value,
    selected: selectedMenu.includes(opt.value),
    onClick: () => {
      if (opt.value !== EDateFilterValues.CUSTOM) {
        handleMenuClick({ key: opt.value });
      }
    },
    childComponent:
      opt.value === EDateFilterValues.CUSTOM
        ? {
            title: 'Custom',
            content: (
              <>
                <div
                  className={style['dropdown-mobile-date-container']}
                  ref={containerRef}
                >
                  {DateRangePicker}
                </div>
              </>
            ),
            footer: (
              <div className="flex justify-between gap-x-2">
                <Button
                  type="text"
                  className="text-neutral-700 !px-4 !py-2"
                  onClick={handleCloseMobilePicker}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="!px-4 !py-2"
                  onClick={() => {
                    if (rangeValues?.every(Boolean)) {
                      onChange?.(rangeValues, selectedMenu[0]);
                      handleClosePicker();
                    }
                  }}
                  disabled={!rangeValues?.every(Boolean)}
                >
                  Apply
                </Button>
              </div>
            ),
          }
        : undefined,
  }));

  return (
    <>
      <Dropdown
        disabled={disabled || loading}
        open={isVisible}
        trigger={['click']}
        dropdownRender={() => {
          return !isMobile ? (
            <div className={cx(style['dropdown-container'])}>
              <Menu
                className={cx(style['dropdown-menu'])}
                selectedKeys={selectedMenu}
                items={menuItems}
              />
              {!isMobile && (
                <div className={cx(style['dropdown-inner-container'])}>
                  <div
                    ref={containerRef}
                    className={cx(style['dropdown-date-container'])}
                  />
                  <div className={cx(style['dropdown-footer'])}>
                    {DateRangePicker}
                    <div className={cx(style['dropdown-footer-buttons'])}>
                      <Button
                        type="default"
                        onClick={handleClosePicker}
                        className="border-none"
                      >
                        Cancel
                      </Button>
                      {ApplyButton}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          );
        }}
      >
        {loading ? (
          <SkeletonButton active className={cx(btnClassName, 'h-10')} />
        ) : (
          <Button
            onClick={() => {
              setIsVisible(!isMobile);
              setMobileDropdownDrawerVisible(isMobile);
            }}
            className={cx(
              style['dropdown-button'],
              isVisible && style['active'],
              btnClassName,
            )}
            data-test-id={dataTestId}
          >
            {buttonText()}
          </Button>
        )}
      </Dropdown>
      {isMobile && (
        <NestedDrawer
          menuItems={menuItems}
          visible={mobileDropdownDrawerVisible}
          onClose={handleCloseMobilePicker}
          title="Date"
        />
      )}
      {isVisible && (
        <div
          aria-label="Close dropdown"
          className={cx(style['dropdown-overlay'])}
          onClick={handleClosePicker}
          onKeyUp={handleClosePicker}
          tabIndex={0}
          role="button"
        />
      )}
    </>
  );
};

export default RangeDatePicker;
