export const getValueFromMarks = (
  values: number[] | undefined,
  marks: Record<number, string>,
  maxNumbers?: [number | undefined, number | undefined],
) => {
  if (!values) return undefined;

  let min: number | string = marks[values[0]].replace(/[<+>%]/g, '');
  let max: number | string = marks[values[1]].replace(/[<+>%]/g, '');

  if (values[0] === maxNumbers?.[0]) {
    min = Number.MIN_SAFE_INTEGER;
  }
  if (values[1] === maxNumbers?.[1]) {
    max = Number.MAX_SAFE_INTEGER;
  }
  return [+min, +max];
};

export const getMarksFromValue = (
  minMax: number[] | undefined,
  marks: Record<number, string>,
  maxNumbers?: [number | undefined, number | undefined],
) => {
  if (!minMax) return undefined;
  let [minValue, maxValue] = minMax;

  if (minValue === Number.MIN_SAFE_INTEGER) {
    minValue = maxNumbers?.[0] || 0;
  }
  if (maxValue === Number.MAX_SAFE_INTEGER) {
    maxValue = maxNumbers?.[1] || 0;
  }

  // Helper function to find the corresponding key
  const findKeyByValue = (value: number, marks: Record<number, string>) => {
    return Object.keys(marks).find(key => {
      const numericValue = Number.parseFloat(
        marks[+key].replace(/[<+>%]/g, ''),
      );
      return numericValue === value;
    });
  };

  const minKey = findKeyByValue(minValue, marks);
  const maxKey = findKeyByValue(maxValue, marks);

  return [Number(minKey), Number(maxKey)];
};
