import { Upload } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as UploadIcon } from '@/assets/svg/icons/icon-upload-funds.svg';

import { FailedStatus } from '../types';

const { Dragger } = Upload;

type Props = {
  onFundsUploadFailure: (failedStatus: FailedStatus) => void;
  onFundsUploadSuccess: (isins: string[], filenames: string[]) => void;
};

const UploadFunds: React.FCWithChild<Props> = ({
  onFundsUploadFailure,
  onFundsUploadSuccess,
}) => {
  const [filenames, setFileNames] = useState<string[] | undefined>();

  const handleUploadChange = (info: any) => {
    const fileList = info.fileList.slice(-1);
    setFileNames(fileList.map((file: any) => file.name));
    if (fileList.length === 0) {
      onFundsUploadSuccess([], []);
    }
  };

  const handleFileRemove = (file: any) => {
    const newFileNames = filenames?.filter(v => v !== file.name);
    setFileNames(newFileNames);
  };

  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target?.result) {
        onFundsUploadSuccess(
          e.target.result
            .toString()
            .split('\n')
            .map((isin: string) => isin.trim())
            .filter(isin => isin),
          filenames || [],
        );
      } else {
        onFundsUploadFailure(FailedStatus.FILE_EMPTY);
      }
    };
    reader.readAsText(file);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onChange: handleUploadChange,
    accept: '.csv',
    beforeUpload: handleBeforeUpload,
    onRemove: handleFileRemove,
  };

  return (
    <div
      className={cx(
        'flex flex-col justify-center w-full gap-y-4',
        'bg-white lg:rounded-lg',
      )}
    >
      <div className="flex flex-col gap-y-2">
        <h2 className="text-sm font-bold text-darkest">Import funds/trusts</h2>
        <p className="text-neutral-700 font-normal text-sm">
          Add multiple funds/trust to your watchlist at the same time by
          uploading a csv file. You may also add a single fund/trust by
          searching from our database.
        </p>
      </div>

      <Dragger {...uploadProps}>
        <div
          className={cx(
            'flex items-center flex-col text-center gap-y-2 py-12',
            'cursor-pointer relative h-full rounded-lg max-md:px-2',
          )}
        >
          <div className="flex gap-x-2 justify-center items-center">
            <UploadIcon />
            <p className="text-primary cursor-pointer underline">
              Browse files
            </p>
          </div>
          <p className="text-neutral-700 font-normal text-sm">
            or drag and drop files here
          </p>
        </div>
      </Dragger>

      <a
        className="underline text-primary text-center"
        href={'/assets/file/sample_template.csv'}
        download
      >
        View sample template
      </a>
    </div>
  );
};

export default UploadFunds;
