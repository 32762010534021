import { RiskDetailsTypeEnum } from '@aminsights/contract';
import { RipsReturnType } from '@aminsights/shared';
import { createDraft, finishDraft } from 'immer';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export enum InvestmentTrustReturnType {
  SharePrice = 'Share Price',
  NAV = 'NAV',
}

interface ChartSettingsFilter {
  investmentTrustsReturns: InvestmentTrustReturnType;
  excludeRecentLaunches: 'Exclude' | 'Include';
}

const initialChartSettingsFilter: ChartSettingsFilter = {
  investmentTrustsReturns: InvestmentTrustReturnType.SharePrice,
  excludeRecentLaunches: 'Include',
};

interface ChartSettingsState {
  chartSettingsFilter: ChartSettingsFilter;
  tentativeChartSettingsFilter: ChartSettingsFilter;

  resetChartSettingsFilter: () => void;
  applyChartSettingsFilter: () => void;
  getMutableChartSettingsFilter: () => ChartSettingsFilter;
  updateTentativeChartSettingsFilter: (filter: ChartSettingsFilter) => void;
}

export const useChartSettingsState = create<ChartSettingsState>()(
  immer((set, get) => ({
    chartSettingsFilter: initialChartSettingsFilter,
    tentativeChartSettingsFilter: initialChartSettingsFilter,

    resetChartSettingsFilter: () => {
      set(state => {
        state.chartSettingsFilter = initialChartSettingsFilter;
        state.tentativeChartSettingsFilter = initialChartSettingsFilter;
      });
    },

    applyChartSettingsFilter: () => {
      set(state => {
        state.chartSettingsFilter = state.tentativeChartSettingsFilter;
      });
    },

    getMutableChartSettingsFilter: () =>
      createDraft(get().tentativeChartSettingsFilter),

    updateTentativeChartSettingsFilter: (filter: ChartSettingsFilter) => {
      set(state => {
        const finishedDraft = finishDraft(filter);
        state.tentativeChartSettingsFilter = finishedDraft;
      });
    },
  })),
);

export const getInvestmentTrustReturnType = (
  investmentTrustsReturns: InvestmentTrustReturnType,
): RipsReturnType => {
  return investmentTrustsReturns === InvestmentTrustReturnType.NAV
    ? RipsReturnType.TotalReturn
    : RipsReturnType.SharePrice;
};

export const getInvestmentTrustRiskDetailsType = (
  investmentTrustsReturns: InvestmentTrustReturnType,
): RiskDetailsTypeEnum => {
  return investmentTrustsReturns === InvestmentTrustReturnType.NAV
    ? RiskDetailsTypeEnum.FUND
    : RiskDetailsTypeEnum.INVESTMENT_TRUST;
};
