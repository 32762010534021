import {
  RequiredError,
  SettingsApi,
  SignificantChangesThreshold,
} from '@aminsights/contract';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { openApiConfig } from '@/utils';

const useSaveSignificantChangesThreshold = (
  options: UseMutationOptions<
    AxiosResponse<SignificantChangesThreshold>,
    RequiredError,
    SignificantChangesThreshold
  >,
) => {
  const settingsApi = new SettingsApi(openApiConfig());

  return useMutation<
    AxiosResponse<SignificantChangesThreshold>,
    RequiredError,
    SignificantChangesThreshold
  >(
    async (threshold: SignificantChangesThreshold) => {
      return await settingsApi.saveSignificantChangesThreshold(threshold);
    },

    {
      ...options,
    },
  );
};

export default useSaveSignificantChangesThreshold;
