import { Input, InputRef } from 'antd';
import cx from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';

import useProvideSCSearch from './useProvideSCSearch';

type SearchProps = {
  searchClassName?: string;
  placeholder?: string;
  searchIconClassName?: string;
  isClear?: boolean;
  autofocusDeps?: any[];
};

const Search = forwardRef<InputRef, SearchProps>(
  (
    {
      searchClassName,
      placeholder = 'Press Enter to search',
      searchIconClassName,
      isClear,
      autofocusDeps,
    },
    forwardedRef,
  ) => {
    const [term, setTerm] = useState('');

    const { state, setSearchTerm } = useProvideSCSearch();
    const { currentWidth } = useScreenWidth();
    const isMobile = currentWidth < screenBreakpoints.md;
    const inputRef = useAutoFocusRef<InputRef>(autofocusDeps, !isMobile);
    useImperativeHandle(forwardedRef, () => inputRef.current!);

    useEffect(() => {
      setTerm(state.searchTerm ?? '');
    }, [state.searchTerm]);
    // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
    useEffect(() => {
      if (isClear) {
        setTerm('');
        setSearchTerm('');
      }
    }, [isClear]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTerm(e.target.value);
    };

    const onBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
      if (state.searchTerm !== term) {
        setSearchTerm(term);
      }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setSearchTerm(term);
      }
    };

    return (
      <div className="relative w-full flex">
        <Input
          ref={inputRef}
          autoFocus={!isMobile}
          className={cx(
            'border border-neutral-300 outline-none w-full rounded !pr-10 !pl-12 focus:border-primary h-10 placeholder:!text-neutral-100',
            searchClassName,
          )}
          type="search"
          value={term}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          id="search-dropdown"
        />
        <IconSearch
          className={cx(
            'icon text-neutral-100 left-5 absolute self-center',
            searchIconClassName,
          )}
        />
      </div>
    );
  },
);

export default Search;
