import { Button } from 'antd';
import React, { useState } from 'react';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconEmptyBucket } from '@/assets/svg/icons/icon-empty-bucket.svg';
import { STATIC_DATA_ADD_BUCKET_MODAL } from '@/constants/modals-static-data';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useCreateNewBucket } from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AddEntityWithBenchmarkModal from '@/partials/Modal/AddEntityWithBenchmarkModal';

const EmptyBucketState: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();

  const createNewBucket = useCreateNewBucket();

  const [isAddNewBucketModalOpen, setIsAddNewBucketModalOpen] = useState(false);

  const handleAddNewBucketModalOpen = () => {
    setIsAddNewBucketModalOpen(prev => !prev);
  };
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const onSaveClick = async (
    name: string,
    benchmarkId?: string,
    sectorId?: string,
  ) => {
    const benchmarkName =
      benchmarkOptionsResponse?.find(b => b.secId === benchmarkId)?.name ?? '';
    await createNewBucket.mutateAsync({
      funds: [],
      name,
      index: benchmarkName,
      indexSecId: benchmarkId || '',
      sector: sectorId,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-y-1 text-center p-5 py-16 md:py-32">
      <IconEmptyBucket />
      {
        <>
          <h3 className="text-lg font-semibold text-neutral-900 mt-7">
            You haven't created any buckets yet.
          </h3>
          <p className="text-sm font-normal text-neutral-450">
            Add your first bucket to start building your watchlist and
            personalise your dashboard.
          </p>
          <Button
            className="mt-2"
            type="primary"
            size="large"
            icon={<AddIcon className="h-3.5 fill-current" />}
            onClick={() => {
              setIsAddNewBucketModalOpen(true);
            }}
          >
            Add Bucket
          </Button>
        </>
      }
      <AddEntityWithBenchmarkModal
        entities={currentWatchlist.data?.buckets ?? []}
        isVisible={isAddNewBucketModalOpen}
        toggleModal={handleAddNewBucketModalOpen}
        onSaveClick={onSaveClick}
        staticModalData={STATIC_DATA_ADD_BUCKET_MODAL}
      />
    </div>
  );
};

export default EmptyBucketState;
