import { Skeleton } from 'antd';

const SkeletonLoader = () => {
  return (
    <div className="p-4 pb-6 bg-white rounded-lg">
      <div className="flex justify-between gap-2 mb-5">
        <Skeleton.Button className="w-16 h-10" active />
        <Skeleton.Button className="w-30 h-10" active />
      </div>

      <div className="flex flex-col gap-2">
        {Array.from(new Array(3), (_, i) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: Index is stable as array length is fixed
            key={`skeleton-loader-${i}`}
            className="border-b border-grey-lightest pb-2 last:pb-0 last:border-b-0"
          >
            <Skeleton.Button block active />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
