import { FundPlatformsEnum, PortfolioFund } from '@aminsights/contract';
import {
  COLOR_MINIMUM_TABLE_CELL,
  EMPTY_DATA_POINT,
  PLATFORMS_FILTERS,
  buildFundDetailsPath,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';

const getPlatformLabel = (
  type?: FundPlatformsEnum,
  platforms?: FundPlatformsEnum[],
) => {
  if (!type || !platforms?.length) return 'No';
  return platforms?.includes(type) ? 'Yes' : 'No';
};

const PlatformColumns = (): IDataTableColumns[] => [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'fundName',
    render: (item: PortfolioFund) => {
      const isSomePlatformsMissing = PLATFORMS_FILTERS.some(
        f => getPlatformLabel(f.key, item.platforms) === 'No',
      );
      return !!item.isin && !!item.fundName ? (
        <Link
          onClick={e => e.preventDefault()}
          to={buildFundDetailsPath(item.isin)}
          style={{ color: isSomePlatformsMissing ? '#CF3B31' : '' }}
        >
          {item.fundName}
        </Link>
      ) : (
        EMPTY_DATA_POINT
      );
    },
    renderType: 'text',
    isColumnFixed: true,
    minWidth: 195,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  ...PLATFORMS_FILTERS.map(f => ({
    title: f.label,
    sortKey: f.key,
    renderType: 'text' as const,
    width: 195,
    backgroundColor: (item: PortfolioFund) => {
      const label = getPlatformLabel(f.key, item.platforms);
      if (label === 'No') return COLOR_MINIMUM_TABLE_CELL;
      return '';
    },
    render: (item: PortfolioFund): string => {
      return getPlatformLabel(f.key, item.platforms);
    },
  })),
];

export default PlatformColumns;
