import React, { useEffect, useState } from 'react';

import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { countEsgFilters } from '../hooks/esgSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { ExploreModalProps } from '../types';
import EsgActFilter from './IndividualFilters/EsgFilters/EsgActFilter';
import EsgActionSurveyRatingsFilter from './IndividualFilters/EsgFilters/EsgActionSurveyRatingsFilter';
import EsgF4BFilter from './IndividualFilters/EsgFilters/EsgF4BFilter';
import SfdrEsgFilter from './IndividualFilters/EsgFilters/EsgSfdrFilter';
import EsgTcfdFilter from './IndividualFilters/EsgFilters/EsgTcfdFilter';
import EsgUnpriFilter from './IndividualFilters/EsgFilters/EsgUnpriFilter';
import EsgYesNoFilter from './IndividualFilters/EsgFilters/EsgYesNoFilter';

export const EsgFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    syncFilterByPath,
    filters,
    tentativeFilters,
    getMutableFilters,
    updateTentativeFilters,
  } = useExploreFilters();

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.esgFilters) ===
        JSON.stringify(filters.esgFilters),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(
      applyDisabled && countEsgFilters(filters.esgFilters) === 0,
    );
  });

  const modalEsgProviderItemStyle =
    'pb-6 px-6 first:pt-0 pt-6 last:border-0 border-b border-midLight [&_*_.ant-checkbox-group]:!p-0 [&_*_.ant-checkbox-group-item]:!p-0 [&_*_.ant-checkbox-group-item]:!leading-[0]';

  const h6LabelStyle = 'text-darkest text-sm font-medium leading-8 mb-2';

  return (
    <ExploreFilterModal
      title="ESG"
      isVisible={isOpen}
      isDisableApply={applyDisabled}
      isDisableClear={clearDisabled}
      toggleModal={onClose}
      onClear={() => {
        const f = getMutableFilters();
        f.esgFilters = undefined;
        updateTentativeFilters(f);
      }}
      onApply={() => {
        syncFilterByPath('esgFilters');
        onClose();
      }}
    >
      <div className="modal-esg-provider">
        <div className={modalEsgProviderItemStyle} data-test-id="ESGLabelSFDR">
          <h6 className={h6LabelStyle}>SFDR (Fund Level)</h6>
          <SfdrEsgFilter />
        </div>
        <div
          className={modalEsgProviderItemStyle}
          data-test-id={'ESGLabelTCFD'}
        >
          <h6 className={h6LabelStyle}>
            Task Force on Climate-Related Financial Disclosures (TCFD)
          </h6>
          <EsgTcfdFilter />
        </div>
        <div
          className={modalEsgProviderItemStyle}
          data-test-id={'ESGLabelUNPRI'}
        >
          <h6 className={h6LabelStyle}>
            UN Principles for Responsible Investment (UN PRI)
          </h6>
          <EsgUnpriFilter />
        </div>
        <div
          className={modalEsgProviderItemStyle}
          data-test-id={'ESGLabelNetZero'}
        >
          <h6 className={h6LabelStyle}>Net Zero Asset Managers (Net Zero)</h6>
          <EsgYesNoFilter filterKey="netZeroAssetManager" />
        </div>
        <div
          className={modalEsgProviderItemStyle}
          data-test-id={'ESGLabelUKSC'}
        >
          <h6 className={h6LabelStyle}>Stewardship Code (UK SC)</h6>
          <EsgYesNoFilter filterKey="stewardshipCode" />
        </div>
        <div className={modalEsgProviderItemStyle} data-test-id={'ESGLabelF4B'}>
          <h6 className={h6LabelStyle}>Finance for Biodiversity (F4B)</h6>
          <EsgF4BFilter />
        </div>
        <div className={modalEsgProviderItemStyle} data-test-id={'ESGLabelACT'}>
          <h6 className={h6LabelStyle}>ACT</h6>
          <EsgActFilter />
        </div>
        <div
          className={modalEsgProviderItemStyle}
          data-test-id={'ESGLabelShareActionRatings'}
        >
          <h6 className={h6LabelStyle}>Share Action Ratings</h6>
          <EsgActionSurveyRatingsFilter />
        </div>
      </div>
    </ExploreFilterModal>
  );
};
