import {} from '@aminsights/contract';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('GMT');

/**
 * In the context of finance this is also known as
 * TenorCode.
 *
 * Replace MsTimePeriod with this one someday and delete MsTimePeriod.
 */
export enum MonthCode {
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  M7 = 'M7',
  M8 = 'M8',
  M9 = 'M9',
  M10 = 'M10',
  M11 = 'M11',
  M12 = 'M12',
  M24 = 'M24',
  M36 = 'M36',
  M48 = 'M48',
  M60 = 'M60',
}

export const getMonthCodeByMonthCount = (
  months: number,
): MonthCode | undefined => {
  const absMonths = Math.abs(months);
  if (absMonths === 60) return MonthCode.M60;
  if (absMonths === 48) return MonthCode.M48;
  if (absMonths === 36) return MonthCode.M36;
  if (absMonths === 24) return MonthCode.M24;
  if (absMonths === 12) return MonthCode.M12;
  if (absMonths === 11) return MonthCode.M11;
  if (absMonths === 10) return MonthCode.M10;
  if (absMonths === 9) return MonthCode.M9;
  if (absMonths === 8) return MonthCode.M8;
  if (absMonths === 7) return MonthCode.M7;
  if (absMonths === 6) return MonthCode.M6;
  if (absMonths === 5) return MonthCode.M5;
  if (absMonths === 4) return MonthCode.M4;
  if (absMonths === 3) return MonthCode.M3;
  if (absMonths === 2) return MonthCode.M2;
  if (absMonths === 1) return MonthCode.M1;

  return undefined;
};

export const getYtdMonthCode = (today: Dayjs) => {
  const isLastDayOfMonth = today.date() === today.daysInMonth();
  const currentMonth = today.month(); // 0-based (January is 0)

  // If it's not the last day of the month, use previous month's count
  const completedMonths = isLastDayOfMonth ? currentMonth + 1 : currentMonth;

  return completedMonths > 0
    ? getMonthCodeByMonthCount(completedMonths)
    : undefined;
};

/**
 * This function looks for the correct YTD given the details.
 * The reason this function is important because just because we know
 * what the YTD MonthCode is it doesn't mean it has been computed for
 * that month.
 *
 * For example, if today is December 3, technically YTD = M11 because
 * November has completely passed. However, if the risk details is delayed
 * due to RIPS availability, we cannot use M11 because it's `asOfDate` will
 * likely be October 31st (M10). To avoid this we will look for the MonthCode that
 * matches the `asOfDate` that is closest to the actual YTD. In the first example
 * if today is December 3rd, but all details are `asOfDate: 2024-10-31`, then we should
 * consider YTD as M10 because that is what is available. Very important to note
 * is that this is sound only because all details are calculated from RIPS, which will
 * always include the latest available date to calculate the value, and said latest date
 * is stored in `asOfDate`.
 *
 * @param detail: any array of objects that have `asOfDate` and `timePeriod`
 */
export const findYtdDetail = <
  T extends { asOfDate: string; timePeriod: MonthCode | string },
>(
  details?: T[],
): T | undefined => {
  if (!details?.length) return undefined;

  // Get the asOfDate from the first detail (they should all be the same)
  const asOfDate = dayjs(details[0].asOfDate);

  // Calculate what the YTD should be based on the asOfDate
  const ytdMonthCode = getYtdMonthCode(asOfDate);

  if (!ytdMonthCode) return undefined;

  // Find the detail with the matching month code or the closest available one
  const availableMonths = details
    .map(d => Number.parseInt(d.timePeriod.substring(1)))
    .filter(months => months <= Number.parseInt(ytdMonthCode.substring(1)));

  if (!availableMonths.length) return undefined;

  // Get the highest available month that doesn't exceed YTD
  const maxAvailableMonth = Math.max(...availableMonths);
  const targetMonthCode = `M${maxAvailableMonth}` as MonthCode;

  return details.find(d => d.timePeriod === targetMonthCode);
};
