import { RatingRecord, RatingScale } from '@aminsights/contract';
import { Button, Dropdown } from 'antd';
import dayjs from 'dayjs';
import { FC, useContext, useMemo, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useDeleteRatings,
  useUpdateRatings,
} from '@/hooks/query-hooks/research-hooks/ratings-hooks';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { validatePermissions } from '@/utils/auth';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { RatingModal } from './RatingModal';
import { RatingOption } from './ratingOptions';

interface RatingListingProps {
  rating: RatingRecord;
  onToggleNestedModal?: () => void;
}

export const RatingListing: FC<RatingListingProps> = ({
  rating: item,
  onToggleNestedModal,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { data: usersData } = useOrganizationUsers();
  const user = usersData?.find(user => user.user_id === item.createdBy);
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const [editRatingIsOpen, setEditRatingIsOpen] = useState(false);

  const { mutate: updateRatings } = useUpdateRatings();
  const { mutate: deleteRatings } = useDeleteRatings();

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxSm];

  const handleToggleEditModal = () => {
    onToggleNestedModal?.();
    setEditRatingIsOpen(!editRatingIsOpen);
  };

  const handleToggleDeleteModal = () => {
    onToggleNestedModal?.();
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const menuItems = [
    {
      label: 'Edit',
      key: 'edit',
      onClick: handleToggleEditModal,
    },
    {
      label: 'Delete',
      danger: true,
      key: 'delete',
      onClick: handleToggleDeleteModal,
    },
  ];

  const hasAdminPermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.admin],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  return (
    <>
      <div className="border-b border-grey-lightest pb-2 mb-2 grid grid-cols-[1fr_min-content]">
        <div>
          <p className="text-neutral-700">{item.rating}</p>
          <p className="text-neutral-500 text-xs">
            {user?.name}, {dayjs(item._createdAt).format('MMM YYYY')}
          </p>
        </div>
        {((item.createdBy === currentUser.sub && hasWritePermissions) ||
          hasAdminPermissions) && (
          <Dropdown
            placement="bottomRight"
            trigger={['click']}
            menu={{
              className:
                'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
              items: !isMobile ? menuItems : [],
            }}
            onOpenChange={() => {
              setIsMenuOpen(true);
            }}
          >
            <Button
              className="border-0"
              size="large"
              type="text"
              icon={<MeatballMenu width={16} height={16} />}
            />
          </Dropdown>
        )}
        {isMobile && (
          <NestedDrawer
            visible={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            menuItems={menuItems}
            destroyOnClose
          />
        )}
      </div>

      <RatingModal
        open={editRatingIsOpen}
        onCancel={handleToggleEditModal}
        defaultRating={item.rating as RatingOption}
        onSave={rating => {
          updateRatings({
            fundId: item.fundId,
            ratingId: item._id,
            body: {
              fundId: item.fundId,
              rating,
              scale: RatingScale.Detailed,
            },
          });
          setEditRatingIsOpen(false);
        }}
        destroyOnClose={true}
      />

      <ConfirmationModalDanger
        modalInfo={{
          title: `Delete '${item.rating}' Rating?`,
          description: 'This action cannot be undone.',
          primaryActionLabel: 'Delete',
          succesMessage: 'Rating has been deleted',
          errorMessage: 'Unable to delete Rating',
        }}
        isVisible={isDeleteModalOpen}
        toggleModal={handleToggleDeleteModal}
        onConfirm={() => {
          deleteRatings({
            fundId: item.fundId,
            ratingId: item._id,
          });
        }}
        destroyOnClose
      />
    </>
  );
};
