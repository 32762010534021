import { Meeting } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC, useContext, useMemo, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import NestedDrawer from '@/partials/NestedDrawer';
import { validatePermissions } from '@/utils/auth';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { composeAttendeesList } from './utils';

interface MeetingsListItemProps {
  meeting: Meeting;
  onEditMeeting: (meeting: Meeting) => void;
  onDeleteMeeting: (meeting: Meeting) => void;
  onShowMeetingDetail: (meeting: Meeting) => void;
}

const MeetingsListItem: FC<MeetingsListItemProps> = ({
  meeting,
  onEditMeeting,
  onDeleteMeeting,
  onShowMeetingDetail,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const hasAdminPermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.admin],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const userActionsList = [
    {
      key: 'edit',
      label: 'Edit',
      onClick: () => onEditMeeting(meeting),
    },
    {
      key: 'delete',
      danger: true,
      label: 'Delete',
      onClick: () => onDeleteMeeting(meeting),
    },
  ];

  return (
    <div className="flex gap-2 py-2 border-b border-b-grey-50">
      <div
        className="flex flex-col flex-1 cursor-pointer"
        onClick={() => onShowMeetingDetail(meeting)}
      >
        <p className="text-sm font-normal text-neutral-700">
          {dayjs(meeting.date).format(DISPLAY_DATE_FORMAT)}
        </p>
        <p className="text-xs text-neutral font-normal">
          {composeAttendeesList(meeting.attendees)}
        </p>
      </div>
      {((meeting.createdBy === currentUser.sub && hasWritePermissions) ||
        hasAdminPermissions) && (
        <>
          <Dropdown
            open={isVisible && !isMobile}
            menu={{
              className:
                'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
              items: isMobile ? [] : userActionsList,
            }}
            className={cx('w-8 h-8 p-2 rounded hover:bg-grey-200', {
              'bg-grey-200': isVisible || isNestedDrawerVisible,
            })}
            placement="bottomRight"
            trigger={['click']}
            onOpenChange={visible => setIsVisible(visible)}
          >
            <Button
              className="border-0"
              size="large"
              type="text"
              icon={<MeatballMenu fill="#3B3C53" width={16} height={16} />}
              onClick={() => isMobile && setIsNestedDrawerVisible(true)}
            />
          </Dropdown>
          {isMobile && (
            <NestedDrawer
              menuItems={userActionsList}
              visible={isNestedDrawerVisible}
              onClose={() => setIsNestedDrawerVisible(false)}
              title="Select"
            />
          )}
        </>
      )}
    </div>
  );
};

export default MeetingsListItem;
