import { Dropdown } from 'antd';
import { useState } from 'react';

import { ReactComponent as IconAction } from '@/assets/svg/icons/icon-action.svg';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import {
  useFundByIsInParam,
  useGenerateReport,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import notEmpty from '@/utils/notEmpty';

import CompareFundSelectModal from './CompareTool/CompareFundSelectModal';

const FundOptionsDropdown: React.FCWithChild = () => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const { state: featureSwitchState } = useFeatureSwitchContext();

  const { data: state } = useFundByIsInParam();

  const [showModal, setShowModal] = useState(false);

  const items: Array<{ label: string; onClick: () => void; key: string }> = [];

  const generateReport = useGenerateReport();
  if (featureSwitchState.isFundDetailsActionsTreatmentEnabled) {
    items.push({
      label: 'Run a report',
      onClick: () => {
        if (state?.fund?._id) {
          generateReport.mutateAsync({
            fundName: state.fund.shareClassDetails.code,
            isin: state.fund._id,
          });
        }
      },
      key: 'runReport',
    });
  }

  const optItems = items.filter(notEmpty);
  const hasItems = optItems.length > 0;
  if (!hasItems) return null; // Make sure the whole thing doesn't show if no features are enabled

  return (
    <div
      className="fund-details__head-actions"
      data-test-id="fundDetailsHeaderDropdownMenu"
    >
      <Dropdown
        menu={{ items: isMobile ? [] : items }}
        placement="bottomRight"
        trigger={['click']}
      >
        <div>
          <IconAction
            className="icon text-xl action-icon"
            onClick={() => isMobile && setIsNestedDrawerVisible(true)}
          />
        </div>
      </Dropdown>
      {state?.fund && (
        <CompareFundSelectModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          initialFundLeft={state?.fund.shareClassDetails}
        />
      )}
      {isMobile && (
        <NestedDrawer
          menuItems={items}
          visible={isNestedDrawerVisible}
          onClose={() => setIsNestedDrawerVisible(false)}
          title="Select"
        />
      )}
    </div>
  );
};

export default FundOptionsDropdown;
