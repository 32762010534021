import { Button, Popover } from 'antd';
import cx from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

interface BenchmarkItemActionsProps {
  onClickEditBenchmark: () => void;
  onClickDeleteBenchmark: () => void;
}

const BenchmarkItemActions: FC<BenchmarkItemActionsProps> = ({
  onClickEditBenchmark,
  onClickDeleteBenchmark,
}) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const menuItems = [
    {
      label: 'Edit',
      key: 'edit',
      onClick: onClickEditBenchmark,
      dataTestId: 'customBenchmarksEditButton',
    },
    {
      label: 'Delete',
      key: 'delete',
      onClick: onClickDeleteBenchmark,
      dataTestId: 'customBenchmarksDeleteButton',
    },
  ];
  return (
    <div className={style['benchmarks-list__actions']}>
      <Button
        type="link"
        className={style['benchmarks-list__delete']}
        onClick={e => {
          e.stopPropagation();
          onClickDeleteBenchmark();
        }}
        data-test-id="customBenchmarksDeleteButton"
      >
        Delete
      </Button>
      <Button
        type="link"
        className={style['benchmarks-list__edit']}
        onClick={e => {
          e.stopPropagation();
          onClickEditBenchmark();
        }}
        data-test-id="customBenchmarksEditButton"
      >
        Edit
      </Button>
      <Popover
        placement="bottomRight"
        trigger="click"
        getPopupContainer={triggerNode =>
          triggerNode.parentElement as HTMLElement
        }
        className="block md:hidden"
        align={{ offset: [5, -10] }}
        content={
          !isMobile && (
            <div className="w-32 flex flex-col items-start">
              {menuItems.map(item => {
                const isDangerItem = item.key === 'delete';
                return (
                  <Button
                    key={item.key}
                    type="link"
                    className={cx('p-0', !isDangerItem && 'text-neutral')}
                    onClick={e => {
                      e.stopPropagation();
                      item.onClick();
                    }}
                    danger={isDangerItem}
                    data-test-id={item.dataTestId}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </div>
          )
        }
      >
        <MeatballMenu
          className="p-1 h-6 rounded-full cursor-pointer"
          onClick={() => isMobile && setIsNestedDrawerVisible(true)}
        />
      </Popover>
      {isMobile && (
        <NestedDrawer
          menuItems={menuItems}
          visible={isNestedDrawerVisible}
          onClose={() => setIsNestedDrawerVisible(false)}
          title="Select"
        />
      )}
    </div>
  );
};

export default BenchmarkItemActions;
