import { AuthUser } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Avatar, List, Tooltip } from 'antd';
import cx from 'classnames';
import { FC, useContext, useMemo, useState } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/svg/icons/icon-close-light.svg';
import { ReactComponent as TeamIcon } from '@/assets/svg/research/team.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import { validatePermissions } from '@/utils/auth';

import EmptyState from '../EmptyState';

interface AnalystListingProps {
  analysts: AuthUser[];
  onShowAnalystForm: () => void;
  onRemoveAnalyst: (id: string, name: string) => void;
}

const AnalystListing: FC<AnalystListingProps> = ({
  analysts,
  onShowAnalystForm,
  onRemoveAnalyst,
}) => {
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAnalyst, setSelectedAnalyst] = useState<AuthUser | null>();
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const handleRemoveAnalyst = async () => {
    if (!selectedAnalyst) return;
    setIsLoading(true);
    await onRemoveAnalyst(selectedAnalyst.user_id, selectedAnalyst.name);
    setIsRemoveModalVisible(false);
    setSelectedAnalyst(null);
    setIsLoading(false);
  };

  return (
    <div>
      {!analysts.length ? (
        <EmptyState
          icon={<TeamIcon />}
          onClick={onShowAnalystForm}
          title="You haven’t assigned analyst to this fund"
          buttonLabel="Add an analyst"
          hideButton={!hasWritePermissions}
        />
      ) : (
        <List
          dataSource={analysts}
          itemLayout="horizontal"
          className="pb-4"
          renderItem={listItem => (
            <List.Item
              key={listItem.user_id}
              className="flex items-center gap-4 border-b-0 pb-0"
              actions={
                hasWritePermissions
                  ? [
                      <Tooltip
                        placement="bottom"
                        title="Remove"
                        color="#313341"
                        arrow={false}
                        key={listItem.user_id}
                      >
                        <div
                          className="h-6 w-6 flex justify-center items-center rounded cursor-pointer hover:bg-midLight"
                          onClick={() => {
                            setSelectedAnalyst(listItem);
                            setIsRemoveModalVisible(true);
                          }}
                        >
                          <CloseIcon className="fill-[#545576]" />
                        </div>
                      </Tooltip>,
                    ]
                  : []
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar className="h-10 w-10 flex items-center justify-center bg-info text-white text-sm font-semibold">
                    {listItem.name
                      .split(' ')
                      .map(n => n[0])
                      .slice(0, 2)
                      .join('')}
                  </Avatar>
                }
                className={cx(
                  'flex items-center gap-2 [&_.ant-list-item-meta-avatar]:mr-0',
                  '[&_.ant-list-item-meta-title]:mb-0',
                )}
                title={
                  <span className="text-sm font-normal text-neutral-700">
                    {listItem.name}
                  </span>
                }
                description={
                  <p className="text-xs text-neutral font-normal">
                    {listItem.email}
                  </p>
                }
              />
            </List.Item>
          )}
        />
      )}
      <ConfirmationModalDanger
        isVisible={isRemoveModalVisible}
        loading={isLoading}
        modalInfo={{
          title: `Are you sure you want to remove ‘${selectedAnalyst?.name}’?`,
          description: (
            <span className="text-sm font-normal text-neutral-700">
              This will remove the assigned analyst for this fund.
            </span>
          ),
          primaryActionLabel: 'Remove',
        }}
        toggleModal={() => setIsRemoveModalVisible(false)}
        onConfirm={handleRemoveAnalyst}
      />
    </div>
  );
};

export default AnalystListing;
