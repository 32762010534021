import { Button, Modal, ModalProps, Radio, Space } from 'antd';
import { FC, useEffect, useState } from 'react';

import { RatingOption, ratingOptions } from './ratingOptions';

interface RatingModalProps {
  ratingId?: string;
  defaultRating?: RatingOption | null;
  open: boolean;
  onCancel: () => void;
  onSave: (rating: RatingOption, ratingId?: string) => void;

  // For less dom unless needed
  destroyOnClose?: ModalProps['destroyOnClose'];
}

export const RatingModal: FC<RatingModalProps> = ({
  ratingId,
  defaultRating,
  open,
  onCancel,
  onSave,
  destroyOnClose,
}) => {
  const [rating, setRating] = useState<RatingOption | null>(null);

  const handleCancel = () => {
    setRating(null);
    onCancel();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setRating(defaultRating || null);
  }, [defaultRating, open]);

  return (
    <Modal
      open={open}
      title={`${defaultRating ? 'Edit' : 'Add'} Fund rating`}
      onCancel={handleCancel}
      className="action-modal action-modal-confirmation"
      footer={[
        <Button key="cancel" size="large" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          size="large"
          type="primary"
          disabled={!rating || rating === defaultRating}
          onClick={async () => {
            if (!rating) return;

            await onSave(rating);
            handleCancel();
          }}
        >
          {defaultRating ? 'Save' : 'Add'}
        </Button>,
      ]}
      destroyOnClose={destroyOnClose}
    >
      <div className="pb-4">
        <p className="text-neutral-900 pb-4">
          {ratingId
            ? 'Edit Fund Rating'
            : 'How would you like to rate this fund?'}
        </p>
        <Radio.Group
          onChange={e => {
            setRating(e.target.value);
          }}
          value={rating}
        >
          <Space direction="vertical">
            {ratingOptions.map(option => (
              <Radio className="text-neutral-700" key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  );
};
