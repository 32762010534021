import { Meeting } from '@aminsights/contract';
import { Modal } from 'antd';
import cx from 'classnames';
import { FC, useMemo } from 'react';

import MeetingsListItem from './MeetingsListItem';
import { groupMeetingsByYear } from './utils';

interface AllMeetingsListProps {
  isVisible: boolean;
  meetings: Meeting[];
  toggleModal: () => void;
  onEditMeeting: (meeting: Meeting) => void;
  onDeleteMeeting: (meeting: Meeting) => void;
  onShowMeetingDetail: (meeting: Meeting) => void;
}

const AllMeetingsList: FC<AllMeetingsListProps> = ({
  isVisible,
  meetings,
  toggleModal,
  onEditMeeting,
  onDeleteMeeting,
  onShowMeetingDetail,
}) => {
  const { groupedMeetings, sortedGroups } = useMemo(() => {
    return groupMeetingsByYear(meetings);
  }, [meetings]);

  return (
    <Modal
      open={isVisible}
      className={cx(
        'max-sm:full-page-modal',
        '[&_.ant-modal-body]:p-10 [&_.ant-modal-close]:mr-4',
      )}
      footer={null}
      onCancel={toggleModal}
    >
      <div className="flex flex-col gap-6">
        <h2 className="text-xl text-neutral-200 font-bold">Meetings</h2>
        {sortedGroups.map(year => (
          <div key={year} className="flex flex-col gap-1">
            <h3 className="text-sm text-neutral-200 font-bold">{year}</h3>
            <div className="flex flex-col">
              {(groupedMeetings[year] ?? []).map(meeting => (
                <MeetingsListItem
                  key={meeting._id}
                  meeting={meeting}
                  onEditMeeting={onEditMeeting}
                  onDeleteMeeting={async meeting => {
                    await onDeleteMeeting(meeting);
                    toggleModal();
                  }}
                  onShowMeetingDetail={onShowMeetingDetail}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AllMeetingsList;
