import React from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import ShareClassTable from '@/partials/shareClassTable';

const ShareClassesDetails: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  if (!state) return null;
  return (
    <div className="lg:px-4">
      <div className="bg-white lg:rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row">
          <div className="xl:w-2/12 lg:w-3/12 md:w-full w-full" />
        </div>
        <ShareClassTable data={state.sharedClass} />
      </div>
    </div>
  );
};

export default ShareClassesDetails;
