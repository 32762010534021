import { ChartDataTag } from '@aminsights/contract';

import {
  CHART_COLOR_FOR_INDEX_LINE,
  CHART_COLOR_FOR_SECTOR_LINE,
  ChartingToolColors,
  LegendData,
} from './common';

export type GenerateChartColorsTypeByIsins<T> = {
  legendData: T[];
  sortedIsins: string[];
  ids?: never;
};
export type GenerateChartColorsTypeByIds<T> = {
  legendData: T[];
  ids: string[];
  sortedIsins?: never;
};
export type GenerateChartColorsType<T> =
  | GenerateChartColorsTypeByIds<T>
  | GenerateChartColorsTypeByIsins<T>;

export const generateChartColors = <T extends LegendData>({
  legendData,
  sortedIsins,
  ids,
}: GenerateChartColorsType<T>): Record<string, string> => {
  const useTransparentColors = legendData.some(
    ld => ld.dataTag === ChartDataTag.Featured,
  );
  const fundColors = (ids || sortedIsins).reduce(
    (prev, i, idx) => {
      const legendPoint = ids
        ? legendData.find(ld => ld.id === i)
        : legendData.find(ld => ld.isin === i);
      if (!legendPoint?.id) {
        return prev;
      }
      const colorVariant =
        legendPoint.dataTag !== ChartDataTag.Featured && useTransparentColors
          ? 'transparent'
          : 'bright';
      const color = ChartingToolColors[idx][colorVariant];
      return { ...prev, [legendPoint.id]: color };
    },
    {} as Record<string, string>,
  );
  const benchmark = legendData.find(
    ld => ld.dataTag === ChartDataTag.Benchmark,
  );
  const sector = legendData.find(ld => ld.dataTag === ChartDataTag.Sector);

  return {
    ...fundColors,
    ...(benchmark?.id ? { [benchmark.id]: CHART_COLOR_FOR_INDEX_LINE } : {}),
    ...(sector?.id ? { [sector.id]: CHART_COLOR_FOR_SECTOR_LINE } : {}),
  };
};
