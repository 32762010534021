import { CSSProperties, FC, ReactNode, memo } from 'react';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

interface Props {
  items: Array<{ label: string; value: string }>;
  renderItemByIndex: (index: number) => ReactNode;
}

const VirtualizedList: FC<Props> = memo(({ items, renderItemByIndex }) => {
  // Create a cache to measure and store row heights dynamically
  const cache = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  const rowRenderer = ({
    index,
    key,
    style,
    parent,
  }: {
    index: number;
    key: string;
    style: CSSProperties;
    parent: any;
  }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {() => <div style={style}>{renderItemByIndex(index)}</div>}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          rowCount={items.length}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          deferredMeasurementCache={cache}
        />
      )}
    </AutoSizer>
  );
});

export default VirtualizedList;
