import {
  FundRelativeRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import _ from 'lodash';

import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from '../../Performance/types';
import { IFundRiskData } from '../CompareRisk';

export function compareDataSort(
  data: (
    | IFundRiskData
    | IAnnualisedPerformanceData
    | ICalendarYearPerformanceData
  )[],
  key: string,
  direction: PageQueryParametersSortDirectionEnum,
) {
  let propertyPath = key;

  //this accounts for the upsideDownsideSortKey in the risk table
  if (key === 'upsideDownsideSortKey') {
    const type: keyof Pick<
      FundRelativeRiskMeasuresDetail,
      'captureRatioTypeOne' | 'captureRatioTypeTwo'
    > =
      direction === PageQueryParametersSortDirectionEnum.Asc
        ? 'captureRatioTypeTwo'
        : 'captureRatioTypeOne';
    propertyPath = `relativeRiskMeasuresDetail.${type}`;
  }

  return data.sort((a, b) => {
    // the negative infinity is to ensure that undefined values are sorted to the end
    const aValue = _.get(a, propertyPath) || Number.NEGATIVE_INFINITY;
    const bValue = _.get(b, propertyPath) || Number.NEGATIVE_INFINITY;

    if (aValue < bValue) {
      return direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
}
