import { Fund } from '@aminsights/contract';
import { MsTimePeriod, WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { StarFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import AnnualisedPerformanceColumns from './AnnualisedPerformance';
import CalendarYearPerformanceColumns from './CalendarYearPerformance';
import ESGColumns from './ESG';
import { CheckboxParams } from './FundNameCheckboxWrapper';
import GenderPayGap from './GenderPayGap';
import FundIncomeColumns from './Income';
import RiskColumns from './RiskColumns';
import SummaryColumns from './Summary';

interface IEvaluateColumns {
  currentTab: string;
  checkboxParams?: CheckboxParams;
  dataTableRenderedAt: DataTableRenderedAt;
  showPrimaryShareClassIndicator?: boolean;
  allItemsAreFixedIncome?: boolean;
  dateRange: MsTimePeriod;
}

export const evaluateColumns = ({
  currentTab,
  allItemsAreFixedIncome = false,
  checkboxParams,
  dataTableRenderedAt,
  showPrimaryShareClassIndicator,
  dateRange,
}: IEvaluateColumns) => {
  const columns: IDataTableColumns[] = [];
  const emptyCheckboxParams = {
    onCheckedRow: () => {
      return;
    },
    checkedISINs: [],
    topISINs: [],
    isAllCheckboxChecked: false,
  };
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  switch (currentTab) {
    case WATCHLIST_TAB_KEY.SUMMARY:
      columns.push(
        ...SummaryColumns(
          checkboxParams || emptyCheckboxParams,
          dataTableRenderedAt,
          showPrimaryShareClassIndicator,
          allItemsAreFixedIncome,
        ),
      );
      break;
    case WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE:
      columns.push(
        ...AnnualisedPerformanceColumns(
          checkboxParams || emptyCheckboxParams,
          dataTableRenderedAt,
          showPrimaryShareClassIndicator,
        ),
      );
      break;
    case WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE:
      columns.push(
        ...CalendarYearPerformanceColumns(
          checkboxParams || emptyCheckboxParams,
          dataTableRenderedAt,
          showPrimaryShareClassIndicator,
        ),
      );
      break;
    case WATCHLIST_TAB_KEY.RISK:
      columns.push(
        ...RiskColumns(
          checkboxParams || emptyCheckboxParams,
          dataTableRenderedAt,
          dateRange,
          showPrimaryShareClassIndicator,
        ),
      );
      break;
    case WATCHLIST_TAB_KEY.INCOME:
      columns.push(
        ...FundIncomeColumns(
          checkboxParams || emptyCheckboxParams,
          dataTableRenderedAt,
          showPrimaryShareClassIndicator,
        ),
      );
      break;
    case WATCHLIST_TAB_KEY.ESG:
      columns.push(
        ...ESGColumns(dataTableRenderedAt === DataTableRenderedAt.Explore),
      );
      break;
    case WATCHLIST_TAB_KEY.GENDER_PAY_GAP:
      columns.push(
        ...GenderPayGap(dataTableRenderedAt === DataTableRenderedAt.Explore),
      );
      break;
    default:
      break;
  }
  // Since we're showing the star icon on the checkbox itself,
  // hide this on set of columns with checkbox.
  if (
    currentTab === WATCHLIST_TAB_KEY.ESG ||
    currentTab === WATCHLIST_TAB_KEY.GENDER_PAY_GAP
  ) {
    columns.unshift({
      title: '',
      renderType: 'custom',
      render: (fund: Fund) => (
        <div className="flex relative justify-center items-center z-10">
          <Tooltip
            overlayClassName="cursor-auto"
            title="Primary Share Class"
            placement={isMobile ? 'right' : 'bottom'}
          >
            <StarFilled
              className={cx(
                'text-[#0072E6] w-3 h-3 pl-1',
                fund?.primaryShareClass ? 'opacity-100' : 'opacity-0',
              )}
            />
          </Tooltip>
        </div>
      ),
      width: 16,
    });
  }
  return columns;
};
