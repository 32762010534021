import cx from 'classnames';
import { FC } from 'react';

export interface TableLegendProps {
  title?: string;
  startText?: string;
  endText?: string;
  colors: string[];
}

export const TableLegend: FC<TableLegendProps> = ({
  title,
  startText,
  endText,
  colors,
}) => (
  <div className="block md:flex p-4 text-xs rounded-full">
    {title && <span className="md:mr-9">{title}</span>}
    <div className="flex">
      <span>{startText ?? 'High'}</span>
      <div className="flex items-center px-3">
        {colors.map((backgroundColor, index) => (
          <span
            key={`background-color-${backgroundColor}`}
            className={cx(
              'h-2 w-6 border-box border-t-[0.5px] border-l-[0.5px] border-b-[0.5px] border-[#181920]',
              index === colors.length - 1 && 'border-r-[0.5px]',
            )}
            style={{ backgroundColor }}
          />
        ))}
      </div>
      <span>{endText ?? 'Low'}</span>
    </div>
  </div>
);
