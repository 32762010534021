import { Button } from 'antd';
import { FC } from 'react';

import { ReactComponent as GraphicCommentary } from '@/assets/svg/graphic-commentary.svg';

interface EmptyStateProps {
  onClick: () => void;
  title: string;
  buttonLabel: string;
  hideButton?: boolean;
  icon?: React.ReactNode;
  subTitle?: string;
}

const EmptyState: FC<EmptyStateProps> = ({
  onClick,
  title,
  buttonLabel,
  hideButton,
  icon = <GraphicCommentary />,
  subTitle,
}) => (
  <div className="flex flex-col gap-y-4 items-center justify-center w-full text-center">
    {icon}
    <div className="flex flex-col gap-y-2">
      <h3 className="text-sm font-semibold text-neutral-200">{title}</h3>
      {subTitle && <p className="leading-5 text-neutral-200">{subTitle}</p>}
    </div>
    {!hideButton && (
      <Button
        onClick={onClick}
        className="h-10 px-6 py-1.5 text-sm not-italic font-medium rounded"
        type="primary"
      >
        {buttonLabel}
      </Button>
    )}
  </div>
);

export default EmptyState;
