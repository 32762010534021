import {
  WatchlistApi,
  WatchlistGenderPayGapResponse,
} from '@aminsights/contract';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import calculateNextPageForInfiniteQuery from '../util';
import { GENDER_PAY_GAP, WATCHLIST } from './query-keys';
import { WatchlistParams } from './watchlistParams';

const useWatchlistGenderPayGapInfiniteQuery = (
  params?: WatchlistParams,
  options?: Omit<
    UseInfiniteQueryOptions<WatchlistGenderPayGapResponse, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection, bucketId } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useInfiniteQuery<WatchlistGenderPayGapResponse, unknown>(
    [WATCHLIST, GENDER_PAY_GAP, { sortKey, sortDirection, bucketId }],
    async ({ pageParam = 1 }) => {
      const response = await watchlistApi.getWatchlistGenderPayGap(
        {
          page: pageParam,
          size: SIZE_PER_PAGE,
          sortBy: sortKey,
          sortDirection: sortDirection,
        },
        bucketId,
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return calculateNextPageForInfiniteQuery(lastPage.total, pages);
      },
      ...options,
    },
  );
};

export default useWatchlistGenderPayGapInfiniteQuery;
