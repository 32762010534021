import { LEGAL_STRUCTURE } from '@aminsights/shared';
import { Dispatch, SetStateAction } from 'react';

import { ERROR_ISIN_ALREADY_EXISTS_IN_THE_POTRFOLIO } from '@/constants/text-messages';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

interface FundInPortfolio {
  isin: string;
  fundName: string;
  broadAssetClass?: string;
}

const AddFundSearchBox = ({
  onClose,
  onAdd,
  dataTestId,
  fundsInPortfolio,
}: {
  onClose: () => void;
  onAdd: (
    isin: string,
    fundName: string,
    code: string,
    broadAssetClass: string,
  ) => void;
  dataTestId?: string;
  fundsInPortfolio: FundInPortfolio[];
}) => {
  const searchConfig = useSearch();
  const searchBoxOpts = useSearchBox({
    onClose: () => {},
    defaultValue: '',
    searchConfig,
  });
  const resultOpts = searchBoxOpts.allSuggestions.map(opt =>
    renderOptions({
      value: opt._id,
      title: opt.shareClassDetails.code,
      highlightTerm: searchBoxOpts.searchValue,
      primaryShareClass: opt.primaryShareClass,
      isLegalStructureMPS: LEGAL_STRUCTURE.MPS === opt.legalStructure,
    }),
  );
  const handleOnSearchResults = async (
    term?: string,
    onError?: Dispatch<SetStateAction<string | null>>,
  ) => {
    if (term) {
      if (fundsInPortfolio.some(f => f.isin === term)) {
        onError?.(ERROR_ISIN_ALREADY_EXISTS_IN_THE_POTRFOLIO);
        return;
      }
      const selectedFund = searchBoxOpts.allSuggestions.find(
        f => f._id === term,
      );
      if (selectedFund) {
        onAdd(
          selectedFund._id,
          selectedFund.fundName,
          selectedFund.shareClassDetails.code,
          selectedFund.broadAssetClass,
        );
      }
    }
  };
  return (
    <ExploreSearchV2
      dataTestId={dataTestId}
      value={searchBoxOpts.searchValue}
      loading={searchBoxOpts.isLoading}
      onChange={searchBoxOpts.onChange}
      onBlur={onClose}
      onSelect={handleOnSearchResults}
      onKeyEnter={handleOnSearchResults}
      onSubmit={handleOnSearchResults}
      options={resultOpts}
      popupClassName="max-sm:!w-[calc(100vw-32px)]"
    />
  );
};

export default AddFundSearchBox;
