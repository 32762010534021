import React, { ReactNode } from 'react';

type SubtitleProps = {
  children: string | ReactNode;
};

const Subtitle: React.FCWithChild<SubtitleProps> = ({ children }) => {
  return <p className="text-sm text-neutral">{children}</p>;
};

export default Subtitle;
