import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const MatrixFundName: FC<{
  index: number;
  fundName: string;
  isin: string;
  isLoading: boolean;
  isFeatured?: boolean;
  className?: string;
}> = ({ index, fundName, isin, isLoading, className, isFeatured }) => {
  return (
    <div className={cx('flex items-center h-4 md:h-10', className)}>
      <div
        className="md:border md:border-gray-300 md:py-3 md:px-[14px] rounded w-full whitespace-nowrap overflow-hidden overflow-ellipsis text-xs"
        style={{
          backgroundColor: isFeatured ? '#E6F9F6' : 'transparent',
        }}
      >
        {isLoading && (
          <div className="h-5 w-full">
            <Skeleton.Node className="rounded h-full w-full block" active />
          </div>
        )}
        {!isLoading && (
          <>
            <strong className="pr-2 font-semibold inline-block text-[#3B3C53]">
              {index + 1}
            </strong>
            &nbsp;
            <Tooltip
              overlayClassName="pointer-events-none"
              title={!isLoading ? fundName : ''}
              placement="bottomLeft"
              showArrow={false}
              mouseLeaveDelay={0}
              align={{
                offset: [-30, 20],
              }}
            >
              <Link to={`/fund/${isin}/details/summary`}>
                <span className="text-xs inline text-[#3B3C53] font-medium">
                  {fundName}
                </span>
              </Link>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export const MatrixNumber: FC<{
  index: number;
  isLoading: boolean;
  fundName: string;
  className?: string;
  isFeatured?: boolean;
  spanClassName?: string;
}> = ({ index, className, fundName, isFeatured, isLoading, spanClassName }) => {
  return (
    <div
      className={cx(
        'flex items-center font-semibold text-center justify-center w-full h-14 text-xs',
        'h-14 last:pt-3 last:h-7',
        className,
      )}
    >
      {isLoading && (
        <div className="w-full h-4 px-3">
          <Skeleton.Node className="h-4 rounded h-full w-full block" active />
        </div>
      )}
      {!isLoading && (
        <Tooltip
          overlayClassName="pointer-events-none"
          title={!isLoading ? fundName : ''}
          placement="bottom"
          showArrow={false}
          mouseLeaveDelay={0}
          align={{
            offset: [0, -10],
          }}
        >
          <span
            className={cx(
              'mx-2 xl:mx-3 xxl:mx-4 px-2 font-bold rounded cursor-default text-darkest',
              isFeatured ? 'bg-[#E6F9F6]' : 'bg-transparent',
              spanClassName,
            )}
          >
            {index + 1}
          </span>
        </Tooltip>
      )}
    </div>
  );
};
