import {
  ChartsApi,
  RiskDetailsTypeEnum,
  ScatterPlotResponse,
} from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { CHARTS, CHARTS_RISK_RETURN } from '../watchlist-hooks/query-keys';

export interface ScatterPlotParams {
  period: string;
  isins: string[];
  benchmarkId?: string;
  sectorId?: string;
  riskDetailsType?: RiskDetailsTypeEnum;
  featuredIsins?: string[];
  retainSortOrder?: boolean;
}

const useGetRiskReturnScatterPlotData = (
  params: ScatterPlotParams,
  options?: Omit<
    UseQueryOptions<ScatterPlotResponse | null, unknown, ScatterPlotResponse>,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    period,
    isins,
    benchmarkId,
    sectorId,
    riskDetailsType,
    retainSortOrder,
    featuredIsins,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());

  const enabled = isins.length !== 0;

  return useQuery<ScatterPlotResponse | null, unknown, ScatterPlotResponse>(
    [CHARTS, CHARTS_RISK_RETURN, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getRiskReturnScatterPlotData(
        benchmarkId,
        isins,
        period,
        retainSortOrder,
        featuredIsins,
        riskDetailsType,
        sectorId,
      );
      return response?.data;
    },
    {
      ...options,
    },
  );
};

export default useGetRiskReturnScatterPlotData;
