import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React, { useEffect, useState } from 'react';

import { Table } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Info',
    dataIndex: 'info',
  },
];

const formInfoStatsIncomeDataData = (fund: Fund) => {
  return [
    {
      type: 'Yield',
      info:
        fund?.yield12Month?.value === 0 ||
        !isNaN(Number.parseFloat(`${fund?.yield12Month?.value}`))
          ? `${Number(fund?.yield12Month?.value).toFixed(2)}%`
          : EMPTY_DATA_POINT,
    },
    {
      type: 'Income frequency',
      info: fund.incomeFrequency || EMPTY_DATA_POINT,
    },
    {
      type: 'Payment type',
      info: EMPTY_DATA_POINT,
    },
  ];
};

const InfoStatsIncome: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [infoStatsIncomeDataData, setInfoStatsIncomeDataData] = useState<
    {
      type: string;
      info: string | number;
    }[]
  >([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (state?.fund) {
      setInfoStatsIncomeDataData(formInfoStatsIncomeDataData(state.fund));
    }
  }, [state?.fund, !!isInvestmentTrust]);

  return (
    <div className={style['info-stats-income']}>
      <Table
        columns={columns}
        data={infoStatsIncomeDataData}
        paginateTotalCount={0}
        uniqueKey="type"
      />
    </div>
  );
};

export default InfoStatsIncome;
