import { Button } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as MobileFiltersIcon } from '@/assets/svg/icons/icon-filter-modal.svg';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';

import { useExploreDefaultFiltersQuery } from '../context';
import useExploreFilters from '../hooks/useExploreFilters';
import areFiltersApplied from '../utils/filterComparisonHelpers';
import { BondsFilterModal } from './BondsFilterModal';
import { EquitiesFilterModal } from './EquitiesFilterModal';
import { EsgFilterModal } from './EsgFilterModal';
import IaFiltersDropdownWrapper from './IndividualFilters/IaSectors/IaFilterDropdownWrapper';
import CategoriesFiltersDropdownWrapper from './IndividualFilters/MorningstarCategories/CategoriesFiltersDropdownWrapper';
import FundSizeDropdown from './IndividualFilters/RangeSliderDropdown/FundSizeDropdown';
import OCFDropdown from './IndividualFilters/RangeSliderDropdown/OCFDropdown';
import MobileExploreFilters from './MobileExploreFilters';
import { MoreFiltersModal } from './MoreFiltersModal';
import { RiskFilterModal } from './RiskFilterModal';

const ExploreFilters: React.FCWithChild = () => {
  const { setFullTerm } = useSearch();
  const [isESGOpen, setIsESGOpen] = useState<boolean>(false);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState<boolean>(false);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] =
    useState<boolean>(false);
  const [isRiskFiltersOpen, setIsRiskFiltersOpen] = useState<boolean>(false);
  const [isEquitiesFiltersOpen, setIsEquitiesFiltersOpen] =
    useState<boolean>(false);
  const [isBondsFiltersOpen, setIsBondsFiltersOpen] = useState<boolean>(false);
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const { resetFilters, filters } = useExploreFilters();
  useExploreFilters();

  const hasFiltersApplied = areFiltersApplied(filters);

  const handleESGOpen = () => {
    setIsESGOpen(prev => !prev);
  };

  const handleMoreFiltersOpen = () => {
    setIsMoreFiltersOpen(prev => !prev);
  };

  const handleMobileFiltersOpen = () => {
    setIsMobileFiltersOpen(prev => !prev);
  };

  const handleRiskFiltersOpen = () => {
    setIsRiskFiltersOpen(prev => !prev);
  };

  const handleEquitiesFiltersOpen = () => {
    setIsEquitiesFiltersOpen(prev => !prev);
  };

  const handleBondsFiltersOpen = () => {
    setIsBondsFiltersOpen(prev => !prev);
  };

  const iaSectors = useMemo(
    () =>
      defaultfiltersOptions.iaSectors?.map((i, idx) => ({
        label: i.label,
        value: `${idx}`,
      })),
    [defaultfiltersOptions.iaSectors],
  );

  const clearState = () => {
    setFullTerm('');
    resetFilters();
  };

  const count = (filters: Array<unknown | undefined>) => {
    let ctr = 0;
    filters.forEach(filter => {
      if (typeof filter !== 'undefined' && filter !== false) {
        ctr++;
      }
    });
    return ctr ?? '';
  };

  const countSelectedRiskFilter = count([
    filters.riskFilters?.betaRange,
    filters.riskFilters?.upsideRange,
    filters.riskFilters?.downsideRange,
    filters.riskFilters?.trackingErrorRange,
    filters.riskFilters?.stdDeviationRange,
    filters.riskFilters?.maximumDrawdownRange,
    filters.riskFilters?.sortinoRatioRange,
  ]);

  const countSelectedEquitiesFilter = count([
    filters.equitiesFilters?.equityStyle,
    filters.equitiesFilters?.roicRange,
    filters.equitiesFilters?.netMarginRange,
  ]);

  const countSelectedBondsFilter = count([
    filters.bondsFilters?.bondsStyle,
    filters.bondsFilters?.yieldMaturityRange,
    filters.bondsFilters?.modifiedDurationRange,
    filters.bondsFilters?.avgCreditQualityRange,
  ]);

  const countSelectedESGFilter = count([
    filters.esgFilters?.checkedSFDR,
    filters.esgFilters?.datesUNPRI,
    filters.esgFilters?.datesTCFD,
    filters.esgFilters?.shareActionRatings,
    filters.esgFilters?.stewardshipCode,
    filters.esgFilters?.netZeroAssetManager,
    filters.esgFilters?.financeForBiodiversityDates,
    filters.esgFilters?.actDates,
  ]);

  const countSelectedMoreFilter = count([
    filters.moreFilters?.selectedManagementGroup,
    filters.moreFilters?.selectedDomiciles,
    filters.moreFilters?.selectedCurrencies,
    filters.moreFilters?.selectedPlatforms,
    filters.moreFilters?.yieldRange,
    filters.moreFilters?.noOfHoldingsRange,
    filters.moreFilters?.selectedBroadAssetClasses,
    filters.moreFilters?.legalStructure,
    filters.moreFilters?.filterByOldestShareClassOnly,
    filters.moreFilters?.genders,
    filters.moreFilters?.currencyHedgedType,
    filters.moreFilters?.filterByActiveOrPassive,
  ]);

  let countAllAppliedFilters =
    countSelectedRiskFilter +
    countSelectedEquitiesFilter +
    countSelectedBondsFilter +
    countSelectedESGFilter +
    countSelectedMoreFilter;

  if (
    filters.morningStarCategories?.selectedMorningStarCategoriesByIndex &&
    filters.morningStarCategories?.selectedMorningStarCategoriesByIndex.length >
      0
  ) {
    countAllAppliedFilters++;
  }
  if (
    filters.iaSectors?.selectedIaSectors?.length &&
    filters.iaSectors?.selectedIaSectors.length > 0
  ) {
    countAllAppliedFilters++;
  }
  if (filters.fundSizeFilter?.fundSizeRange) {
    countAllAppliedFilters++;
  }
  if (filters.ocfFilter?.ocfRange) {
    countAllAppliedFilters++;
  }

  const renderClearFilters = () => {
    return (
      <div
        data-test-id={'clearFilters'}
        className="flex items-center clear-filters min-h-10"
        onClick={clearState}
        aria-hidden="true"
        id="clear-filter"
      >
        <div className="w-20">
          <p className="font-medium">Clear filters</p>
        </div>
      </div>
    );
  };

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  const mobileFiltersPortal = document.getElementById('mobile-filters-portal');

  useEffect(() => {
    if (shouldUseMobileFilter) {
      setIsMoreFiltersOpen(false);
      setIsRiskFiltersOpen(false);
      setIsEquitiesFiltersOpen(false);
      setIsBondsFiltersOpen(false);
      setIsESGOpen(false);
    }
  }, [shouldUseMobileFilter]);

  return (
    <div className="flex">
      {shouldUseMobileFilter &&
        mobileFiltersPortal &&
        createPortal(
          <>
            <div>
              <Button
                className={cx(
                  `${
                    (countSelectedMoreFilter as number) > 0 && 'with-selected'
                  }`,
                  'aspect-square p-0 h-10 rounded-[4px]',
                )}
                size="large"
                type="default"
                onClick={handleMobileFiltersOpen}
                data-test-id={'mobile-filters-button'}
              >
                <div className="flex items-center">
                  <MobileFiltersIcon height={24} width={24} />{' '}
                </div>
                {countAllAppliedFilters > 0 && (
                  <div className="absolute top-[-10px] right-[-10px] bg-info h-5 w-5 rounded-xl text-white flex justify-center items-center text-xxs">
                    <p>{countAllAppliedFilters}</p>
                  </div>
                )}
              </Button>
            </div>
            <MobileExploreFilters
              isOpen={isMobileFiltersOpen}
              onClose={() => {
                setIsMobileFiltersOpen(false);
              }}
              iaSectors={iaSectors}
            />
          </>,
          mobileFiltersPortal,
        )}
      <div className="flex flex-row flex-wrap">
        {
          <div className="flex flex-col 2xl:flex-row">
            <div className="flex justify-start w-full flex-wrap">
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <CategoriesFiltersDropdownWrapper />
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <IaFiltersDropdownWrapper />
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <FundSizeDropdown />
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <OCFDropdown />
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <Button
                  className={cx(
                    'hover:text-primary text-neutral-700',
                    countSelectedRiskFilter > 0 && 'font-semibold',
                  )}
                  type="default"
                  size="large"
                  onClick={handleRiskFiltersOpen}
                  data-test-id={'risk'}
                >
                  <div className="flex items-center">
                    <SelectedDropdownFilter
                      placeholder="Risk"
                      noOfSelectedFilter={countSelectedRiskFilter}
                      className="select-dropdown-filter-wrapper"
                    />
                  </div>
                </Button>
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <Button
                  className={cx(
                    'hover:text-primary text-neutral-700',
                    countSelectedEquitiesFilter > 0 && 'font-semibold',
                  )}
                  type="default"
                  size="large"
                  onClick={handleEquitiesFiltersOpen}
                  data-test-id={'equities'}
                >
                  <div className="flex items-center">
                    <SelectedDropdownFilter
                      placeholder="Equities"
                      noOfSelectedFilter={countSelectedEquitiesFilter}
                      className="select-dropdown-filter-wrapper"
                    />
                  </div>
                </Button>
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <Button
                  className={cx(
                    'hover:text-primary text-neutral-700',
                    countSelectedBondsFilter > 0 && 'font-semibold',
                  )}
                  type="default"
                  size="large"
                  onClick={handleBondsFiltersOpen}
                  data-test-id={'bonds'}
                >
                  <div className="flex items-center">
                    <SelectedDropdownFilter
                      placeholder="Bonds"
                      noOfSelectedFilter={countSelectedBondsFilter}
                      className="select-dropdown-filter-wrapper"
                    />
                  </div>
                </Button>
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <Button
                  className={cx(
                    'hover:text-primary text-neutral-700',
                    countSelectedESGFilter > 0 && 'font-semibold',
                  )}
                  type="default"
                  size="large"
                  onClick={handleESGOpen}
                  data-test-id={'ESG'}
                >
                  <div className="flex items-center">
                    <SelectedDropdownFilter
                      placeholder="ESG"
                      noOfSelectedFilter={countSelectedESGFilter}
                      className="select-dropdown-filter-wrapper"
                    />
                  </div>
                </Button>
              </div>
              <div
                className={cx('pr-2 pb-4', shouldUseMobileFilter && 'hidden')}
              >
                <Button
                  className={cx(
                    'hover:text-primary text-neutral-700',
                    countSelectedMoreFilter > 0 && 'font-semibold',
                  )}
                  type="default"
                  size="large"
                  onClick={handleMoreFiltersOpen}
                  data-test-id={'morefilters'}
                >
                  <div className="flex items-center">
                    <SelectedDropdownFilter
                      placeholder="More filters"
                      noOfSelectedFilter={countSelectedMoreFilter}
                      className="select-dropdown-filter-wrapper"
                    />
                  </div>
                </Button>
              </div>
              {hasFiltersApplied && (
                <div
                  className={cx('px-2 pb-4', shouldUseMobileFilter && 'hidden')}
                >
                  {renderClearFilters()}
                </div>
              )}
            </div>
          </div>
        }
      </div>
      <EsgFilterModal isOpen={isESGOpen} onClose={handleESGOpen} />
      <MoreFiltersModal
        isOpen={isMoreFiltersOpen}
        onClose={() => {
          setIsMoreFiltersOpen(false);
        }}
      />
      <RiskFilterModal
        isOpen={isRiskFiltersOpen}
        onClose={() => {
          setIsRiskFiltersOpen(false);
        }}
      />
      <EquitiesFilterModal
        isOpen={isEquitiesFiltersOpen}
        onClose={() => {
          setIsEquitiesFiltersOpen(false);
        }}
      />
      <BondsFilterModal
        isOpen={isBondsFiltersOpen}
        onClose={() => {
          setIsBondsFiltersOpen(false);
        }}
      />
    </div>
  );
};

export default ExploreFilters;
