import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import withScrollToTop from '@/hoc/withScrollToTop';
import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import { BucketsScrollTab } from '../../Watchlist/Watchlist/components';
import SignificantChangesWrapper from '../components/SignificantChangesWrapper';

const All: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist?.data?.buckets;

  const currentBucketId = useCurrentBucketId();
  const { slug } = useParams<{ slug: string }>();

  const isAllBucketsSelected = useMemo(() => {
    return !slug || slug.toLowerCase() === 'all';
  }, [slug]);

  const isinsFromWatchlist = useMemo(() => {
    return buckets
      ?.flatMap(bucket => bucket.funds.map(fundItem => fundItem.isin))
      .filter(isin => isin !== undefined);
  }, [buckets]);

  const currentBucket = buckets?.find(b => b.id === currentBucketId);

  return (
    <BasePageWithMetadata
      title={`Significant Changes${
        currentBucket ? ` - ${currentBucket.name}` : ' - All'
      }`}
    >
      <div className="pt-6">
        <h2 className="px-4 mb-4 lg:mb-0 text-xl font-bold">
          Significant Changes
        </h2>
        <section className="lg:p-4">
          <div className="pt-1 mb-6 bg-white lg:rounded-lg">
            <div className="bg-white relative [&_.ant-tabs-tab-active]:font-normal px-4">
              <BucketsScrollTab />
            </div>

            <div>
              <SignificantChangesWrapper
                isins={
                  (isAllBucketsSelected
                    ? isinsFromWatchlist
                    : currentBucket?.funds
                        ?.filter(fund => fund.isin !== undefined)
                        .map(fund => fund.isin) || undefined) ?? []
                }
              />
            </div>
          </div>
        </section>
      </div>
    </BasePageWithMetadata>
  );
};

export default withScrollToTop(All);
