import { SettingsApi, UserChangePassword } from '@aminsights/contract';
import { useState } from 'react';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { openApiConfig } from '@/utils';

export const useProvideSecurity = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const appContext = useAppContext();

  const settingsAPI = new SettingsApi(openApiConfig());

  const patchChangePassword = async (values: UserChangePassword) => {
    setSubmitting(true);
    setError(null);
    await settingsAPI
      .changeUserPassword({
        newPassword: values.newPassword,
        currentPassword: values.currentPassword,
      })
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        appContext.dispatch({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: {
            shouldUseTimer: true,
            showSnackbarIcon: true,
            text: 'Successfully updated password',
          },
        });
      })
      .catch(error => {
        const errorMessage =
          error.response?.status === 403
            ? 'Current password is incorrect'
            : (error.response?.data?.message ?? error.message);

        appContext.dispatch({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return {
    success,
    submitting,
    error,
    patchChangePassword,
  };
};
