import { APP_ROUTE_EXPLORE, APP_ROUTE_FUND } from '@aminsights/shared';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import { Button } from '@/components';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useScreenWidth from '@/hooks/screenWidth';
import { FundDetailTabs } from '@/pages/app/FundAndInvestmentTrust/components/FundDetailTabs';
import FundDetailsSection from '@/pages/app/FundAndInvestmentTrust/components/FundDetailsSection';
import { FundTitleHeader } from '@/pages/app/FundAndInvestmentTrust/components/FundTitleHeader';
import { FUND_SUB_ROUTES, generatePaths } from '@/router/routes';

import { EmptySearch } from '../Explore/components/EmptySearch';

const FundOrInvestmentDetailsPage: React.FCWithChild = () => {
  const { currentWidth } = useScreenWidth();
  const { isLoading, data, error, failureReason } = useFundByIsInParam();

  const fundDetailsSectionRef = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const [height, setHeight] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (fundDetailsSectionRef?.current) {
      setTitleHeight(fundDetailsSectionRef.current.clientHeight);
      setHeight(fundDetailsSectionRef.current.clientHeight);
    }
  }, [currentWidth]);

  if (failureReason && (failureReason as any).response?.status === 404) {
    return (
      <EmptySearch
        title="ISIN not available"
        subTitle="Let us know if this ISIN should be available or browse for another below."
      >
        <Link to={`/${APP_ROUTE_EXPLORE}/`}>
          <Button
            className="h-10 font-medium"
            type="primary"
            size="large"
            key="primary"
            htmlType="submit"
          >
            Browse more funds
          </Button>
        </Link>
      </EmptySearch>
    );
  }

  if (!isLoading && error) return <></>;

  return (
    <>
      <FundTitleHeader isLoading={isLoading} fund={data?.fund} />
      <FundDetailsSection ref={fundDetailsSectionRef} />
      <FundDetailTabs sectionHeight={height + titleHeight} />
    </>
  );
};

export default function FundOrInvestmentDetails() {
  return (
    <Switch>
      <Route
        exact
        path="/fund/:id/details"
        render={({ match }) => (
          <Redirect to={`/fund/${match.params.id}/details/summary`} />
        )}
      />
      <Route
        exact
        path="/fund/:id/"
        render={({ match }) => {
          return (
            match.params.id !== 'compare' && (
              <Redirect to={`/fund/${match.params.id}/details/summary`} />
            )
          );
        }}
      />
      <Route
        exact
        path={[...generatePaths(FUND_SUB_ROUTES, APP_ROUTE_FUND)].map(p => {
          return `/${p}`;
        })}
      >
        <FundOrInvestmentDetailsPage />
      </Route>
    </Switch>
  );
}
