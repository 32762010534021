import { Comment } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Skeleton } from 'antd';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useDeleteComment } from '@/hooks/query-hooks/internal-hooks/useManageCommentary';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import { INTERNAL } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import queryClient from '@/queryClient';
import { validatePermissions } from '@/utils/auth';

import CommentaryFormModal from '../CommentaryFormModal';
import CommentaryListItem from '../CommentaryListItem';
import EmptyCommentaryState from '../EmptyCommentaryState';

interface CommentaryProps {
  comments?: Comment[];
  isLoading?: boolean;
  fundId?: string;
  fundName?: string;
}

const highlightCommentKey = 'highlightRecentCommentary';

const Commentary: FC<CommentaryProps> = ({
  comments,
  isLoading,
  fundId,
  fundName,
}) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [highlightRecent, setHighlightRecent] = useState(false);
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const { data: users } = useOrganizationUsers();
  const deleteComment = useDeleteComment();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (searchParams.has(highlightCommentKey)) {
      searchParams.delete(highlightCommentKey);
      history.replace({
        search: searchParams.toString(),
      });
      setHighlightRecent(true);
    }
  }, [searchParams]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (highlightRecent) {
      queryClient.invalidateQueries([INTERNAL]);
      timeout = setTimeout(() => {
        setHighlightRecent(false);
      }, 1000 * 10);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [highlightRecent]);

  const handleToggleFormModal = () => {
    setIsFormModalOpen(prev => !prev);
  };

  const handleDeleteComment = async (comment: Comment) => {
    if (!fundId) return;
    await deleteComment.mutateAsync({ fundId, commentId: comment._id });
  };

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.comments.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasComments = comments && comments.length > 0;

  return (
    <div className="flex flex-col gap-6 bg-white p-4 min-h-120 lg:rounded-lg">
      <div className="flex justify-between items-center">
        <p className="text-base font-semibold">Commentary</p>
        {hasComments && hasWritePermissions && (
          <Button
            onClick={handleToggleFormModal}
            className="h-10 text-sm font-medium"
            type="primary"
          >
            + Add commentary
          </Button>
        )}
      </div>
      {isLoading && (
        <div className="flex flex-col gap-8">
          {[1, 2].map(item => (
            <Skeleton key={item} paragraph={{ rows: 3 }} active />
          ))}
        </div>
      )}
      {!isLoading && hasComments && (
        <div className="flex flex-col gap-4">
          {comments.map((comment, index) => {
            const user = users?.find(
              user => user.user_id === comment.createdBy,
            );
            return (
              <CommentaryListItem
                key={comment._id}
                comment={comment}
                user={user}
                onDeleteComment={handleDeleteComment}
                highlight={highlightRecent && index === 0}
              />
            );
          })}
        </div>
      )}
      {!isLoading && !hasComments && (
        <EmptyCommentaryState
          onAddCommentary={handleToggleFormModal}
          showWriteAction={hasWritePermissions}
        />
      )}
      <CommentaryFormModal
        isVisible={isFormModalOpen}
        fundId={fundId}
        fundName={fundName}
        toggleModal={handleToggleFormModal}
      />
    </div>
  );
};

export default Commentary;
