import { ChartDataTag } from '@aminsights/contract';
import { DATE_PERIOD_FILTER, EDateFilterValues } from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import { Button, Dropdown } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import ChartSettings from '@/components/ChartSettings/ChartSettings';
import { DATE_FORMAT_CHARTS } from '@/constants';
import useGetRiskReturnScatterPlotData from '@/hooks/query-hooks/chart-hooks/useGetRiskReturnScatterPlotData';
import {
  getInvestmentTrustRiskDetailsType,
  useChartSettingsState,
} from '@/hooks/useChartSettingsState';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import useChartColors from '@/partials/Charts/utils/useChartColors';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';
import NestedDrawer from '@/partials/NestedDrawer';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import { STROKE_GREY } from '../../Charts/utils/colors';
import BaseScatterPlot from '../components/BaseScatterPlot';
import { ScatterPlotCardDetails } from '../utils/scatter-plot-data';

interface RiskReturnScatterPlotProps {
  isins: string[];
  benchmarkId?: string;
  sectorId?: string;
  chartOnly?: boolean;
  hasInvestmentTrust?: boolean;
  featuredIsins?: string[];
  showDropdown?: boolean;
  onRemoveFund?: (id: string, dataTag?: ChartDataTag) => void;
  retainSortOrder?: boolean;
  scatterPlotColors?: Record<string, string>;
}

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const RiskReturnScatterPlot: React.FCWithChild<RiskReturnScatterPlotProps> = ({
  isins,
  benchmarkId,
  sectorId,
  chartOnly,
  hasInvestmentTrust = false,
  showDropdown,
  onRemoveFund,
  featuredIsins,
  retainSortOrder,
  scatterPlotColors: scatterPlotColorsProps,
}) => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const { chartSettingsFilter } = useChartSettingsState();

  const [legendData, setLegendData] = useState<ScatterPlotCardDetails[]>([]);

  const currentPeriod = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);
  const activePeriod = DATE_PERIOD_FILTER.find(p => p.value === currentPeriod);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      setPeriod(parseDateValue(period.value), period.value);
      setIsNestedYearDrawerVisible(false);
    },
    selected: period.value === currentPeriod,
  }));

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedYearDrawerVisible, setIsNestedYearDrawerVisible] =
    useState(false);

  const { data, isLoading } = useGetRiskReturnScatterPlotData({
    period: currentPeriod,
    isins,
    benchmarkId,
    sectorId,
    riskDetailsType: getInvestmentTrustRiskDetailsType(
      chartSettingsFilter.investmentTrustsReturns,
    ),
    featuredIsins,
    retainSortOrder,
  });

  const scatterPlotColorsHook = useChartColors({
    isins,
    legend: data?.legendData || [],
  });
  const scatterPlotColors = scatterPlotColorsProps || scatterPlotColorsHook;
  const scatterPlotData = useMemo(
    () =>
      data?.data?.map(s => ({
        ...s,
        color: scatterPlotColors[s.id],
      })) || [],
    [data, scatterPlotColors],
  );

  useEffect(() => {
    if (data?.legendData?.length && scatterPlotColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          standardDeviation: f.xValue || 0,
          totalReturnOverPeriod: f.yValue || 0,
          id: f.id || '',
          label: f.label || '',
          color: scatterPlotColors[f.id || ''] || '',
          date: f.date ? dayjs(f.date).format(DATE_FORMAT_CHARTS) : '',
          dataTag: f.dataTag,
        })),
      );
    } else {
      setLegendData([]);
    }
  }, [data, scatterPlotColors]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData,
      colorField: 'color',
      color: data => {
        // Based on the colorField it is configured
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  return (
    <div>
      <div>
        {!chartOnly && (
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between my-2">
            <h5
              data-test-id="riskReturnChartLabel"
              className="text-sm font-bold text-darkest"
            >
              <Loader
                width="150px"
                loading={isLoading}
                component={<>Risk/Return</>}
              />
            </h5>
            <div className="flex items-center gap-x-2 pt-2 md:pt-0">
              <div
                className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto"
                data-test-id="riskReturnChartDropdown"
              >
                <Dropdown
                  trigger={['click']}
                  placement="bottom"
                  menu={{
                    items: isMobile ? [] : allowedPeriodsForDropdown,
                  }}
                >
                  <Button
                    type="default"
                    className="hover:fill-primary-light h-[40px] rounded w-full flex items-center justify-between"
                    onClick={() => setIsNestedYearDrawerVisible(true)}
                  >
                    <span>{activePeriod?.label}</span>
                    <NextArrow className="w-3 h-3 rotate-90 fill-inherit" />
                  </Button>
                </Dropdown>
                {isMobile && (
                  <NestedDrawer
                    menuItems={allowedPeriodsForDropdown}
                    visible={isNestedYearDrawerVisible}
                    onClose={() => setIsNestedYearDrawerVisible(false)}
                    title="Select"
                  />
                )}
              </div>
              {showDropdown && (
                <ChartSettings
                  hidePanelKeys={
                    !hasInvestmentTrust && 'investment-trusts-returns'
                  }
                />
              )}
            </div>
          </div>
        )}
        {!isLoading && (
          <div data-test-id="riskReturnChart">
            <BaseScatterPlot
              config={config}
              strokeColor={STROKE_GREY}
              onUpdateLegend={setLegendData}
            />
          </div>
        )}
        {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
        <div
          data-test-id="riskReturnChartLegend"
          className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
        >
          <Loader
            row={2}
            width="150px"
            loading={isLoading}
            component={legendData
              .filter(f => f.label)
              .sort((a, b) =>
                a.totalReturnOverPeriod > b.totalReturnOverPeriod ? -1 : 1,
              )
              .map(legendItem => (
                <ScatterPlotLegendCard
                  key={legendItem.id}
                  label={legendItem.label}
                  tooltip={legendItem.tooltip || legendItem.label}
                  color={legendItem.color}
                  totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                  standardDeviation={legendItem.standardDeviation}
                  superTag={getLegendSuperTag(legendItem.dataTag)}
                  id={getLegendIdToRemove(legendItem)}
                  targetLink={getLegendTargetLink(legendItem)}
                  onRemove={onRemoveFund}
                  cardClasses={getLegendCardClasses(
                    legendItem.dataTag,
                    legendItem.isActive,
                  )}
                  date={legendItem.date}
                />
              ))}
          />
        </div>
      </div>
      {/* Devider is here because it is only on charts page */}
      <div className="my-[30px] border-b border-dashed border-grey-light" />
    </div>
  );
};

export default RiskReturnScatterPlot;
