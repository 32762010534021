import { OrganizationApi } from '@aminsights/contract';
import { AUTH0_NAMESPACE } from '@aminsights/shared';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { openApiConfig } from '@/utils';

const organizationApi = new OrganizationApi(openApiConfig());

const useOrganizationUsers = () => {
  const authContext = useContext(AxiosAuthContext);
  const tenantId =
    authContext.state.decodedToken?.[`${AUTH0_NAMESPACE}/tenant_id`];

  return useQuery(
    ['org-users', tenantId],
    async () => {
      if (tenantId) {
        const res = await organizationApi.fetchUsers();
        return res.data;
      }
      return [];
    },
    { staleTime: 1000 * 60 * 5 },
  );
};

export default useOrganizationUsers;
