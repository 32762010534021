import { EDateFilterValues } from '@aminsights/shared';
import dayjs, { Dayjs } from 'dayjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DatePickerValue,
  RangeValue,
  parseDateValue,
  parseRangeValues,
} from './Kinds/RangeDatePicker';

type HandleChange = (range: RangeValue, mode: EDateFilterValues) => void;
interface DatePickerContextValue {
  value: DatePickerValue;
  handleChange: (range: RangeValue, mode: EDateFilterValues) => void;
}
interface UseDatePickerContextReturn<T> extends DatePickerContextValue {
  parsedValue?: string[] | T;
}

const isJanuaryToday = dayjs().month() === 0;
const eDateFilterDefaultValue =
  EDateFilterValues[isJanuaryToday ? '30D' : 'YTD'];

const isDateValid = (date: string) => {
  return Object.values(EDateFilterValues).includes(date as EDateFilterValues);
};

const DEFAULT_STATE: DatePickerContextValue = {
  value: {
    mode: eDateFilterDefaultValue,
    range: parseDateValue(eDateFilterDefaultValue),
  },
  // eslint-disable-next-line
  handleChange: (range: RangeValue, mode: EDateFilterValues) => {},
};

export const DatePickerContext = createContext(DEFAULT_STATE);

export const DatePickerProvider: React.FCWithChild<{
  preserveState?: boolean;
}> = ({ children, preserveState }) => {
  const [value, setValue] = useState(DEFAULT_STATE.value);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const history = useHistory();

  const handleChange: HandleChange = (range, mode) => {
    setValue({ range, mode });
    if (preserveState) {
      queryParams.set('date', mode);
      if (mode === EDateFilterValues.CUSTOM) {
        range?.forEach((d, idx) => {
          if (!idx) {
            queryParams.set('dateRange', d?.toISOString() || '');
            return;
          }
          queryParams.append('dateRange', d?.toISOString() || '');
        });
      }
      history.push({ search: queryParams.toString() });
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const dateFromParams = queryParams.get('date') as EDateFilterValues;
    const rangeFromParams = queryParams.getAll('dateRange');
    if (preserveState && dateFromParams && isDateValid(dateFromParams)) {
      const range = rangeFromParams
        .map(d => dayjs(d))
        .filter(d => d.isValid())
        .slice(0, 2) as [Dayjs, Dayjs];
      setValue({
        mode: dateFromParams,
        range:
          range.length === 2 && dateFromParams === EDateFilterValues.CUSTOM
            ? range
            : parseDateValue(dateFromParams),
      });
    }
  }, [preserveState, queryParams.get('date')]);

  return (
    <DatePickerContext.Provider value={{ value, handleChange }}>
      {children}
    </DatePickerContext.Provider>
  );
};

export function useDatePickerContext<T = string[]>(
  customRangeParser?: (range: RangeValue) => T,
): UseDatePickerContextReturn<T> {
  const { value, handleChange } = useContext(DatePickerContext);
  const [parsedValue, setParsedValue] = useState<T | string[] | undefined>();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (value.range) {
      setParsedValue(
        customRangeParser
          ? customRangeParser(value.range)
          : parseRangeValues(value.range, value.mode),
      );
    }
  }, [value]);

  return {
    value,
    handleChange,
    parsedValue,
  };
}
