import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import { forwardRef, useState } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import NestedDrawer from '../NestedDrawer';

export interface ResponsiveDropdownRef {
  close?: () => void;
}

type OptionVaue = string | number;

interface ResponsiveDropdownProps {
  nestedDrawerTitle: string;
  value: OptionVaue;
  options: {
    value: OptionVaue;
    label: string;
    key: string;
    onClick: () => void;
  }[];
  className?: string;
  dropdownKey?: string;
  dataTestId?: string;
  disabled?: boolean;
}

const ResponsiveDropdown = forwardRef<
  ResponsiveDropdownRef,
  ResponsiveDropdownProps
>(
  ({
    nestedDrawerTitle: mobileDrawerTitle,
    value,
    options,
    className,
    dropdownKey,
    dataTestId,
    disabled,
  }) => {
    const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);

    const screenWidthMode = getScreenWidthMode();
    const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

    return (
      <>
        <Dropdown
          key={dropdownKey}
          data-test-id={dataTestId}
          trigger={['click']}
          placement="bottom"
          className={className}
          disabled={disabled}
          open={isDropdownMenuVisible}
          onOpenChange={open => setIsDropdownMenuVisible(open)}
          menu={{
            items: isMobile
              ? []
              : options.map(option => ({
                  ...option,
                  label: (
                    <span className="flex justify-between">
                      <span
                        className={option.value === value ? 'text-primary' : ''}
                      >
                        {option.label}
                      </span>
                      {option.value === value && <CheckActive />}
                    </span>
                  ),
                  onClick: () => {
                    option.onClick();
                    setIsDropdownMenuVisible(false);
                  },
                })),
          }}
        >
          <Button
            onClick={() => isMobile && setMobileDrawerVisible(true)}
            size="large"
            className={cx(
              `w-full min-w-40 md:w-40 landscape:w-auto text-neutral-700 text-left flex items-center justify-between 
               border border-neutral-300 rounded h-10 group`,
              {
                'bg-grey-lighter text-neutral-300 border-neutral-300 fill-neutral-300 gap-2':
                  disabled,
                'hover:border-primary': !disabled,
                'border-primary': isDropdownMenuVisible,
              },
            )}
          >
            <span
              className={cx(
                'truncate inline text-sm mb-0 flex-grow w-full group-hover:text-primary',
                { 'text-primary': isDropdownMenuVisible },
              )}
            >
              {options.find(p => p.value === value)?.label}
            </span>
            <NextArrow
              className={cx(
                'w-3 rotate-90 group-hover:fill-[#007fff]',
                isDropdownMenuVisible ? 'fill-[#007fff]' : 'fill-neutral-700',
              )}
            />
          </Button>
        </Dropdown>
        {isMobile && (
          <NestedDrawer
            menuItems={options}
            visible={mobileDrawerVisible}
            onClose={() => setMobileDrawerVisible(false)}
            title={mobileDrawerTitle}
            key={options.length}
          />
        )}
      </>
    );
  },
);

export default ResponsiveDropdown;
