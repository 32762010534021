import { BucketRequestBodyFunds } from '@aminsights/contract';
import { Bucket } from '@aminsights/contract';
import {
  LIMIT_FUNDS_PER_BUCKET,
  buildFundDetailsPath,
} from '@aminsights/shared';
import { Dropdown, Tooltip } from 'antd';
import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check.svg';
import { ReactComponent as IconFolder } from '@/assets/svg/icons/icon-folder.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import { APP_ACTIONS } from '@/constants';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { STATIC_DATA_BUCKET_LIMIT_MODAL } from '@/constants/modals-static-data';
import { useAppContext } from '@/context/AppContext';
import {
  useAddFundsToBucket,
  useAssignAsFocusFund,
  useChangeFundsBucket,
  useRemoveFundsFromBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import LimitReachModal from '@/partials/Modal/LimitReachModal';
import NestedDrawer from '@/partials/NestedDrawer';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import style from '../style.module.less';
import FundSearchBox from './FundSearchBox';
import TooltipRender from './ToolTipRender';

const RenderFunds: React.FCWithChild<{
  funds: BucketRequestBodyFunds[];
  bucketId: string;
  showActions?: boolean;
}> = ({ funds, bucketId, showActions = true }) => {
  const { dispatch: dispatchApp } = useAppContext();
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const assignAsFocusFund = useAssignAsFocusFund({
    onSuccess: () => {
      dispatchApp({
        icon: <IconCheck className="w-4 h-4" style={{ fill: 'white' }} />,
        type: !watchlistProps.isFeatured
          ? APP_ACTIONS.SET_SUCCESS_MESSAGE
          : APP_ACTIONS.SET_REGULAR_MESSAGE,
        payload: {
          text: `${watchlistProps.fundName} ${
            watchlistProps.isFeatured ? 'unassigned' : 'assigned'
          } as focus fund`,
        },
      });
    },
    onError: () => {
      dispatchApp({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: 'Error assign/unassign as focus fund',
      });
    },
  });
  const removeFundsFromBucket = useRemoveFundsFromBucket();
  const changeFundsBucket = useChangeFundsBucket();
  const addFundsToBucket = useAddFundsToBucket();
  const addFundCallback = (isins: string[]) => {
    addFundsToBucket.mutateAsync({
      bucketId,
      isins: isins,
    });
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const debouncedAddFundsToBucket = useCallback(
    debounce(addFundCallback, 100),
    [addFundsToBucket],
  );

  const [bucketsWithCurrentFund, setBucketsWithCurrentFund] = useState<
    Bucket[]
  >([]);
  const [isRemoveFromBucketModalOpen, setIsRemoveFromBucketModalOpen] =
    useState(false);
  const [isRemoveFromWatchlistModalOpen, setIsRemoveFromWatchlistModalOpen] =
    useState(false);
  const [watchlistProps, setWatchlistProps] = useState({
    isin: '',
    fundName: '',
    isFeatured: false,
  });
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [isSearchInputShown, setIsSearchInputShown] = useState(false);
  const [fundsFromCurrentBucket, setFundsFromCurrentBucket] = useState<
    BucketRequestBodyFunds[]
  >([]);

  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };

  const handleRemovefromBucketModalOpen = () => {
    setIsRemoveFromBucketModalOpen(prev => !prev);
    setBucketsWithCurrentFund([]);
  };

  const handleRemovefromWatclistOpen = () => {
    setIsRemoveFromWatchlistModalOpen(prev => !prev);
    setBucketsWithCurrentFund([]);
  };

  const currentBucket = buckets?.find(b => b.id === bucketId);

  const getMenuItems = (fund: BucketRequestBodyFunds) => {
    return [
      {
        label: (
          <ElementWithDataTestId
            label={`${fund.isFeatured ? 'Unassign' : 'Assign'} as focus fund`}
          />
        ),
        key: 'assignAsFocusFund',
        onClick: async () => {
          await assignAsFocusFund.mutateAsync([
            {
              bucketId: bucketId,
              isin: fund.isin,
              isFeatured: !fund.isFeatured,
            },
          ]);
          setIsNestedDrawerVisible(false);
        },
      },
      {
        label: <ElementWithDataTestId label={'Change Bucket'} />,
        key: 'changeBucket',
        children: buckets
          ?.filter(b => b.id !== bucketId)
          .map(b => ({
            label: b.name || '',
            key: b.id || '',
            onClick: async () => {
              if (b.funds && b.funds.length >= LIMIT_FUNDS_PER_BUCKET) {
                setIsLimitReachModalOpen(true);
              } else if (b.funds.find(f => f.isin === fund.isin)) {
                dispatchApp({
                  type: APP_ACTIONS.SET_ERROR_MESSAGE,
                  payload: `Bucket already contains ${fund.fundName}`,
                });
              } else {
                await changeFundsBucket.mutateAsync(
                  {
                    isins: [fund.isin],
                    bucketId: b.id || '',
                    previousBucketId: bucketId,
                  },
                  {
                    onError: () => {
                      dispatchApp({
                        type: APP_ACTIONS.SET_ERROR_MESSAGE,
                        payload: `Bucket can't be changed`,
                      });
                    },
                  },
                );
                dispatchApp({
                  type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                  payload: { text: `Successfully changed to ${b.name}` },
                });
                setIsNestedDrawerVisible(false);
              }
            },
          })),
      },
      {
        label: <ElementWithDataTestId label={'Remove from bucket'} />,
        key: 'removeFromBucket',
        onClick: () => {
          setIsRemoveFromBucketModalOpen(true);
          setIsNestedDrawerVisible(false);
        },
      },
      {
        label: <ElementWithDataTestId label={'Remove from watchlist'} />,
        key: 'removeFromWatchlist',
        onClick: () => {
          setIsRemoveFromWatchlistModalOpen(true);
          filterBucketsWithCurrentFund();
          setIsNestedDrawerVisible(false);
        },
      },
    ];
  };

  const [isLoadingData, setLoadingData] = useState(true);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingData(!isLoadingData);
    }, 300);
    return () => clearTimeout(timer);
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setFundsFromCurrentBucket(
      buckets?.find(b => b.id === bucketId)?.funds || [],
    );
  }, [buckets]);

  const onDeleteClick = async () => {
    await removeFundsFromBucket.mutateAsync({
      bucketId,
      isins: [watchlistProps.isin],
    });
  };

  const filterBucketsWithCurrentFund = (): void => {
    if (currentWatchlist && buckets) {
      const selectedFund = watchlistProps.isin;

      const selectedFundBucketRelated = buckets.filter(bucket =>
        bucket.funds.some(fund => fund.isin === selectedFund),
      );

      setBucketsWithCurrentFund(selectedFundBucketRelated);
    }
  };

  const deleteFromWatchlist = async () => {
    const bucketIdsWithFund = buckets
      ? buckets
          .filter(bucket =>
            bucket.funds.some(fund => fund.isin === watchlistProps.isin),
          )
          .map(bucket => bucket.id)
      : [];
    if (bucketIdsWithFund.length !== 0) {
      await Promise.all(
        bucketIdsWithFund.map(bucketId =>
          removeFundsFromBucket.mutateAsync({
            isins: watchlistProps.isin ? [watchlistProps.isin] : [],
            bucketId,
          }),
        ),
      );
    }
  };

  const handleSearchInputClose = () => {
    setIsSearchInputShown(false);
  };

  return (
    <>
      {funds
        .filter(d => d.fundName) // just hide funds without fund name (do not exist on the ES)
        .sort((a, b) => {
          if (a.fundName < b.fundName) {
            return -1;
          }
          if (a.fundName > b.fundName) {
            return 1;
          }
          return 0;
        })
        ?.map(d => (
          <Link to={buildFundDetailsPath(d.isin)} key={d.isin}>
            <div
              style={{
                backgroundColor: d.isFeatured ? COLOR_FUND_FEATURED() : '',
              }}
              className={style['bucket__body-fund']}
            >
              <div className="overflow-hidden">
                <TooltipRender
                  shareClassDetailsCode={d.shareClassDetailsCode}
                />
                <p className="text-neutral-500">{d.isin}</p>
              </div>
              <div
                onClick={e => {
                  e.stopPropagation();
                }}
                className="z-10"
              >
                <Dropdown
                  menu={{ items: isMobile ? [] : getMenuItems(d) }}
                  placement="bottomRight"
                  trigger={['click']}
                  disabled={!showActions}
                  onOpenChange={() => {
                    setWatchlistProps({
                      isin: d.isin,
                      fundName: d.fundName,
                      isFeatured: d.isFeatured || false,
                    });
                  }}
                >
                  <div data-test-id="editFundMeatballMenu">
                    <Tooltip
                      placement="bottomRight"
                      overlayClassName="max-w-60 md:max-w-none"
                      overlayInnerStyle={{
                        borderRadius: 4,
                        backgroundColor: '#313341',
                      }}
                      align={{ offset: [10, 10] }} // added value to ensure arrow is correctly aligned
                      title={
                        showActions
                          ? ''
                          : 'You don’t have edit rights to this bucket'
                      }
                    >
                      <MeatballMenu
                        width={20}
                        height={20}
                        className={`${
                          showActions
                            ? '!text-neutral-700'
                            : '!text-light cursor-not-allowed'
                        }`}
                        onClick={e => {
                          setWatchlistProps({
                            isin: d.isin,
                            fundName: d.fundName,
                            isFeatured: d.isFeatured || false,
                          });
                          isMobile && setIsNestedDrawerVisible(true);
                          e.preventDefault();
                          isMobile && e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                  </div>
                </Dropdown>
                {watchlistProps.isin === d.isin && isMobile && (
                  <NestedDrawer
                    menuItems={getMenuItems(d)}
                    visible={isNestedDrawerVisible}
                    onClose={() => setIsNestedDrawerVisible(false)}
                    title="Select"
                    key={getMenuItems(d).length}
                  />
                )}
              </div>
            </div>
          </Link>
        ))}
      {!showActions ||
      (currentBucket?.funds?.length ?? 0) >=
        LIMIT_FUNDS_PER_BUCKET ? null : isSearchInputShown ? (
        <FundSearchBox
          dataTestId={`addFundSearchBox-${currentBucket?.id}`}
          onClose={handleSearchInputClose}
          fundsFromCurrentBucket={fundsFromCurrentBucket}
          onAdd={isin => {
            debouncedAddFundsToBucket([isin]);
            setIsSearchInputShown(false);
          }}
          isLoading={addFundsToBucket.isLoading}
        />
      ) : (
        <div
          data-test-id="bucketAddFundBtn"
          className="py-1 text-sm font-medium cursor-pointer text-primary"
          role="button"
          onClick={() => setIsSearchInputShown(true)}
        >
          + Add fund
        </div>
      )}

      <ConfirmationModalDanger
        modalInfo={{
          title: `Remove '${watchlistProps.fundName}' from watchlist?`,
          description:
            'Removing a fund from your Watchlist will also remove it from the associated bucket(s).',
          primaryActionLabel: 'Remove',
          succesMessage: 'Fund removed from watchlist',
          errorMessage: 'Fund not removed from watchlist',
        }}
        className="max-sm:[&_.ant-modal-header]:shadow-sm max-sm:[&_.ant-modal-footer]:drop-shadow-md"
        isVisible={isRemoveFromWatchlistModalOpen}
        toggleModal={handleRemovefromWatclistOpen}
        onConfirm={deleteFromWatchlist}
      >
        <div className="flex flex-col mt-6 px-4 py-6 gap-2 w-auto rounded-lg overflow-y-auto border border-solid border-[#adadb3]">
          {bucketsWithCurrentFund?.map(bucket => (
            <div key={bucket.id} className="flex items-center h-6">
              <IconFolder className="mr-2" />
              <div className="text-sm text-darkest">{bucket.name}</div>
            </div>
          ))}
        </div>
      </ConfirmationModalDanger>

      <ConfirmationModalDanger
        modalInfo={{
          title: `Are you sure you want to remove '${watchlistProps.fundName}'?`,
          description:
            'If this is the only bucket the fund is in, it will also be removed from your watchlist.',
          primaryActionLabel: 'Remove',
          succesMessage: 'Successfully removed from bucket',
          errorMessage: `'${watchlistProps.fundName}' not removed`,
        }}
        isVisible={isRemoveFromBucketModalOpen}
        toggleModal={handleRemovefromBucketModalOpen}
        onConfirm={onDeleteClick}
      />
      <LimitReachModal
        isFundTrust
        staticData={STATIC_DATA_BUCKET_LIMIT_MODAL}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
      />
    </>
  );
};
export default RenderFunds;
