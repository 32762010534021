import { ChartDataResponseLegendData } from '@aminsights/contract';

import notEmpty from '@/utils/notEmpty';

export const getIdOrderByLegendData = (
  legendData: ChartDataResponseLegendData[] | undefined,
) => {
  const d = legendData ?? [];

  const sortedData = d.sort((a, b) => {
    const aValue = Number.parseFloat(a.value ?? '0');
    const bValue = Number.parseFloat(b.value ?? '0');
    return aValue < bValue ? 1 : -1;
  });

  const mappedIds = sortedData.map(f => f.id).filter(notEmpty);

  return mappedIds;
};
