import { Meeting } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import { ReactComponent as ArrowBackIcon } from '@/assets/svg/icons/icon-arrow-back.svg';

interface MeetingDetailModalProps {
  isVisible: boolean;
  meeting: Meeting | null;
  toggleModal: () => void;
  showBackButton?: boolean;
}

const MeetingDetailModal: FC<MeetingDetailModalProps> = ({
  isVisible,
  meeting,
  toggleModal,
  showBackButton,
}) => {
  if (!meeting) return null;

  return (
    <Modal
      open={isVisible}
      className={cx(
        'max-sm:full-page-modal',
        '[&_.ant-modal-body]:p-10 [&_.ant-modal-close]:mr-4',
      )}
      footer={null}
      onCancel={toggleModal}
    >
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          {showBackButton && (
            <ArrowBackIcon className="cursor-pointer" onClick={toggleModal} />
          )}
          <h2 className="text-xl text-neutral-200 font-bold">Meeting</h2>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-neutral-700 font-normal">
            Date: {dayjs(meeting.date).format(DISPLAY_DATE_FORMAT)}
          </p>
          <p className="text-sm text-neutral-700 font-normal">
            Time: {dayjs(meeting.date).format(DISPLAY_TIME_FORMAT)}
          </p>
          <p className="text-sm text-neutral-700 font-normal">
            Attendees:{' '}
            {meeting.attendees.map(attendee => attendee.name).join(', ')}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-neutral-200 font-bold">Meeting notes</p>
          <p className="w-full text-sm text-neutral-700 font-normal whitespace-pre-wrap">
            {meeting.note}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default MeetingDetailModal;
