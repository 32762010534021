import cx from 'classnames';
import React from 'react';

interface EmptyStateBaseProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode | string;
}

const EmptyStateBase: React.FCWithChild<EmptyStateBaseProps> = ({
  icon,
  title,
  subtitle,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      'flex flex-col items-center justify-center w-full text-center p-5 py-16 md:py-32',
      props.className,
    )}
  >
    {icon}
    <h3 className="text-xl font-medium mt-4 mb-2">{title}</h3>
    <p>{subtitle}</p>
  </div>
);

export default EmptyStateBase;
