import { EDateFilterValues } from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import { DATE_FORMAT_CHARTS } from '@/constants';
import useGetRiskReturnDataWithPortfolio from '@/hooks/query-hooks/chart-hooks/useGetRiskReturnDataWithPortfolio';
import {
  getInvestmentTrustRiskDetailsType,
  useChartSettingsState,
} from '@/hooks/useChartSettingsState';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import { BaseChartProps } from '@/partials/Charts/components/BaseChart';
import { STROKE_GREY } from '@/partials/Charts/utils/colors';
import { useChartColorsWithIsinsAndPortfolio } from '@/partials/Charts/utils/useChartColors';
import { useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';
import BaseScatterPlot from '@/partials/ScatterPlots/components/BaseScatterPlot';
import { ScatterPlotCardDetailsWithPortfolio } from '@/partials/ScatterPlots/utils/scatter-plot-data';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import { allowedPeriodValuesForRiskReturn } from './RiskReturnScatterPlot';

interface AbsoluteChartWithPortfolioProps extends BaseChartProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
  portfolios?: string[];
  riskReturnLatestDate?: string;
  updateRiskReturnLatestDate: (date?: string) => void;
}

const RiskReturnWithPortfolio: React.FCWithChild<
  AbsoluteChartWithPortfolioProps
> = ({
  showFundLinks = true,
  emptyState,
  onRemoveFund,
  isins,
  benchmarkId,
  sectorId,
  featuredIsins,
  portfolios,
  chartColors: chartColorsProps,
  riskReturnLatestDate,
  updateRiskReturnLatestDate,
}) => {
  const { value: datePickerValue } = useDatePickerContext();
  const { chartSettingsFilter } = useChartSettingsState();
  const riskDetailsType = getInvestmentTrustRiskDetailsType(
    chartSettingsFilter.investmentTrustsReturns,
  );

  const [legendData, setLegendData] = useState<
    ScatterPlotCardDetailsWithPortfolio[]
  >([]);

  const period = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const { data, isLoading } = useGetRiskReturnDataWithPortfolio({
    benchmarkId,
    sectorId,
    isins,
    riskDetailsType,
    featuredIsins,
    portfolios,
    period,
  });

  const chartColorsHook = useChartColorsWithIsinsAndPortfolio({
    isins,
    legend: data?.legendData || [],
    portfolioLegendIds: portfolios,
  });
  const scatterPlotColors =
    !chartColorsProps || Object.keys(chartColorsProps).length === 0
      ? chartColorsHook
      : chartColorsProps;

  const isChartLoaded = useMemo(() => {
    if (!isLoading && !!data) {
      if (scatterPlotColors) {
        return !!Object.keys(scatterPlotColors).length;
      }
      return true;
    }
    return false;
  }, [data, isLoading, scatterPlotColors]);

  const scatterPlotData = useMemo(() => {
    const sortedPlotData =
      data?.data?.sort((a, b) =>
        (a.standardDeviation || 0) < (b.standardDeviation || 0) ? 1 : -1,
      ) || [];

    return sortedPlotData.map(s => ({
      ...s,
      color: scatterPlotColors[s.id],
    }));
  }, [data, scatterPlotColors]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (data?.legendData?.length && scatterPlotData) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.tooltip || '',
          standardDeviation: f.xValue || 0,
          totalReturnOverPeriod: f.yValue || 0,
          id: f.id || '',
          label: f.label || '',
          color: scatterPlotColors[f.id || ''] || '',
          date: f.date ? dayjs(f.date).format(DATE_FORMAT_CHARTS) : '',
          dataTag: f.dataTag,
        })),
      );
    } else {
      setLegendData([]);
    }
  }, [data, scatterPlotColors]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isLoading) return;

    const to = data?.queriedDateRange?.to;
    if (to !== riskReturnLatestDate) {
      updateRiskReturnLatestDate(to);
    }
  }, [data, isLoading]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData,
      colorField: 'color',
      color: data => {
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  if (!scatterPlotData.length && !isLoading) {
    return (
      <ChartEmptyState
        subtitle={emptyState.subTitle}
        title={emptyState.title}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {isChartLoaded && (
        <div data-test-id="riskReturnChart">
          <BaseScatterPlot
            config={config}
            strokeColor={STROKE_GREY}
            onUpdateLegend={setLegendData}
          />
        </div>
      )}
      {(isLoading || !isChartLoaded) && (
        <img className="w-full mt-5 mb-5" src={loaderImage} />
      )}
      <div
        data-test-id="riskReturnWithPortfolioLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort((a, b) =>
              a.totalReturnOverPeriod > b.totalReturnOverPeriod ? -1 : 1,
            )
            .map(legendItem => (
              <ScatterPlotLegendCard
                key={legendItem.id}
                label={legendItem.label}
                tooltip={legendItem.tooltip || legendItem.label}
                color={legendItem.color}
                totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                standardDeviation={legendItem.standardDeviation}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                dataTag={legendItem.dataTag}
                id={getLegendIdToRemove(legendItem)}
                targetLink={
                  showFundLinks ? getLegendTargetLink(legendItem) : undefined
                }
                onRemove={onRemoveFund}
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                date={legendItem.date}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default RiskReturnWithPortfolio;
