import axios from 'axios';

export const WATCHLIST_ID_HEADER_NAME = 'X-Watchlist-Id';

let openApiFixupSetup = false;
export const setupOpenApiFixupInterceptor = () => {
  if (openApiFixupSetup) return;

  axios.interceptors.request.use(
    config => {
      if (
        config.headers['Content-Type'] === 'application/json' &&
        typeof config.data === 'string'
      ) {
        config.data = JSON.parse(config.data);
      }
      return config;
    },
    error => Promise.reject(error),
  );

  openApiFixupSetup = true;
};

let isOnErrorrSetup = false;
export const setupOnErrorInterceptor = (
  onError: (
    statusCode: string,
    statusText: string,
    correlationId: string,
    endpoint: string,
  ) => void,
) => {
  if (isOnErrorrSetup) return;

  axios.interceptors.response.use(
    response => response,
    error => {
      onError(
        error.response?.status,
        error.response?.data?.error || error.response?.statusText,
        error.response?.headers['x-corellation-id'],
        error.response?.config?.url,
      );
      return Promise.reject(error);
    },
  );
  isOnErrorrSetup = true;
};

let isAuthSetup = false;
export const setupAuthInterceptor = (
  getToken: () => Promise<string | null>,
) => {
  if (isAuthSetup) return;

  axios.interceptors.request.use(
    async config => {
      const token = await getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        throw new Error('Problem with token retrieval');
      }
      return config;
    },
    error => Promise.reject(error),
  );
  isAuthSetup = true;
};

let isWatchlistInterceptorSetup = false;
export const setupWatchlistInterceptor = (
  getCurrentWatchlistId: () => string | null,
) => {
  if (isWatchlistInterceptorSetup) return;

  axios.interceptors.request.use(
    config => {
      const watchlistId = getCurrentWatchlistId();
      if (watchlistId) {
        config.headers[WATCHLIST_ID_HEADER_NAME] = watchlistId;
      }
      return config;
    },
    error => Promise.reject(error),
  );
  isWatchlistInterceptorSetup = true;
};
