import { SignificantChangesItem } from '@aminsights/contract';
import { Modal } from 'antd';
import React, { useState } from 'react';

import { MarketDataChart } from '@/pages/app/Dashboard/Charts/MarketData';
import SignificantChangesModal from '@/partials/SignificantChanges/SignificantChangesContentModal';
import Summary from '@/partials/Summary';

import BasePageWithMetadata from '../BasePageWithMetadata';
import FundPerformance from './FundPerformance';
import { ViewMode } from './SignificantChanges/index';
import SignificantChangesWidget from './SignificantChanges/index';

const Dashboard: React.FCWithChild = () => {
  const [selectedSignificantChange, setSelectedSignificantChange] =
    useState<SignificantChangesItem | null>();

  return (
    <BasePageWithMetadata title={'Dashboard'}>
      <section>
        <div className="p-4 pl-0">
          <Summary />
        </div>
        <Modal
          title="Significant Changes"
          className="
            max-sm:full-page-modal information-modal [&_.ant-modal-body]:pb-10 [&_.ant-modal-header]:shadow-sm
            [&_.ant-modal-body]:pt-4
          "
          open={!!selectedSignificantChange}
          onClose={() => setSelectedSignificantChange(null)}
          footer={null}
          onCancel={() => setSelectedSignificantChange(null)}
        >
          {selectedSignificantChange && (
            <SignificantChangesModal
              data={selectedSignificantChange}
              toggleModal={() => setSelectedSignificantChange(null)}
            />
          )}
        </Modal>

        <div className="flex flex-col-reverse flex-wrap lg:flex-row lg:px-4">
          <div className="w-full lg:w-4/12">
            <SignificantChangesWidget
              onSignificantChangeClicked={sg =>
                setSelectedSignificantChange(sg)
              }
              mode={ViewMode.desktop}
            />
            <div className="px-4 pt-4 mb-6 bg-white lg:rounded-lg md:pt-0 pb-9">
              <MarketDataChart />
            </div>
          </div>

          <div className="w-full lg:w-8/12 lg:pl-4">
            <FundPerformance />
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Dashboard;
