import { Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';

export const ChangeBucketOrderColumns = (): Array<IDataTableColumns> => {
  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: 'Bucket Name',
      idSubText: 'bucketName',
      render: item => {
        return (
          <div className="flex items-center w-full select-none">
            <div className="text-sm font-bold text-darkest">{item.name}</div>
          </div>
        );
      },
      renderType: 'text',
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Benchmark',
      idSubText: 'benchmark',
      render: item => {
        return (
          <div className="flex items-center w-full select-none">
            <div className="sm:truncate text-neutral-700 text-sm">
              {item.index}
            </div>
          </div>
        );
      },
      renderType: 'text',
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Funds',
      sortKey: 'fundCount',
      idSubText: 'fundCount',
      render: item => {
        return (
          <div className="flex items-center w-full select-none">
            <div className="w-12 text-right text-sm">{item.funds.length}</div>
          </div>
        );
      },
      renderType: 'text',
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
  ];

  return columnDefinitions;
};
