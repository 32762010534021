import {
  Fund,
  FundRelativeRiskMeasuresDetail,
  FundRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  BenchmarkType,
  MsTimePeriod,
  isInvestmentTrust,
} from '@aminsights/shared';
import { FC, useEffect, useState } from 'react';

import { DataTable } from '@/components';

import RiskColumns from './CompareRiskColumns';
import { compareDataSort } from './utils/compareDataSort';

interface CompareRiskProps {
  funds: Fund[];
  dateRange: MsTimePeriod;
  loading?: boolean;
}

export interface IFundRiskData {
  key: string;
  name?: string;
  riskMeasuresDetail?: FundRiskMeasuresDetail;
  relativeRiskMeasuresDetail?: FundRelativeRiskMeasuresDetail;
  maximumDrawdown?: number;
}

const CompareRisk: FC<CompareRiskProps> = ({ funds, dateRange, loading }) => {
  const [sortedData, setSortedData] = useState<IFundRiskData[]>([]);

  const isAllInvestmentTrust = !!funds?.every(f =>
    isInvestmentTrust(f.legalStructure),
  );
  const getRiskMeasureData = (
    fund: Fund,
    benchmarkType: BenchmarkType,
    year: MsTimePeriod,
  ) => {
    if (fund) {
      const riskMeasuresDetailData = fund.riskMeasuresDetail?.find(
        r => r.timePeriod === year,
      );

      const relativeRiskMeasuresDetailData =
        fund.relativeRiskMeasuresDetail?.find(
          r => r.timePeriod === year && r.benchmarkType === benchmarkType,
        );

      const maximumDrawdown = riskMeasuresDetailData?.maximumDrawdown;

      return {
        key: fund._id,
        name: fund.shareClassDetails.code,
        riskMeasuresDetail: riskMeasuresDetailData,
        relativeRiskMeasuresDetail: relativeRiskMeasuresDetailData,
        maximumDrawdown,
      };
    }
    return {
      key: '',
      name: '',
      riskMeasuresDetail: {} as FundRiskMeasuresDetail,
      relativeRiskMeasuresDetail: {} as FundRelativeRiskMeasuresDetail,
      maximumDrawdown: undefined,
    };
  };

  const fundsRiskData: IFundRiskData[] = (funds || []).map(fund =>
    getRiskMeasureData(fund, BenchmarkType.MORNINGSTAR_CATEGORY, dateRange),
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    setSortedData([]);
  }, [dateRange]);

  return (
    <DataTable
      className="4xl:table-fixed"
      loading={loading}
      loaderSize={7}
      uniqueKey="key"
      columns={RiskColumns({
        isInvestmentTrust: isAllInvestmentTrust,
      })}
      onSort={async (
        key: string,
        direction: PageQueryParametersSortDirectionEnum,
      ) => {
        setSortedData(compareDataSort(fundsRiskData, key, direction));
      }}
      data={sortedData.length > 1 ? sortedData : fundsRiskData}
    />
  );
};

export default CompareRisk;
