import { SignificantChangesItem } from '@aminsights/contract';
import { EMPTY_DATA_POINT, getChangeTypeLabel } from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { DataTable } from '@/components/Table';
import GetChangeColumn from '@/partials/SignificantChanges/GetChangeColumn';

const columns = [
  {
    title: 'Latest updates',
    dataIndex: 'name',
    render: (item: SignificantChangesItem) => {
      return (
        <button type="button" className={cx('text-left w-full inline')}>
          <div className="custom-table__first-col">
            <div className="custom-table__indicator" />
            <div className="flex justify-between w-full">
              <div className="flex-1 flex flex-col">
                <p className="text-sm font-medium text-darkest pr-1">
                  {item.fundName}
                </p>
              </div>
              <div
                className="flex flex-col items-end"
                style={{ paddingTop: 3 }}
              >
                <div className="text-xs font-bold text-end">
                  <GetChangeColumn data={item} length={20} />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex-1 flex flex-col justify-center">
                {item.changeType && (
                  <p className="text-xs font-regular">
                    {getChangeTypeLabel(item.changeType)}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-center items-end">
                <p className="text-xxs font-regular text-neutral">
                  {item.updatedDate}
                </p>
              </div>
            </div>
          </div>
        </button>
      );
    },
    renderType: 'text' as const,
  },
];

type SignificantChangesTableProps = {
  data: SignificantChangesItem[];
  disabled?: boolean;
  loading?: boolean;
  loaderSize?: number;
  emptyState?: React.ReactChild | null | boolean;
  onRow: (id: string) => void;
};

const SignificantChangesTable: React.FCWithChild<
  SignificantChangesTableProps
> = ({ data, disabled, loading = false, loaderSize, emptyState, onRow }) => {
  const [significantChanges, setSignificantChanges] = useState<
    SignificantChangesItem[]
  >([]);

  useEffect(() => {
    if (data) {
      const composedSignificantChangesData = [];

      const significantChangesMap: SignificantChangesItem[] = data.map(
        significantChange => {
          return {
            ...significantChange,
            updatedDate: significantChange.updatedDate
              ? dayjs(significantChange.updatedDate).format(DISPLAY_DATE_FORMAT)
              : EMPTY_DATA_POINT,
            previousDate: significantChange.previousDate
              ? dayjs(significantChange.previousDate).format(
                  DISPLAY_DATE_FORMAT,
                )
              : EMPTY_DATA_POINT,
          };
        },
      );
      composedSignificantChangesData.push(...significantChangesMap);

      setSignificantChanges(composedSignificantChangesData);
    }
  }, [data]);

  return (
    <div className="disabled">
      <DataTable
        loading={loading}
        uniqueKey="_id"
        loaderSize={loaderSize}
        columns={columns}
        data={significantChanges}
        disabled={disabled}
        emptyState={emptyState}
        onRow={onRow}
      />
    </div>
  );
};

export default SignificantChangesTable;
