import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, Layout } from 'antd';
import React, { useState } from 'react';

import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import { Avatar } from '@/components';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

const { Header } = Layout;

const NavbarSimple: React.FCWithChild = () => {
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const { logout } = useAuth0();

  const handleClickLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const menuItems = [
    {
      label: 'Logout',
      key: 'logout',
      onClick: () => handleClickLogout(),
      'data-test-id': 'navLogoutButton',
    },
  ];

  return (
    <div className={style['simple-navbar-wrapper']}>
      <Header>
        <div className="w-11/12 flex justify-between">
          <div className="flex items-center justify-start">
            <div className={style['navbar-logo']}>
              <Logo />
            </div>
          </div>
          <div className={style['icons-wrapper']}>
            <Dropdown
              menu={{ items: isMobile ? [] : menuItems }}
              trigger={['click']}
              placement="bottomRight"
              className={style['navbar-profile']}
            >
              <div onClick={() => setIsNestedDrawerVisible(true)}>
                <Avatar type="user-avatar" color="sky-blue">
                  JG
                </Avatar>
              </div>
            </Dropdown>
            {isMobile && (
              <NestedDrawer
                menuItems={menuItems}
                visible={isNestedDrawerVisible}
                onClose={() => setIsNestedDrawerVisible(false)}
                title="Account"
              />
            )}
          </div>
        </div>
      </Header>
    </div>
  );
};

export default NavbarSimple;
