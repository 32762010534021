import {
  APP_ROUTE_CHARTS,
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_FUND,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_WATCHLIST,
  APP_ROUTE_WELCOME,
  AUTH_ROUTE_ACCESS_DENIED,
  AUTH_ROUTE_EMAIL_VERIFICATION,
} from '@aminsights/shared';
import { useAuth0 } from '@auth0/auth0-react';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { History } from 'history';
import React, { useEffect, useMemo } from 'react';
import {
  Switch as ReactSwitch,
  Redirect,
  Route,
  Router,
} from 'react-router-dom';

import { getConfigValue } from '@/constants';
import { AnalyticsProvider } from '@/context/AnalyticsContext';
import { AxiosAuthProvider } from '@/context/AxiosAuthContext';
import { FeatureSwitchProvider } from '@/context/FeatureSwitchContext';
import useChangePWATheme from '@/hooks/useChangePWATheme';
import usePWABadge from '@/hooks/usePWABadge';
import DashboardLayout from '@/layouts/Dashboard';
import ChartingTool from '@/pages/app/Charts';
import Dashboard from '@/pages/app/Dashboard';
import NotFoundPage from '@/pages/app/Error/NotFound';
import Explore from '@/pages/app/Explore';
import { ExploreProvider } from '@/pages/app/Explore/context';
import FundOrInvestmentDetails from '@/pages/app/FundAndInvestmentTrust';
import CompareFundPage from '@/pages/app/FundAndInvestmentTrust/components/CompareTool/CompareFundPage';
import Portfolio from '@/pages/app/Portfolio/Portfolio';
import { PortfolioProvider } from '@/pages/app/Portfolio/context';
import Settings from '@/pages/app/Settings';
import SignificantChangesPage from '@/pages/app/SignificantChanges';
import { SignificantChangesProvider } from '@/pages/app/SignificantChanges/Context';
import Watchlist from '@/pages/app/Watchlist/Watchlist';
import Welcome from '@/pages/app/Welcome';
import AccessDenied from '@/pages/auth/AccessDenied';
import { VerificationEmail } from '@/pages/auth/Verification';
import ShareBucketVerification from '@/pages/claim-ticket/ShareBucketVerification';
import SharePortfolioInvitation from '@/pages/claim-ticket/SharePortfolioInvitation';
import { DatePickerProvider } from '@/partials/DatePicker';
import { SearchSCProvider } from '@/partials/SearchBox/context';

import PublicRoute from './PublicRoute';
import WatchlistGuard from './WatchlistGuard';
import { IRoute, PATHS_WITH_LAYOUT } from './routes';

export const ComponentWrapper =
  (props: { component: React.ComponentType }) => () => {
    usePWABadge();
    const ProtectedComponent = props.component;
    return (
      <DatePickerProvider>
        <ProtectedComponent />
      </DatePickerProvider>
    );
  };

const ProtectedRoute = (props: {
  exact?: boolean;
  component: React.ComponentType;
  path: string | string[];
}) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Route
      exact={props.exact}
      path={props.path}
      component={
        isAuthenticated
          ? ComponentWrapper({
              component: props.component,
            })
          : () => <Redirect to="/" />
      }
    />
  );
};

const Switch: React.FCWithChild = ({ children }) => {
  useChangePWATheme();

  useEffect(() => {
    // Remove loader
    const loaderElem = document.querySelector('.loader-bg');
    loaderElem?.remove();
  }, []);
  return <ReactSwitch>{children}</ReactSwitch>;
};

export const SubRouter: React.FCWithChild<{
  routes: IRoute[];
  rootPath: string;
}> = ({ children, routes, rootPath }) => (
  <ReactSwitch>
    {/* This is required as Switch seems to render only "one" child */}
    <>
      {routes.map(r => (
        <Route
          key={r.path}
          exact
          path={r.path ? `/${rootPath}/${r.path}` : `/${rootPath}`}
          component={r.component}
        />
      ))}
      {children}
    </>
  </ReactSwitch>
);

const MainRouter = ({ history }: { history: History<unknown> }) => {
  const currentPath = window.location.pathname.substring(1);
  const isPublic = [
    AUTH_ROUTE_EMAIL_VERIFICATION,
    AUTH_ROUTE_ACCESS_DENIED,
  ].includes(currentPath);
  const { user } = useAuth0();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const sdkConfig = useMemo(() => {
    return {
      core: {
        authorizationKey: getConfigValue('REACT_APP_SPLIT_BROWSER_API_KEY'),
        key: user?.sub || '',
      },
    };
  }, [getConfigValue('REACT_APP_SPLIT_BROWSER_API_KEY'), user?.sub]);

  return (
    <AnalyticsProvider>
      {isPublic ? (
        <Router history={history}>
          <Switch>
            <PublicRoute path={`/${AUTH_ROUTE_EMAIL_VERIFICATION}`}>
              <VerificationEmail />
            </PublicRoute>
            <PublicRoute path={`/${AUTH_ROUTE_ACCESS_DENIED}`}>
              <AccessDenied />
            </PublicRoute>
          </Switch>
        </Router>
      ) : (
        <AxiosAuthProvider>
          <WatchlistGuard>
            <ExploreProvider>
              <SignificantChangesProvider>
                <SearchSCProvider>
                  <SplitFactoryProvider config={sdkConfig}>
                    <FeatureSwitchProvider>
                      <Router history={history}>
                        <Switch>
                          <Route path={PATHS_WITH_LAYOUT} exact>
                            <DashboardLayout>
                              <ProtectedRoute
                                path={`/${APP_ROUTE_DASHBOARD}`}
                                component={Dashboard}
                              />
                              <ProtectedRoute
                                path={`/${APP_ROUTE_FUND}/compare`}
                                component={CompareFundPage}
                              />
                              <SignificantChangesProvider>
                                <ProtectedRoute
                                  path={`/${APP_ROUTE_FUND}`}
                                  component={FundOrInvestmentDetails}
                                />
                              </SignificantChangesProvider>
                              <ProtectedRoute
                                path={`/${APP_ROUTE_WATCHLIST}`}
                                component={Watchlist}
                              />
                              <PortfolioProvider>
                                <ProtectedRoute
                                  path={`/${APP_ROUTE_PORTFOLIOS}`}
                                  component={Portfolio}
                                />
                              </PortfolioProvider>
                              <ProtectedRoute
                                path={`/${APP_ROUTE_EXPLORE}`}
                                component={Explore}
                              />
                              <ProtectedRoute
                                path={`/${APP_ROUTE_CHARTS}/`}
                                component={ChartingTool}
                              />
                              <ProtectedRoute
                                path={`/${APP_ROUTE_SETTINGS}`}
                                component={Settings}
                              />
                              <ProtectedRoute
                                path={`/${APP_ROUTE_SIGNIFICANT_CHANGES}`}
                                component={SignificantChangesPage}
                              />
                            </DashboardLayout>
                          </Route>
                          {/* Protected routes without layout */}
                          <ProtectedRoute
                            path={'/bucket/accept-invitation'}
                            component={ShareBucketVerification}
                          />
                          <ProtectedRoute
                            path={'/portfolios/invitation'}
                            component={SharePortfolioInvitation}
                          />
                          <ProtectedRoute
                            path={`/${APP_ROUTE_WELCOME}`}
                            component={Welcome}
                          />
                          <Route
                            path="/"
                            exact
                            strict
                            render={() => (
                              <Redirect to={`/${APP_ROUTE_DASHBOARD}`} />
                            )}
                          />
                          <Route path="/*" component={NotFoundPage} />
                        </Switch>
                      </Router>
                    </FeatureSwitchProvider>
                  </SplitFactoryProvider>
                </SearchSCProvider>
              </SignificantChangesProvider>
            </ExploreProvider>
          </WatchlistGuard>
        </AxiosAuthProvider>
      )}
    </AnalyticsProvider>
  );
};

export default MainRouter;
