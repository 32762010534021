export const AUTH_ROUTE_FORGOT_PASSWORD = 'forgot-password';
export const AUTH_ROUTE_RESET_PASSWORD = 'reset-password';
export const AUTH_ROUTE_REQUEST_ACCOUNT = 'request-account';
export const AUTH_ROUTE_WELCOME_SETUP = 'setup-account';
export const AUTH_ROUTE_EMAIL_VERIFICATION = 'email-verification';
export const AUTH_ROUTE_ACCESS_DENIED = 'access-denied';

export const APP_ROUTE_WELCOME = 'welcome';
export const APP_ROUTE_DASHBOARD = 'dashboard';
export const APP_ROUTE_FUND = 'fund';
export const APP_ROUTE_WATCHLIST = 'watchlist';
export const APP_ROUTE_PORTFOLIOS = 'portfolios';
export const APP_ROUTE_SIGNIFICANT_CHANGES = 'significant-changes';
export const APP_ROUTE_EXPLORE = 'explore';
export const APP_ROUTE_SETTINGS = 'settings';
export const APP_ROUTE_CHARTS = 'charts';
export const APP_ROUTE_HELP = 'help';

export const APP_ROUTES = [
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_FUND,
  APP_ROUTE_WATCHLIST,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_CHARTS,
];

export const buildFundDetailsPath = (isin: string) => {
  return `/fund/${isin.toUpperCase()}/details/summary`;
};
