import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './index.less';

import { setupOpenApiFixupInterceptor } from '@aminsights/contract';
import { APP_ROUTE_DASHBOARD } from '@aminsights/shared';
import { StyleProvider } from '@ant-design/cssinjs';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import React, { useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

import { AUTH0_BROADCAST_CHANNEL, AUTH0_LOGIN_EVENT } from './constants';
import { getConfigValue } from './constants/configs';
import { AppProvider } from './context/AppContext';
import TopErrorBoundary from './error-boudaries/TopErrorBoundary';
import queryClient from './queryClient';
import MainRouter from './router';
import { useScript } from './utils/useScript';

export const history = createBrowserHistory();

const Main: React.FCWithChild = () => {
  useScript(`/newrelic.${getConfigValue('REACT_APP_NODE_ENV')}.js`, 'newrelic');
  const domain = useMemo(
    () => getConfigValue('REACT_APP_AUTH0_CUSTOM_DOMAIN'),
    [],
  );
  const clientId = useMemo(
    () => getConfigValue('REACT_APP_AUTH0_CLIENT_ID'),
    [],
  );
  const connection = useMemo(
    () => getConfigValue('REACT_APP_AUTH0_CONNECTION_NAME'),
    [],
  );
  const audience = useMemo(
    () => getConfigValue('REACT_APP_AUTH0_AUDIENCE'),
    [],
  );

  const broadcastChannel = useMemo(() => {
    if ('BroadcastChannel' in window) {
      return new BroadcastChannel(AUTH0_BROADCAST_CHANNEL);
    }
  }, []);

  useEffect(() => {
    if (!broadcastChannel) return;

    broadcastChannel.onmessage = event => {
      if (event.data === AUTH0_LOGIN_EVENT)
        window.location.href = window.location.origin;
    };

    () => {
      broadcastChannel?.close();
    };
  }, [broadcastChannel]);

  const onRedirectCallback = (appState?: AppState) => {
    // broadcast auth0 login event to all tabs
    broadcastChannel?.postMessage(AUTH0_LOGIN_EVENT);

    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <StyleProvider layer>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/${APP_ROUTE_DASHBOARD}`,
          connection: connection,
          audience: audience,
        }}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        useRefreshTokensFallback={true}
        useRefreshTokens={true}
      >
        <TopErrorBoundary>
          <AppProvider>
            <QueryClientProvider client={queryClient}>
              <MainRouter history={history} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AppProvider>
        </TopErrorBoundary>
      </Auth0Provider>
    </StyleProvider>
  );
};

async function bootstrapApp() {
  // this is a workaround related to an issue in openapi-generator for openapi-client-axios https://github.com/OpenAPITools/openapi-generator/issues/5717
  // basically it is serializing json body unnecessarily
  setupOpenApiFixupInterceptor();
  const container = document.getElementById('root');
  if (!container) throw new Error('Root element not found');
  const root = createRoot(container); // proof we are on 18 now
  root.render(<Main />);
}

bootstrapApp();
