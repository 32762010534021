import { useEffect, useState } from 'react';

import { Table } from '@/components/Table';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { infoStatsFeesColumns, tansformInfoStatsFeesData } from './utils';

const InfoStatsFees = () => {
  const { data: state } = useFundByIsInParam();
  const [infoStatsFeesData, setInfoStatsFeesData] = useState<
    {
      type: string;
      info: string | number;
    }[]
  >([]);

  useEffect(() => {
    if (state?.fund) {
      setInfoStatsFeesData(tansformInfoStatsFeesData(state.fund));
    }
  }, [state?.fund]);

  return (
    <div
      className="
        [&_.ant-table-tbody_>_tr_>_td]:min-h-[56px] 
        [&_.ant-table-tbody_>_tr_>_td:first-child]:font-bold 
        [&_.ant-table-tbody_>_tr_>_td:first-child]:text-darkest 
        [&_.ant-table-thead_th.ant-table-cell]:text-xs 
        [&_.ant-table-thead_th.ant-table-cell]:font-normal 
        [&_.ant-table-thead_th.ant-table-cell]:text-neutral 
        [&_.ant-table-thead_th.ant-table-cell]:py-2 
        [&_.ant-table-thead_th.ant-table-cell]:bg-grey-lighter
        [&_.ant-table-thead_.ant-table-cell:first-child::before]:hidden
      "
    >
      <Table
        columns={infoStatsFeesColumns}
        data={infoStatsFeesData}
        paginateTotalCount={0}
        uniqueKey="type"
      />
    </div>
  );
};

export default InfoStatsFees;
