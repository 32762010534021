import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Dropdown } from 'antd';
import { useContext, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Option } from '@/components';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import { WATCHLIST_TAB_GROUP } from '../WatchlistTabItems';

const EquityMenu = () => {
  const { setActiveTab, activeTabMenuItem, setActiveTabMenuItem } =
    useContext(TabContext);
  const currentBucketId = useCurrentBucketId();
  const { data: tabsToShow } = useTabsToShowOnWatchlist(currentBucketId);

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const formatLabel = (value: string | undefined) => {
    switch (value) {
      case WATCHLIST_TAB_KEY.STYLE_EQ:
        return 'EQ: Style';
      case WATCHLIST_TAB_KEY.SECTORS_EQ:
        return 'EQ: Sectors';
      case WATCHLIST_TAB_KEY.GEOGRAPHY_EQ:
        return 'EQ: Geog';
      case WATCHLIST_TAB_KEY.MARKET_CAP:
        return 'EQ: Market Cap';
      case WATCHLIST_TAB_KEY.EQUITY_STATS:
        return 'EQ: Equity Stats';
      default:
        return 'Equity';
    }
  };

  const menuOptions = [
    {
      value: WATCHLIST_TAB_KEY.STYLE_EQ,
      label: 'Style',
    },
    {
      value: WATCHLIST_TAB_KEY.SECTORS_EQ,
      label: 'Sectors',
    },
    {
      value: WATCHLIST_TAB_KEY.GEOGRAPHY_EQ,
      label: 'Geog',
    },
    {
      value: WATCHLIST_TAB_KEY.MARKET_CAP,
      label: 'Market Cap',
    },
    {
      value: WATCHLIST_TAB_KEY.EQUITY_STATS,
      label: 'Equity Stats',
    },
  ].filter(option => (tabsToShow ? tabsToShow.includes(option.value) : true));

  if (!menuOptions.length) {
    return null;
  }

  const menuItems = menuOptions.map(option => {
    const isActive = option.value === activeTabMenuItem;
    return {
      key: option.value,
      label: (
        <Option
          isActive={isActive && !isMobile}
          dataTestId={`tabGroupEquity-${option.value}`}
          label={option.label}
        />
      ),
      selected: isActive,
      onClick: () => {
        setActiveTab(WATCHLIST_TAB_GROUP.EQUITY);
        setActiveTabMenuItem(option.value);
      },
    };
  });

  return (
    <div>
      <Dropdown
        trigger={['hover', 'click']}
        placement="bottomLeft"
        menu={{ items: isMobile ? [] : menuItems }}
      >
        <div
          className="flex items-center justify-start gap-x-3"
          onClick={() => {
            isMobile && setIsNestedDrawerVisible(true);
          }}
        >
          {formatLabel(activeTabMenuItem)}{' '}
          <NextArrow className="h-3 w-3 fill-current text-[#545576] transform rotate-90" />
        </div>
      </Dropdown>
      {isMobile && (
        <NestedDrawer
          menuItems={menuItems}
          visible={isNestedDrawerVisible}
          onClose={() => setIsNestedDrawerVisible(false)}
          title="Equity"
        />
      )}
    </div>
  );
};

export default EquityMenu;
