import { PortfoliosApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { PORTFOLIO_EDGE_DATES } from './query-keys';

type GetPortfolioEdgeDatesParams = {
  portfolioId: string | undefined;
};

// For some reason OpenApi cannot generate types for additionaProperties in yml
export interface PortfolioEdgeDates {
  [isin: string]: {
    startDate?: {
      secId: string;
      performanceId: string;
      date: string;
      unitGbp: string;
      returnType: number;
      chartSeriesIdentifier: string;
    };
    endDate?: {
      secId: string;
      performanceId: string;
      date: string;
      unitGbp: string;
      returnType: number;
      chartSeriesIdentifier: string;
    };
  };
}

const usePortfolioEdgeDates = (
  params?: GetPortfolioEdgeDatesParams,
  options?: Omit<
    UseQueryOptions<PortfolioEdgeDates | null>,
    'queryKey' | 'queryFn'
  >,
) => {
  const portfolioApi = new PortfoliosApi(openApiConfig());
  return useQuery<PortfolioEdgeDates | null>(
    [PORTFOLIO_EDGE_DATES, params],
    async () => {
      if (!params?.portfolioId) {
        return null;
      }

      const response = await portfolioApi.getEdgeDatesByPortfolioId(
        params?.portfolioId,
      );

      return response.data as PortfolioEdgeDates;
    },
    options,
  );
};

export default usePortfolioEdgeDates;
