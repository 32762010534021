import { ChartDataItem, ChartDataSeries } from '@aminsights/contract';

// The only difference from above is we're not doing transformation on dates
export const boxChartDataSeriesToChartDataItems = (
  data: ChartDataSeries[],
  /**
   * Warning this property is not yet flexible, it'll override all the items with a static
   * object. Ideally its a function but we're trying to reduce runtime for now.
   */
  overrideProps?: Partial<ChartDataItem>,
): ChartDataItem[] => {
  const boxedItems: ChartDataItem[] = [];

  data.forEach(r => {
    const targetDataItem = boxedItems.find(l => l.i === r.id);
    if (targetDataItem) {
      targetDataItem.p?.push({
        d: r.date,
        v: r.value,
      });
    } else {
      const newItem: ChartDataItem = {
        i: r.id,
        l: r.label,
        t: r.tooltip,
        dt: r.dataTag,
        ...overrideProps, // We may want to replace some properties depending on the chart type
        p: [
          {
            d: r.date,
            v: r.value,
          },
        ],
      };
      boxedItems.push(newItem);
    }
  });
  return boxedItems;
};

export const unboxChartDataItemsToChartDataSeries = (data: ChartDataItem[]) => {
  const unboxedData: ChartDataSeries[] = [];
  for (const item of data) {
    const dataPoints = item.p?.map((point: { d: string; v: number }) => {
      const output = {
        date: point.d,
        value: point.v,
        label: item.l,
        tooltip: item.t,
        id: item.i,
        dataTag: item.dt,
      };
      return output;
    });
    unboxedData.push(...(dataPoints ?? []));
  }
  return unboxedData;
};
