import { WatchlistApi, WatchlistBenchmarkResponse } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { BENCHMARK, WATCHLIST } from './query-keys';

const useBenchmarkBySecId = (
  secId: string | null | undefined,
  options?: Omit<
    UseQueryOptions<WatchlistBenchmarkResponse | null, unknown>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  return useQuery<WatchlistBenchmarkResponse | null, unknown>(
    [WATCHLIST, BENCHMARK, secId],
    async () => {
      const watchlistApi = new WatchlistApi(openApiConfig());
      if (secId) {
        const result = await watchlistApi.getBucketBenchmark(secId);
        return result.data;
      }
      return null;
    },
    options,
  );
};

export default useBenchmarkBySecId;
