import { BucketRequestBodyFunds } from '@aminsights/contract';
import { LEGAL_STRUCTURE } from '@aminsights/shared';
import { Dispatch, SetStateAction } from 'react';

import { ERROR_ISIN_ALREADY_EXISTS_IN_BUCKETS } from '@/constants/text-messages';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

const FundSearchBox = ({
  onClose,
  fundsFromCurrentBucket,
  isLoading,
  onAdd,
  dataTestId,
}: {
  onClose: () => void;
  isLoading?: boolean;
  fundsFromCurrentBucket: BucketRequestBodyFunds[];
  onAdd: (isin: string) => void;
  dataTestId?: string;
}) => {
  const searchConfig = useSearch();
  const searchBoxOpts = useSearchBox({
    onClose: () => {},
    defaultValue: '',
    searchConfig,
  });
  const resultOpts = searchBoxOpts.allSuggestions.map(opt =>
    renderOptions({
      value: opt._id,
      title: opt.shareClassDetails.code,
      highlightTerm: searchBoxOpts.searchValue,
      primaryShareClass: opt.primaryShareClass,
      isLegalStructureMPS: LEGAL_STRUCTURE.MPS === opt.legalStructure,
    }),
  );
  const handleOnSearchResults = async (
    term?: string,
    onError?: Dispatch<SetStateAction<string | null>>,
  ) => {
    if (term) {
      if (fundsFromCurrentBucket.some(f => f.isin === term)) {
        onError?.(ERROR_ISIN_ALREADY_EXISTS_IN_BUCKETS);
        return;
      }
      const selectedFund = searchBoxOpts.allSuggestions.find(
        f => f._id === term,
      );
      if (selectedFund && !isLoading) {
        onAdd(selectedFund._id);
      }
    }
  };
  return (
    <ExploreSearchV2
      dataTestId={dataTestId}
      value={searchBoxOpts.searchValue}
      onChange={searchBoxOpts.onChange}
      onBlur={onClose}
      onSelect={handleOnSearchResults}
      onKeyEnter={handleOnSearchResults}
      onSubmit={handleOnSearchResults}
      options={resultOpts}
      placeholder="Search Fund Name/ISIN"
      loading={searchBoxOpts.isLoading}
    />
  );
};

export default FundSearchBox;
