import { Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import SettingsRouter from '@/router/Settings';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';

import BasePageWithMetadata from '../BasePageWithMetadata';

const Settings: React.FCWithChild = () => {
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [metaTitle, setMetaTitle] = useState<string>('');
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const SETTINGS_ROUTES = useMemo(
    () =>
      [
        {
          label: (
            <Link to={'/settings/profile'}>
              <span data-test-id="settingsTabProfile">Profile</span>
            </Link>
          ),
          value: 'profile',
          disabled: false,
        },
        {
          label: (
            <Link to={'/settings/security'}>
              <span data-test-id="settingsTabSecurity">Security</span>
            </Link>
          ),
          value: 'security',
          disabled: false,
        },
        {
          label: (
            <Link to={'/settings/significant-changes'}>
              <span data-test-id="settingsTabSignificantChanges">
                Significant Changes
              </span>
            </Link>
          ),
          value: 'significant-changes',
        },
        {
          label: (
            <Link to={'/settings/custom-benchmarks'}>
              <span data-test-id="settingsTabCustomBenchmarks">
                Custom Benchmarks
              </span>
            </Link>
          ),
          value: 'custom-benchmarks',
          isRestrictedFeature: isAppLimitedAccessEnabled,
        },
        {
          label: (
            <Link to={'/settings/notifications'}>
              <span data-test-id="settingsTabNotifications">Notifications</span>
            </Link>
          ),
          value: 'notifications',
        },
      ].filter(({ isRestrictedFeature }) => !isRestrictedFeature),
    [],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const { pathname } = location;
    const currentTab = [
      'profile',
      ...SETTINGS_ROUTES.map(({ value }) => value),
    ].find(word => pathname.includes(word));
    setActiveTab(currentTab);
    setMetaTitle(
      `Settings${
        currentTab
          ? ` - ${currentTab
              .split('-')
              .map(word => capitalizeFirstLetter(word))
              .join(' ')}`
          : ''
      }`,
    );
  }, [location.pathname]);

  return (
    <BasePageWithMetadata title={metaTitle}>
      <div className="pt-6">
        <h3
          className="px-4 text-xl font-bold leading-[var(--line-height-lg)] mb-4"
          data-test-id="settingsPageTitle"
        >
          Settings
        </h3>
        <div className="pt-1 pb-6 mb-6 bg-white lg:mx-4 lg:rounded-lg">
          <Tabs
            activeKey={activeTab}
            items={SETTINGS_ROUTES.map(settings => ({
              label: settings.label,
              key: settings.value,
              disabled: settings.disabled,
            }))}
            className="[&_.ant-tabs-nav-more]:hidden [&_.ant-tabs-tab:nth-last-child(2)]:mr-4"
          />
          <div className="section-watchlist-inner">
            <SettingsRouter />
          </div>
        </div>
      </div>
    </BasePageWithMetadata>
  );
};

export default Settings;
