import { Fund, FundsApi, GetFundByIsinResponse } from '@aminsights/contract';
import { FundType, getFundType } from '@aminsights/shared';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { staleTime1Hour } from '@/queryClient';
import { openApiConfig } from '@/utils';

import useGetCurrencyRates from '../currency-hooks/useGetCurrencyRates';

type UseFundByIsinResponse = GetFundByIsinResponse;
type UseFundByIsinError = unknown; // If API had error types place here
export type UseFundByIsinOptions = Omit<
  UseQueryOptions<UseFundByIsinResponse, UseFundByIsinError>,
  'queryKey' | 'queryFn' | 'initialData'
>; // Probably won't be used often but its what makes react-query so easy to tweak as needed

type UseGenerateReportOptions = {
  isin: string;
  options?: any;
  fundName: string;
};

type UseMultipleFundsByIsinsResponse = Fund[];
type UseMultipleFundsByIsinsOptions = Omit<
  UseQueryOptions<UseMultipleFundsByIsinsResponse, UseFundByIsinError>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useFundByIsin = (
  isin: string | null | undefined,
  options?: UseFundByIsinOptions,
) => {
  return useQuery<UseFundByIsinResponse, UseFundByIsinError>(
    ['fund', isin],
    async () => {
      const fundApi = new FundsApi(openApiConfig());
      if (isin) {
        const result = await fundApi.getFundByIsin(isin, {
          params: { shareClasses: true },
        });

        return result.data;
      } else {
        throw new Error('No isin provided'); // handle with <return value>.error || <return value>.isError
      }
    },
    options,
  );
};

export const useGetFundsToCompare = (
  isins: string[] | null | undefined,
  options: UseMultipleFundsByIsinsOptions = { refetchOnWindowFocus: false },
) => {
  return useQuery<UseMultipleFundsByIsinsResponse, UseFundByIsinError>(
    ['funds-to-compare', isins],
    async () => {
      const fundApi = new FundsApi(openApiConfig());
      if (isins?.length) {
        const result = await fundApi.getFundsToCompare(isins);
        return result.data || [];
      }
      return [];
    },
    { staleTime: staleTime1Hour, ...options },
  );
};

export const useMultipleFundsByIsins = (
  isins: string[] | null | undefined,
  options: UseMultipleFundsByIsinsOptions = { refetchOnWindowFocus: false },
) => {
  return useQuery<UseMultipleFundsByIsinsResponse, UseFundByIsinError>(
    ['fund', isins],
    async () => {
      const fundApi = new FundsApi(openApiConfig());
      if (isins && isins.length !== 0) {
        const result = await fundApi.getMultipleFunds(isins, {
          params: { shareClasses: true },
        });
        return result.data;
      } else {
        console.error('No isins were provided for useMultipleFundsByIsins');
        return [];
      }
    },
    { staleTime: staleTime1Hour, ...options },
  );
};

/**
 * Rather than adding this logic to the hook, we compose a new one, this will prevent bugs where
 * `useParam` may pass `id` when that `id` can mean something other than isin
 */
export const useFundByIsInParam = () => {
  const { id } = useParams<{ id: string }>();
  return useFundByIsin(id, { refetchOnWindowFocus: false });
};

export const useFundIsInvestmentTrust = () => {
  const { id } = useParams<{ id: string }>();
  const response = useFundByIsin(id, { enabled: false });
  return (
    response.data?.fund &&
    getFundType(response.data.fund.legalStructure) === FundType.INVESTMENT_TRUST
  );
};

export const useGenerateReport = (
  ops?: UseMutationOptions<
    AxiosResponse<any>,
    unknown,
    UseGenerateReportOptions
  >,
) => {
  const { dispatch: dispatchApp } = useAppContext();
  const fundApi = new FundsApi(openApiConfig());
  const { data } = useGetCurrencyRates();
  const mutation = useMutation(
    async (options: UseGenerateReportOptions) => {
      if (options.isin && data?.currencyCode) {
        dispatchApp({
          type: APP_ACTIONS.SET_REGULAR_MESSAGE,
          payload: {
            text: 'Downloading report...',
            shouldUseTimer: false,
            showSnackbarIcon: false,
          },
        });
        const result = await fundApi.generateReport(
          options.isin,
          data?.currencyCode,
          {
            responseType: 'arraybuffer',
          },
        );
        return result;
      } else {
        throw new Error('No isin or currencyCode provided'); // handle with <return value>.error || <return value>.isError
      }
    },
    {
      onSuccess: (response, options) => {
        if (response?.data) {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          window.open(URL.createObjectURL(blob), '_blank');

          saveAs(blob, `${options.fundName}.pdf`);
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Report is downloaded' },
          });
        }
        return;
      },
      ...ops,
    },
  );

  return mutation;
};

export default useFundByIsin;
