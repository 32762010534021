import { SLIDER_MIN } from '@/constants';

export const ocfRangeMarks: Record<number, string> = {
  0: '0',
  16: '0.25%',
  32: '0.5%',
  48: '0.75%',
  64: '1%',
  80: '1.25%',
  96: '1.5%',
  112: '1.75%>',
};

export const OCF_SLIDER_MAX = 112;

export interface OCFState {
  ocfRange: number[];
}

export const ocfInitialState: OCFState = {
  ocfRange: [SLIDER_MIN, OCF_SLIDER_MAX],
};

const parseOcfValue = (val: string) => Number.parseFloat(val.split('%')[0]);

export const convertOcfToUrl = (ocfValues?: number[]): number[] | undefined => {
  if (ocfValues === undefined) return undefined;

  const [ocfFrom, ocfTo] = ocfValues;

  if (ocfTo === 0 && ocfFrom === 0) return undefined;

  return [
    parseOcfValue(ocfRangeMarks[ocfFrom]),
    parseOcfValue(ocfRangeMarks[ocfTo]) === 1.75
      ? Number.MAX_SAFE_INTEGER
      : parseOcfValue(ocfRangeMarks[ocfTo]),
  ];
};

export const convertUrlToOcf = (ocfValues?: number[]): number[] | undefined => {
  if (ocfValues === undefined) return [];

  const ocfFrom = ocfValues[0];
  let ocfTo = ocfValues[1];

  if (ocfTo === Number.MAX_SAFE_INTEGER) {
    ocfTo = 1.75;
  }

  const test = Object.entries(ocfRangeMarks);

  const from = test.find(([_key, value]) => parseOcfValue(value) === ocfFrom);
  const to = test.find(([_key, value]) => parseOcfValue(value) === ocfTo);
  if (from && to) {
    return [Number(from[0]), Number(to[0])];
  } else {
    return [];
  }
};
