import {
  GetUserProfileResponse,
  SettingsApi,
  UserProfile,
} from '@aminsights/contract';
import { useEffect, useState } from 'react';

import { openApiConfig } from '@/utils';

export const useProvideProfile = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<GetUserProfileResponse | null>(null);

  const settingsAPI = new SettingsApi(openApiConfig());

  const getUserProfile = async () => {
    await settingsAPI.getUserProfile().then(({ data }) => {
      setUser(data);
    });
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    getUserProfile();
  }, []);

  const patchUserProfile = async (values: UserProfile) => {
    setSubmitting(true);
    await settingsAPI
      .updateUserProfile({
        family_name: values.family_name,
        given_name: values.given_name,
        ...(values.phone_number !== '' && {
          phone_number: values.phone_number,
        }),
      })
      .then(({ data }) => {
        setSubmitting(false);
        if (data.code && data.message) {
          setError(data.message);
        } else {
          setSuccess(true);
        }
      });
  };

  return { user, success, setSuccess, submitting, error, patchUserProfile };
};
