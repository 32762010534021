import React from 'react';

import { getShareActionColor } from '@/constants';

interface IESGBadgeProps {
  value?: string;
}

export const ESGBadge: React.FCWithChild<IESGBadgeProps> = ({ value }) => {
  return value ? (
    <span
      className="h-10 w-10 block rounded-full"
      style={{
        backgroundColor: getShareActionColor(value),
      }}
    />
  ) : (
    <span>-</span>
  );
};
