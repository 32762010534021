import { TrustBoardDirectors } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import React from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

const columns: ColumnType<TrustBoardDirectors>[] = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    render: (_, item) => item.fullName ?? EMPTY_DATA_POINT,
    className: 'font-bold text-darkest',
  },
  {
    title: 'Since Year',
    dataIndex: 'yearStarted',
    render: (_, item) => item.yearStarted ?? EMPTY_DATA_POINT,
    width: 95,
    align: 'right',
  },
];

const NameList: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return (
    <div className="name-list">
      <Table
        columns={columns}
        pagination={false}
        dataSource={
          state?.fund?.boardDirectors
            ? state.fund?.boardDirectors.filter(
                boardDirector => boardDirector.yearStarted !== 'N/A',
              )
            : []
        }
        className="[&_.ant-table-thead]:bg-grey-lighter"
        onHeaderRow={() => ({
          className:
            '[&_.ant-table-cell]:text-xs [&_.ant-table-cell]:font-normal [&_.ant-table-cell]:text-neutral-700 [&_.ant-table-cell]:py-2 [&_.ant-table-cell]:bg-transparent',
        })}
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="key"
      />
    </div>
  );
};

export default NameList;
