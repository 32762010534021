import { Checkbox, Divider } from 'antd';
import cx from 'classnames';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { toCamelCase } from '@/utils/toCamelCase';

import { useExploreDefaultFiltersQuery } from '../../../context';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { Search } from '../../Search';
import VirtualizedList from '../../VirtualizedList';
import style from '../../style.module.less';

const CategoriesFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const { selectedMorningStarCategoriesByIndex } =
    tentativeFilters.morningStarCategories ?? {};

  const [filter, setFilter] = useState<string>('');

  const categories = useMemo(() => {
    return (
      defaultfiltersOptions.categories?.map((i, idx) => ({
        label: i.label,
        value: `${idx}`,
      })) ?? []
    );
  }, [defaultfiltersOptions.categories]);

  const searchedCategories = useMemo(() => {
    return categories.filter(cat => cat.label.toLowerCase().includes(filter));
  }, [filter, categories]);

  const itemCallback = useCallback(
    (index: number) => {
      const item = searchedCategories[index];
      return (
        <CategoryItem key={item.label} label={item.label} value={item.value} />
      );
    },
    [searchedCategories],
  );

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  return (
    <div>
      <div className={`${!shouldUseMobileFilter && 'px-2'}`}>
        <Search onChange={setFilter} input={filter} />
      </div>
      <div className={`py-1 ${!shouldUseMobileFilter && 'px-2'}`}>
        <div className="flex justify-between items-center">
          <Checkbox
            checked={
              (selectedMorningStarCategoriesByIndex ?? []).length ===
              categories.length
            }
            className="px-2"
            onChange={({ target }) => {
              const f = getMutableFilters();
              if (target.checked) {
                f.morningStarCategories = {
                  selectedMorningStarCategoriesByIndex: searchedCategories.map(
                    item => item.value,
                  ),
                };
                f.moreFilters = {
                  ...f.moreFilters,
                  filterByOldestShareClassOnly: true,
                };
              } else {
                f.morningStarCategories = undefined;
                f.moreFilters = {
                  ...f.moreFilters,
                  filterByOldestShareClassOnly: undefined,
                };
              }

              updateTentativeFilters(f);
            }}
          >
            Select all
          </Checkbox>
          <span className="font-bold text-sm text-neutral">
            {selectedMorningStarCategoriesByIndex?.length &&
            !shouldUseMobileFilter
              ? `(${tentativeFilters.morningStarCategories?.selectedMorningStarCategoriesByIndex?.length})`
              : ''}
          </span>
        </div>
        <Divider className="m-0" />
      </div>

      <div
        className={cx(
          'w-full min-w-80',
          shouldUseMobileFilter
            ? 'min-h-[11.5rem] [&>div>div]:max-h-[180px]' // Mobile styles
            : 'min-h-52 [&>div>div]:max-h-[150px]', // Desktop styles
        )}
      >
        <VirtualizedList
          items={searchedCategories}
          renderItemByIndex={itemCallback}
        />
      </div>
      {shouldUseMobileFilter && (
        <div className={cx(style['button-search-dropdown-form-note'], 'mt-4')}>
          <p>
            <span className="font-bold">Note:</span> Returns all share classes.
          </p>
        </div>
      )}
    </div>
  );
};

/**
 * Memoized sub components. Do not export or move to separate file to prevent confusion.
 */

const CategoryItem: FC<{
  label: string;
  value: string;
}> = memo(({ label, value }) => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const selectedMorningStarCategoriesByIndex =
    tentativeFilters.morningStarCategories
      ?.selectedMorningStarCategoriesByIndex;

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  return (
    <Checkbox
      data-test-id={toCamelCase(label)}
      value={value}
      className={`${shouldUseMobileFilter ? 'px-2' : 'px-4'}`}
      checked={selectedMorningStarCategoriesByIndex?.includes(value)}
      onChange={({ target }) => {
        const f = getMutableFilters();
        const newItems =
          f.morningStarCategories?.selectedMorningStarCategoriesByIndex ?? [];

        if (target.checked) {
          newItems.push(value);
        } else {
          newItems.splice(newItems.indexOf(value), 1);
        }
        f.morningStarCategories = {
          selectedMorningStarCategoriesByIndex: newItems,
        };
        f.moreFilters = {
          ...f.moreFilters,
          filterByOldestShareClassOnly: newItems.length > 0 ? true : undefined,
        };
        updateTentativeFilters(f);
      }}
    >
      {label}
    </Checkbox>
  );
});

export default CategoriesFilter;
