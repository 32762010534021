import { NoteCategory, NoteRecord, NoteStatus } from '@aminsights/contract';
import { LEGAL_STRUCTURE, USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Dropdown, Modal, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as IconPublish } from '@/assets/svg/icons/publish.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { ReactComponent as SuccessIcon } from '@/assets/svg/research/success.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import {
  useDeleteAllNotes,
  useGetNotes,
  usePublishNotes,
} from '@/hooks/query-hooks/research-hooks/notes-hooks';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import { validatePermissions } from '@/utils/auth';

import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import Analyst from './Analyst';
import MainEmptyState from './MainEmptyState';
import CardNotes from './MainEmptyState/component/CardNotes';
import DropdownMenuWithModal from './MainEmptyState/component/DropdownMenuWithModal';
import Meetings from './Meetings';
import Ratings from './Ratings';

const Research: React.FC = () => {
  const { id: fundId } = useParams<{ id: string }>();
  const { data: fundData } = useFundByIsInParam();
  const { data } = useGetNotes(fundId);
  const [noteData, setNoteData] = useState<NoteRecord[]>([]);
  const [categories, setCategories] = useState<NoteCategory[]>([]);
  const [isThereNewToPublish, setIsThereNewToPublish] = useState(false);
  const [draftNoteIds, setDraftNoteIds] = useState<string[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const authContext = useContext(AxiosAuthContext);
  const currentUser = authContext.state.decodedToken;
  const [successPublishedModalShow, setSuccessPublishedModalShow] =
    useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isMobileFirstTap, setIsMobileFirstTap] = useState(true);
  const screenWidthMode = getScreenWidthMode();
  const isTablet = screenWidthMode[ScreenWidthEnum.MaxLg];

  useEffect(() => {
    if (data) {
      const normalizedData = Array.isArray(data) ? data : [data];

      // Define the order of categories
      const categoryOrder = [
        NoteCategory.Summary,
        NoteCategory.People,
        NoteCategory.Philosophy,
        NoteCategory.Process,
        NoteCategory.Portfolio,
        NoteCategory.Performance,
      ];

      // Sort data by category order
      const sortedData = normalizedData.sort((a, b) => {
        return (
          categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
        );
      });

      setNoteData(sortedData);

      // Extract categories
      const categories = sortedData.map(item => item.category);
      setCategories(categories);

      // Check for "Draft" status
      const hasDraftStatus = normalizedData.some(
        item => item.status === NoteStatus.Draft,
      );
      setIsThereNewToPublish(hasDraftStatus);

      // Set selected note IDs
      const noteIds = normalizedData
        .filter(item => item.status === NoteStatus.Draft)
        .map(item => item._id);
      setDraftNoteIds(noteIds);
    }
  }, [data]);

  const { mutate: publishNotes, isLoading } = usePublishNotes();

  const isLegalStructureMPS = useMemo(() => {
    return fundData?.fund.legalStructure === LEGAL_STRUCTURE.MPS;
  }, [fundData]);

  const handlePublishNotes = async () => {
    if (!isTablet || !isMobileFirstTap) {
      await publishNotes({ fundId, noteIds: draftNoteIds });
      setSuccessPublishedModalShow(true);
    }
  };

  const { mutate: deleteAllNotes, isLoading: isDeleting } = useDeleteAllNotes();

  const handleDeleteAllNotes = async () => {
    await deleteAllNotes({ fundId });
  };

  const handleTooltipVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean),
  ) => {
    setTooltipVisible(visible);
    if (!visible) {
      setIsMobileFirstTap(true);
    }
    if (isMobileFirstTap) {
      setTooltipVisible(true);
      setIsMobileFirstTap(false);
    }
  };

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.research.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  return (
    <>
      <div className="grid lg:grid-cols-[1fr_360px] gap-4 md:px-4 pt-2">
        <div className="w-full bg-white flex flex-col p-4 lg:rounded-lg">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold text-neutral-900">Notes</h3>
            {noteData.length > 0 && (
              <div className="flex gap-4 items-center justify-end">
                <Tooltip
                  title="This will notify your organisation. We recommend publishing once you have finished."
                  placement="bottom"
                  color="#313341"
                  align={{ offset: [0, 15] }}
                  overlayStyle={{ width: '240px' }}
                  trigger={isTablet ? 'click' : 'hover'}
                  open={tooltipVisible && !!draftNoteIds.length}
                  onOpenChange={handleTooltipVisibleChange}
                >
                  {hasWritePermissions && (
                    <Button
                      className={cx(
                        'text-sm font-medium leading-5 rounded !border max-sm:w-10 max-sm:p-0',
                        isThereNewToPublish
                          ? '!border-primary !text-primary fill-primary hover:!text-primary-400 hover:!border-primary-400 hover:fill-primary-400'
                          : '!bg-[unset] !text-neutral-300 !border-neutral-300 fill-neutral-300',
                        isLoading &&
                          '!bg-neutral-300 !text-white !border-ext-white',
                      )}
                      type="default"
                      size="large"
                      disabled={!isThereNewToPublish || isLoading}
                      onClick={handlePublishNotes}
                      loading={isLoading}
                    >
                      <IconPublish />
                      <span className="max-sm:hidden">Publish</span>
                    </Button>
                  )}
                </Tooltip>
                {hasWritePermissions && (
                  <DropdownMenuWithModal
                    noteCategory={categories}
                    hideTextOnSmallScreen
                  />
                )}
                {hasWritePermissions && (
                  <Dropdown
                    menu={{
                      className:
                        'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
                      items: [
                        {
                          key: 'delete',
                          label: 'Delete all',
                          onClick: () => {
                            setIsDeleteModalVisible(true);
                          },
                          danger: true,
                          disabled: isDeleting || !data?.length,
                        },
                      ],
                    }}
                    placement="bottomRight"
                    autoAdjustOverflow={false}
                    align={{ offset: [0, 10] }}
                    trigger={['click']}
                  >
                    <div className="flex w-10 h-10 justify-center items-center hover:bg-grey-light-50 cursor-pointer rounded">
                      <MeatballMenu
                        width={16}
                        height={16}
                        className="text-neutral-700 mx-2"
                      />
                    </div>
                  </Dropdown>
                )}
              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="flex flex-col justify-center items-center">
            {noteData.length === 0 ? (
              <div className="min-h-64 mb-2 flex flex-col items-center justify-center">
                <MainEmptyState
                  onClick={() => {}}
                  title="You don’t have any notes yet"
                  subTitle="Start adding your notes by clicking on the button below."
                  buttonLabel="Add a note"
                />
                {hasWritePermissions && (
                  <DropdownMenuWithModal noteCategory={categories} />
                )}
              </div>
            ) : (
              <div className="mt-6 w-full flex flex-col gap-6 min-h-48">
                {noteData.map(note => (
                  <CardNotes key={note._id} {...note} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          {!isLegalStructureMPS && <Analyst />}
          <Meetings />
          {!isLegalStructureMPS && <Ratings />}
        </div>
      </div>
      <Modal
        centered
        open={successPublishedModalShow}
        footer={null}
        width={480}
        onCancel={() => setSuccessPublishedModalShow(false)}
      >
        <div className="py-10 flex flex-col justify-center items-center">
          <SuccessIcon />
          <p className="text-xl font-bold my-2">Successfully published!</p>
          <span className="text-sm mb-6">
            This note is now visible to everyone in your organisation
          </span>
          <Button
            size="large"
            type="primary"
            onClick={() => setSuccessPublishedModalShow(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <ConfirmationModalDanger
        isVisible={isDeleteModalVisible}
        modalInfo={{
          title: 'Delete notes?',
          description: (
            <span className="text-sm font-normal text-neutral-700">
              All notes will be deleted. This action cannot be undone.
            </span>
          ),
          primaryActionLabel: 'Delete',
        }}
        toggleModal={() => setIsDeleteModalVisible(false)}
        onConfirm={handleDeleteAllNotes}
      />
    </>
  );
};

export default Research;
